import { BinanceWallet, Metamask, SafePalWallet, TrustWallet, WalletConnect, OKXWallet, BitWallet } from '../icons';

export const NetworkContextName = 'NETWORK';

// export const API_URL = process.env.REACT_APP_API_URL;
// export const API_URL = 'https://api.pogaibaby.xyz/api'
export const API_URL = 'https://api.geckoinu.vip/api'
export const API_IDO_URL = 'https://api.geckoinu.vip/api'

// wallet list
export const WALLET_LIST = [
	{ icon: Metamask, title: 'Metamask', connectorId: 'injected' },
	{ icon: BinanceWallet, title: 'Binance', connectorId: 'bsc' },
	{ icon: TrustWallet, title: 'Trust Wallet', connectorId: 'trustWallet' },
	{ icon: BitWallet, title: 'Bitget Wallet', connectorId: 'bit' },
	{ icon: OKXWallet, title: 'OKX Wallet', connectorId: 'okx' },
	{ icon: SafePalWallet, title: 'SalePal Wallet', connectorId: 'safe' },
];
export const ADDRESS_BUSD_ADDRESS = '0xe9e7cea3dedca5984780bafc599bd69add087d56';
export const ADDRESS_RECEVIE_BUSD = '0x9a5D956C9FBc8Bd51a08DFC31441A2cCff2584aE';
export const NEED_A_PLACEHOLDER = WALLET_LIST.length % 2 !== 0;
export const MAINNET_BSC_URL = 'https://bsc-dataseed.binance.org';
export const MAINNET_CHAIN_ID = 56;

export const marketContractAddress = '0x7c76E1344A79fFe41Dd43239e30D60C57981dbF2';

export const bscTokens = [
	// stake pool
	{
		id: 'wbnb',
		symbol: 'wbnb',
		contract: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
	},
	{
		id: 'binance-usd',
		symbol: 'busd',
		contract: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
	},
	{
		id: 'tether',
		symbol: 'USDT',
		contract: '0x55d398326f99059fF775485246999027B3197955'
	},
	{
		id: 'ethereum',
		symbol: 'ETH',
		contract: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8'
	},
	{
		id: 'dai',
		symbol: 'DAI',
		contract: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3'
	},
	{
		id: 'polis',
		symbol: 'POLIS',
		contract: '0xb5bea8a26d587cf665f2d78f077cca3c7f6341bd'
	},
	{
		id: 'tether',
		symbol: 'USDT',
		contract: '0x049d68029688eAbF473097a2fC38ef61633A3C7A'
	},
	{
		id: 'usd-coin',
		symbol: 'USDC',
		contract: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d'
	},
];

export const MAPPING_CHAINID: any = {
	BSC: "0x38",
	eth: "0x1",
	POLYGON: "0x89",
	fmt: "0xfa",
	okc: "0x42",
	avax: "0xa86a",
	op: "0xa",
	doge: "0x7d0",
	arb: "0xa4b1",
	cron: '0x19',
	multi: '0xf49d',
	metis: '0x440',
	velas: '0x6a',
	moon: '0x504',
	celo: '0xa4ec',
	heco: '0x80'
};

export const MAPPING_NETWORK: any = {
	"0x38": 1,
	"0x1": 1,
	"0x89": 1,
	"0xfa": 1,
	"0x42": 1,
	"0xa86a": 1,
	"0xa": 1,
	"0x7d0": 1,
	"0xa4b1": 1,
	'0x19': 1,
	'0xf49d': 1,
	'0x440': 1,
	'0x6a': 1,
	'0x504': 1,
	'0xa4ec': 1,
	'0x80': 1
};
export const MINT_NFT_CONTRACT: any = process.env.REACT_APP_MINT_CONTRACT
export const MARKET_NFT: any = process.env.REACT_APP_MARKET_NFT
export const BID_NFT: any = process.env.REACT_APP_BID_NFT
export const TOTAL_NFT_MINT = 3000
export const FEE_CREATOR = 2.5 // percent
export const FEE_SERVICE = 2.5 // percent
