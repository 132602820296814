/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Modal, Spin, message, Progress, Select, Drawer, Menu } from "antd";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { isMobile } from 'react-device-detect';
import {
    MenuOutlined,
    SettingOutlined
} from '@ant-design/icons';
import './style.css'

import ConnectWallet from "../connect-wallet/ConnectWallet";

import cn from '../cn/common.json'
import en from '../en/common.json'
import po from '../po/common.json'
import { Link } from "react-router-dom";
import WagmiConnect from "components/wagmi-connect";



i18next.init({
    interpolation: { escapeValue: false },
    lng: 'en',
    resources: {
        en: {
            common: en
        },
        cn: {
            common: cn
        },
        po: {
            common: po
        }
    },
});

const Header = () => {
    let navigate = useNavigate();
    const { t, i18n }: any = useTranslation('common');
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [valueSelect, setValueSelect]: any = useState('en');


    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    // const handleChange = (value: any) => {
    //     console.log(`selected ${value}`);
    //     i18n.changeLanguage(`${value}`)
    //     localStorage.setItem('valuelang', value)
    //     setValueSelect(value)
    // };
    useEffect(() => {
        setValueSelect(localStorage.getItem('valuelang'))
        i18n.changeLanguage(localStorage.getItem('valuelang'))
    }, [valueSelect])

    const gotoHome = () => {
        navigate("/");
    }

    const location = useLocation();

    let defaultMenu;
    switch (location.pathname) {
        case '/nft':
            defaultMenu = 'nft';
            break;
        case '/mint-nft':
            defaultMenu = 'nft';
            break;
        case '/detailNFT/:id':
            defaultMenu = 'nft';
            break;
        case '/ownerNFT/:id':
            defaultMenu = 'nft';
            break;
        case '/gecko-art':
            defaultMenu = 'more';
            break;
        case '/my-nft':
            defaultMenu = 'nft';
            break;
        case '/staking':
            defaultMenu = 'launchpool';
            break;
        case '/gecverse':
            defaultMenu = 'gecverse';
            break;
        case '/launchpad':
            defaultMenu = 'launchpad';
            break;
        case '/swap':
            defaultMenu = 'trade';
            break;
        case '/bot':
            defaultMenu = 'bot';
            break;

        default:
            defaultMenu = 'home';
    }

    // const [current, setCurrent] = useState(defaultMenu);


    // const onClick = (e: any) => {
    //     setCurrent(e.key);
    // };

    // const [showSubNFT, setshowSubNFT]: any = useState(false);
    const [showSubTrade, setshowSubTrade]: any = useState(false);
    const [showSubNft, setshowSubNft]: any = useState(false);
    const [showSubLaunchpad, setShowSubLaunchpad]: any = useState(false);
    const [showSubMore, setShowSubMore]: any = useState(false);
    const [showExploreCollection, setShowExploreCollection]: any = useState(false);

    const handleShowExploreCollection = () => {
        setShowExploreCollection(true);
    };

    // const handleMove = () => {
    //     setshowSubNFT(true)
    // }
    // const handleLease = () => {
    //     setshowSubNFT(false)
    // }

    const handleMoveTrade = () => {
        setshowSubTrade(true)
    }
    const handleLeaseTrade = () => {
        setshowSubTrade(false)
    }
    const handleMoveNft = () => {
        setshowSubNft(true)
    }
    const handleLeaseNft = () => {
        setshowSubNft(false)
    }

    const handleMoveLaunchpad = () => {
        setShowSubLaunchpad(true)
    }
    const handleLeaseLaunchpad = () => {
        setShowSubLaunchpad(false)
    }

    const handleMoveMore = () => {
        setShowSubMore(true)
    }
    const handleLeaseMore = () => {
        setShowSubMore(false)
    }

    const gotoAudit = () => {
        window.open('https://github.com/solidproof/projects/tree/main/2024/GeckoINU')
    }

    const gotoSwap = () => {
        window.location.replace("/swap")
    }


    const handleClose = () => {
        setOpen(false)
    }

    return (
        <>
            <div className="header">
                {isMobile ? (
                    <>
                        <div className="logo" onClick={gotoHome}>
                            <img src="../../images/logo-new.png" alt="" />
                        </div>
                        <div className="header-left-mb">
                            {/* <div className="action">
                                <div className="action-btn">
                                    <Select
                                        value={valueSelect ? valueSelect : 'en'}
                                        style={{
                                            width: 180,
                                        }}
                                        popupClassName='select-lang'
                                        onChange={handleChange}
                                        options={[
                                            {
                                                value: 'en',
                                                label: <div className="country">
                                                    <img src="./images/en1.png" alt="" />
                                                </div>,
                                            },
                                            {
                                                value: 'po',
                                                label: <div className="country">
                                                    <img src="./images/po1.png" alt="" />
                                                </div>,
                                            },
                                            {
                                                value: 'cn',
                                                label: <div className="country">
                                                    <img src="./images/cn1.png" alt="" />
                                                </div>,
                                            },
                                        ]}
                                    />
                                </div>
                            </div> */}
                            <div className="menu-mobie" onClick={showDrawer}>
                                <img src="../../images/menu-mo.svg" alt="" />
                            </div>
                        </div>

                    </>

                ) : (
                    <>
                        <div className="header-left">
                            <div className="logo" onClick={gotoHome}>
                                <img src="../../images/logo-new.png" alt="" />
                            </div>
                            <div className="menu">
                                {/* <div className="menu-nft" onMouseOver={handleMoveTrade} onMouseLeave={handleLeaseTrade}>
                                    <span className="menu-nft-text">{t('menu.menu-trade', { framework: 'React' })}</span>
                                    <img className="icon-nft-menu " src="./images/nft/menu-nft.png" alt="" />
                                    <div className={`sub-nft ${showSubTrade ? 'show-sub' : ''}`}>
                                        <div className="sub1">{t('menu.menu-crosschain-swap', { framework: 'React' })}  <span>(Soon)</span></div>
                                        <div className="sub1">{t('menu.menu-telegram-bot', { framework: 'React' })}  <span>(Soon)</span></div>
                                    </div>
                                </div> */}
                                <div className="wallpaper">
                                    <div className="menu-nft">
                                        <div className={`menu-nft-text ${defaultMenu == 'trade' && 'text-red'}`}>
                                            <a className={`menu-nft-text ${defaultMenu == 'trade' ? 'text-red' : 'menu-staking'}`} onClick={gotoSwap} >Trade</a>
                                            {/* <Link className={`menu-nft-text ${defaultMenu == 'trade' ? 'text-red' : 'menu-staking'}`} to="/swap">Trade</Link> */}
                                        </div>
                                    </div>
                                </div>

                                <div className="menu-nft" onClick={handleClose} onMouseOver={handleMoveNft} onMouseLeave={handleLeaseNft}>
                                    <span className={`menu-nft-text ${defaultMenu == 'nft' && 'text-red'}`}>{t('menu.menu-nft', { framework: 'React' })}</span>
                                    <img className="icon-nft-menu " src="../../images/nft/menu-nft.png" alt="" />
                                    <div className={`sub-nft ${showSubNft ? 'show-sub' : ''}`}>
                                        <div className="sub1 active-sub1"><Link to="/nft">{t('menu.menu-nft-overview', { framework: 'React' })}</Link></div>
                                        <div className="sub1 active-sub1" onClick={handleShowExploreCollection}>{t('menu.menu-nft-explore-collection', { framework: 'React' })}</div>
                                        <div className="sub1 active-sub1"><Link to="/my-nft">{t('menu.menu-nft-my-nfts', { framework: 'React' })}</Link></div>
                                    </div>
                                </div>

                                <div className="wallpaper">
                                    <div className="menu-nft">
                                        <div onClick={handleClose} className={`menu-nft-text ${defaultMenu == 'bot' && 'text-red'}`}>
                                            <Link className={`menu-nft-text  ${defaultMenu == 'bot' ? 'text-red' : 'menu-staking'}`} to="/bot">Geckobot</Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="menu-nft" onClick={handleClose} onMouseOver={handleMoveLaunchpad} onMouseLeave={handleLeaseLaunchpad}>
                                    <span className={`menu-nft-text ${defaultMenu == 'launchpad' && 'text-red'}`}>
                                        <Link className={`menu-nft-text ${defaultMenu == 'launchpad' ? 'text-red' : 'menu-staking'}`} to="/launchpad">Launchpad</Link>

                                    </span>
                                    {/* <img className="icon-nft-menu " src="../../images/nft/menu-nft.png" alt="" /> */}
                                    {/* <div className={`sub-nft ${showSubLaunchpad ? 'show-sub' : ''}`}>
                                        <div className="sub1">Explore Projects <span>(Soon)</span></div>
                                        <div className="sub1 active-sub1">
                                            <Link to="/launch-pool">Launchpool</Link>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="wallpaper">
                                    <div className="menu-nft">
                                        <div onClick={handleClose} className={`menu-nft-text ${defaultMenu == 'launchpool' && 'text-red'}`}>
                                            <Link className={`menu-nft-text  ${defaultMenu == 'launchpool' ? 'text-red' : 'menu-staking'}`} to="/staking">Launchpool</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="wallpaper">
                                    <div className="menu-nft">
                                        <div onClick={handleClose} className={`menu-nft-text ${defaultMenu == 'gecverse' && 'text-red'}`}>
                                            <Link className={`menu-nft-text ${defaultMenu == 'gecverse' ? 'text-red' : 'menu-staking'}`} to="/gecverse">gecverse</Link>
                                        </div>
                                    </div>
                                </div>


                                {/* <div className="wallpaper">
                                    <div className={`menu-disable ${defaultMenu == 'earn' && 'text-red'}`}>Earn <small className="text-red">(Soon)</small></div>
                                </div> */}

                                <div className="menu-nft" onMouseOver={handleMoveMore} onMouseLeave={handleLeaseMore}>
                                    <span className={`menu-nft-text ${defaultMenu == 'more' && 'text-red'}`}>More</span>
                                    <img className="icon-nft-menu " src="../../images/nft/menu-nft.png" alt="" />
                                    <div className={`sub-nft ${showSubMore ? 'show-sub' : ''}`}>
                                        <div className="sub1 active-sub1"><Link to="/gecko-art">GECKO ART</Link></div>
                                        <div className="sub1 active-sub1" onClick={showModal}>Roadmap</div>
                                        <div className="sub1 active-sub1" onClick={gotoAudit}>Solidproof Audit </div>
                                    </div>
                                </div>
                                {/* <div className="roundmap" onClick={showModal}>{t('menu.menu-2', { framework: 'React' })}</div> */}

                                {/* <div className="menu-nft" onMouseOver={handleMove} onMouseLeave={handleLease}>
                                    <span className="menu-nft-text">{t('menu.menu-6', { framework: 'React' })}</span>
                                    <img className="icon-nft-menu " src="./images/nft/menu-nft.png" alt="" />
                                    <div className={`sub-nft ${showSubNFT ? 'show-sub' : ''}`}>
                                       
                                        <div className="sub1">{t('menu.menu-7', { framework: 'React' })} - <span>{t('menu.menu-23', { framework: 'React' })}</span></div>
                                        <div className="sub1">{t('menu.menu-8', { framework: 'React' })} - <span>{t('menu.menu-23', { framework: 'React' })}</span></div>
                                        <div className="sub1 active-sub1" onClick={gotoNFT}>{t('menu.menu-9', { framework: 'React' })}</div>
                                        <div className="sub1">{t('menu.menu-10', { framework: 'React' })} - <span>{t('menu.menu-23', { framework: 'React' })}</span></div>
                                        <div className="sub1">{t('menu.menu-11', { framework: 'React' })} - <span>{t('menu.menu-23', { framework: 'React' })}</span></div>
                                        <div className="sub1">{t('menu.menu-12', { framework: 'React' })} - <span>{t('menu.menu-23', { framework: 'React' })}</span></div>
                                        <div className="sub1">{t('menu.menu-13', { framework: 'React' })} - <span>{t('menu.menu-23', { framework: 'React' })}</span></div>
                                    </div>

                                </div> */}
                            </div>
                        </div>

                        <div className="action">
                            <div className="action-btn">
                                {/* <button className="btn-air" onClick={gotoAirdrop}>
                                    {t('menu.menu-3', { framework: 'React' })}

                                </button> */}
                                {/* <button className="buy-now" onClick={gotoSwap}>
                                    {t('menu.menu-4', { framework: 'React' })}

                                </button> */}

                                {/* <Select
                                    value={valueSelect ? valueSelect : 'en'}
                                    style={{
                                        width: 70,
                                    }}

                                    popupClassName='select-lang'
                                    onChange={handleChange}
                                    options={[
                                        {
                                            value: 'en',
                                            label: <div className="country">
                                                <img src="./images/en1.png" alt="" />
                                            </div>,
                                        },
                                        {
                                            value: 'po',
                                            label: <div className="country">
                                                <img src="./images/po1.png" alt="" />
                                            </div>,
                                        },
                                        {
                                            value: 'cn',
                                            label: <div className="country">
                                                <img src="./images/cn1.png" alt="" />
                                            </div>,
                                        },

                                    ]}
                                /> */}
                                {/* <ConnectWallet /> */}
                                <WagmiConnect />

                            </div>
                        </div>
                    </>

                )}

            </div>
            <div className="line"></div>
            <Drawer title="" placement="right" onClose={onClose} open={open}>

                <div className="menu mb">
                    {/* <div className="menu-nft" onMouseOver={handleMoveTrade} onMouseLeave={handleLeaseTrade}>
                        <span className="menu-nft-text">{t('menu.menu-trade', { framework: 'React' })}</span>
                        <img className="icon-nft-menu " src="./images/nft/menu-nft.png" alt="" />
                        <div className={`sub-nft ${showSubTrade ? 'show-sub' : ''}`}>
                            <div className="sub1">{t('menu.menu-crosschain-swap', { framework: 'React' })}  <span>(Soon)</span></div>
                            <div className="sub1">{t('menu.menu-telegram-bot', { framework: 'React' })}  <span>(Soon)</span></div>
                        </div>
                    </div> */}
                    <div className="wallpaper">
                        <div className="menu-nft">
                            <div className={`menu-nft-text ${defaultMenu == 'trade' && 'text-red'}`}>
                                <a className={`menu-nft-text ${defaultMenu == 'trade' ? 'text-red' : 'menu-staking'}`} onClick={gotoSwap} >trade</a>
                            </div>
                        </div>
                    </div>
                    <div className="menu-nft" onMouseOver={handleMoveNft} onMouseLeave={handleLeaseNft}>
                        <span className={`menu-nft-text ${defaultMenu == 'nft' && 'text-red'}`}>{t('menu.menu-nft', { framework: 'React' })}</span>
                        <img className="icon-nft-menu " src="../../images/nft/menu-nft.png" alt="" />
                        <div className={`sub-nft ${showSubNft ? 'show-sub' : ''}`}>
                            <div className="sub1 active-sub1"><Link to="/nft">{t('menu.menu-nft-overview', { framework: 'React' })}</Link></div>
                            <div className="sub1 active-sub1" onClick={handleShowExploreCollection}>{t('menu.menu-nft-explore-collection', { framework: 'React' })}</div>
                            <div className="sub1 active-sub1"><Link to="/my-nft">{t('menu.menu-nft-my-nfts', { framework: 'React' })}</Link></div>
                        </div>
                    </div>
                    <div className="menu-nft" onMouseOver={handleMoveLaunchpad} onMouseLeave={handleLeaseLaunchpad}>
                        <span onClick={handleClose} className={`menu-nft-text ${defaultMenu == 'launchpad' && 'text-red'}`}>
                            <Link className={`menu-nft-text ${defaultMenu == 'launchpad' ? 'text-red' : 'menu-staking'}`} to="/launchpad">Launchpad</Link>
                        </span>
                        {/* <img className="icon-nft-menu " src="../../images/nft/menu-nft.png" alt="" /> */}
                        {/* <div className={`sub-nft ${showSubLaunchpad ? 'show-sub' : ''}`}>
                                        <div className="sub1">Explore Projects <span>(Soon)</span></div>
                                        <div className="sub1 active-sub1">
                                            <Link to="/launch-pool">Launchpool</Link>
                                        </div>
                                    </div> */}
                    </div>
                    {/* <div className="menu-nft" onMouseOver={handleMoveLaunchpad} onMouseLeave={handleLeaseLaunchpad}>
                        <span className={`menu-nft-text ${defaultMenu == 'launchpad' && 'text-red'}`}>Launchpad</span>
                        <img className="icon-nft-menu " src="../../images/nft/menu-nft.png" alt="" />
                        <div className={`sub-nft ${showSubLaunchpad ? 'show-sub' : ''}`}>
                            <div className="sub1">Explore Projects <span>(Soon)</span></div>
                            <div className="sub1 active-sub1">
                                <Link to="/launch-pool">Launchpool</Link>
                            </div>
                        </div>
                    </div> */}
                    <div className="wallpaper">
                        <div className="menu-nft">
                            <div onClick={handleClose} className={`menu-nft-text ${defaultMenu == 'bot' && 'text-red'}`}>
                                <Link className={`menu-nft-text ${defaultMenu == 'bot' ? 'text-red' : 'menu-staking'}`} to="/bot">geckobot</Link>
                            </div>
                        </div>
                    </div>
                    <div className="wallpaper">
                        <div className="menu-nft">
                            <div onClick={handleClose} className={`menu-nft-text ${defaultMenu == 'launchpool' && 'text-red'}`}>
                                <Link className={`menu-nft-text ${defaultMenu == 'launchpool' ? 'text-red' : 'menu-staking'}`} to="/staking">Launchpool</Link>
                            </div>
                        </div>
                    </div>

                    <div className="wallpaper">
                        <div className="menu-nft">
                            <div onClick={handleClose} className={`menu-nft-text ${defaultMenu == 'gecverse' && 'text-red'}`}>
                                <Link className={`menu-nft-text ${defaultMenu == 'gecverse' ? 'text-red' : 'menu-staking'}`} to="/gecverse">gecverse</Link>
                            </div>
                        </div>
                    </div>

                    <div className="menu-nft" onMouseOver={handleMoveMore} onMouseLeave={handleLeaseMore}>
                        <span className={`menu-nft-text ${defaultMenu == 'more' && 'text-red'}`}>More</span>
                        <img className="icon-nft-menu " src="../../images/nft/menu-nft.png" alt="" />
                        <div className={`sub-nft ${showSubMore ? 'show-sub' : ''}`}>
                            <div onClick={handleClose} className="sub1 active-sub1"><Link to="/gecko-art">GECKO ART</Link></div>
                            <div className="sub1 active-sub1" onClick={showModal}>Roadmap</div>
                            <div className="sub1 active-sub1" onClick={gotoAudit}>Solidproof Audit </div>
                        </div>
                    </div>

                    {/* <div className="wallpaper">
                        {t('menu.menu-5', { framework: 'React' })}
                    </div>
                    <div className="wallpaper" onClick={gotoWallPaper}>
                        {t('menu.menu-1', { framework: 'React' })}
                    </div>
                    <div className="roundmap" onClick={showModal}>{t('menu.menu-2', { framework: 'React' })}</div>

                    <div className="menu-nft" onMouseOver={handleMove} onMouseLeave={handleLease}>
                        <span className="menu-nft-text">{t('menu.menu-6', { framework: 'React' })}</span>
                        <img className="icon-nft-menu " src="./images/nft/menu-nft.png" alt="" />
                        <div className={`sub-nft ${showSubNFT ? 'show-sub' : ''}`}>
                            <div className="sub1">{t('menu.menu-7', { framework: 'React' })} - <span>{t('menu.menu-23', { framework: 'React' })}</span></div>
                            <div className="sub1">{t('menu.menu-8', { framework: 'React' })} - <span>{t('menu.menu-23', { framework: 'React' })}</span></div>
                            <div className="sub1 active-sub1" onClick={gotoNFT}>{t('menu.menu-9', { framework: 'React' })}</div>
                            <div className="sub1">{t('menu.menu-10', { framework: 'React' })} - <span>{t('menu.menu-23', { framework: 'React' })}</span></div>
                            <div className="sub1">{t('menu.menu-11', { framework: 'React' })} - <span>{t('menu.menu-23', { framework: 'React' })}</span></div>
                            <div className="sub1">{t('menu.menu-12', { framework: 'React' })} - <span>{t('menu.menu-23', { framework: 'React' })}</span></div>
                            <div className="sub1">{t('menu.menu-13', { framework: 'React' })} - <span>{t('menu.menu-23', { framework: 'React' })}</span></div>
                        </div>
                    </div> */}
                </div>
                <div className="action">
                    <div className="action-btn mb">
                        {/* <ConnectWallet /> */}
                        <WagmiConnect open={open} onClose={onClose} />
                        {/* <button className="btn-air mb" onClick={gotoAirdrop}>
                            {t('menu.menu-3', { framework: 'React' })}

                        </button> */}
                        {/* <button className="buy-now mb" onClick={gotoSwap}>
                            {t('menu.menu-4', { framework: 'React' })}

                        </button> */}
                        {/* <Select
                            value={valueSelect}
                            style={{
                                width: 180,
                            }}
                            popupClassName='select-lang'
                            onChange={handleChange}
                            options={[
                                {
                                    value: 'en',
                                    label: <div className="country">
                                        <img src="./images/en.png" alt="" />&ensp;  English
                                    </div>,
                                },
                                {
                                    value: 'po',
                                    label: <div className="country">
                                        <img src="./images/po.png" alt="" />&ensp;  Português
                                    </div>,
                                },
                                {
                                    value: 'cn',
                                    label: <div className="country">
                                        <img src="./images/china.png" alt="" />&ensp;  中文（简体）
                                    </div>,
                                },

                            ]}
                        /> */}

                    </div>
                </div>
            </Drawer>



            <Modal
                title=''
                visible={isModalVisible}
                onOk={handleOk}
                footer={false}
                onCancel={handleCancel}
                className="modal-lucky"
                width={720}
            >
                <div className="lucky">
                    <img className="roundmap-img" src={t('content.t-30', { framework: 'React' })} alt="" />
                    <img className="roundmap-text" src="../../images/rm-img.svg" alt="" />

                </div>

            </Modal>

            <Modal
                wrapClassName="modal-ow"
                footer={false}
                title=""
                open={showExploreCollection}
                onOk={() => setShowExploreCollection(false)}
                onCancel={() => setShowExploreCollection(false)}
            >
                <div className="modal-owner">
                    <div className="modal-link">
                        <div className="title-link">
                            Explore NFTs
                        </div>
                        <div className="trade-link">
                            <div className="item-trade" onClick={() => { window.open('https://joepegs.com/collections/avalanche/0x2da215293f66319facdf8fe41d99524a406d4c8f') }}>Trade on <p>Joepegs <p className="by-trade">by Trader Joe</p></p>

                            </div>
                            <div className="item-trade" onClick={() => { window.open('https://opensea.io/collection/gecko-inu-nft-collection-1?tab=items') }}>Trade on <p>
                                <img src="../../images/nft/opensea.png" alt="" />
                                OpenSea
                            </p> </div>
                            <div className="item-trade" onClick={() => { window.open('https://avax.hyperspace.xyz/collection/avax/007f119d-e48c-4390-9331-0c2bde2468c1') }}>Trade on <p>
                                <img src="../../images/nft/hyper.jpeg" alt="" />
                                HyperSpace</p> </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default Header;