/* eslint-disable prefer-template */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return, no-param-reassign */
import {
    createStore,
    createHook,
    createContainer,
    createSubscriber,
} from "react-sweet-state";
import axios from "axios";
import { API_URL } from '../../constants/index'


const Store = createStore({
    initialState: {},
    actions: {
        getListGame:
            () =>
                ({ setState }) => {
                    return new Promise((resolve, reject) => {
                        axios.get(`${API_URL}/gamehub/list`).then((res) => {
                            resolve(res?.data?.data);
                        });
                    });
                },
    },

    name: "Store",
});

export const useHookStore = createHook(Store);
export const Container = createContainer(Store, {
    onInit:
        () =>
            ({ setState }, props) => {
                setState({ ...props });
            },
});
export const Subscriber = createSubscriber(Store);
