import React from "react";
import "./style.css";
import { TbLoader } from "react-icons/tb";

interface LoadingProps {
  status: boolean;
  content: string;
}

const Loading: React.FC<LoadingProps> = ({ status, content }: any) => {
  if (status) {
    return (
      <div className="loading-container">
        <div className="loading-spinner">
          <div className="btn-bsc-icon-loading">
            <TbLoader size={20} />
          </div>
          <span>{content}</span>
        </div>
      </div>
    );
  } else {
    return content;
  }
};

export default Loading;
