/* eslint-disable no-unreachable */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useRef } from "react";
import { Tabs, Select, Pagination, Drawer } from "antd";
import "./style.css";
import Header from "../../../components/common/header";
import {
  getListMyNFT,
} from "../../../axios/marketNFT";
import { useActiveWeb3React } from "../../../hooks";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useHook } from '../../../components/connect-wallet/store'
import { useWeb3ModalState } from '@web3modal/wagmi/react'
import {
  useAccount
} from "wagmi";

const MyNFT = () => {
  const { address } = useAccount();
  let account: any = address
  // let account = '0xb47b8a62F47e008510fA7888c7Cd61229f1CAfE2'
  const [state, actions]: any = useHook();
  let navigate = useNavigate();
  const [tabKey, setTabKey]: any = useState("1");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [listNFTs, setListNFTs] = useState<any>([]);

  const [open, setOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);




  const showDrawer = () => {
    setOpen(true);
  };
  const showDrawerFilter = () => {
    setOpenFilter(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const onCloseFilter = () => {
    setOpenFilter(false);
  };

  const onChange = (key: any) => {
    console.log("key", key);
    console.log(key);
    setTabKey(key);
  };
  const items = [
    {
      key: "1",
      label: "My Geckos",
    },
    // {
    //   key: "2",
    //   label: "Offer history",
    // },
    // {
    //   key: "3",
    //   label: "My offers",
    // },
    // {
    //   key: "4",
    //   label: "Activity",
    // },
  ];

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };
  const handleChangeSelectLeft = (value: any) => {
    console.log(`Selected: ${value}`);
  };

  const options = [];
  for (let i = 10; i < 36; i++) {
    options.push({
      value: i.toString(36) + i,
      label: i.toString(36) + i,
    });
  }

  const [showRow, setShowRow] = useState(false);

  const handleChangeSize = () => {
    setShowRow(!showRow);
  };
  const gotoDetail = (id: any) => {
    navigate(`${"/detailNFT"}/${id}`);
  };

  let params = {
    ownerAddress: "",
    lastTime: 0,
  };
  const getListNFT = async () => {
    try {
      params = {
        ...params,
        ownerAddress: account,
        lastTime: 0,
      };
      setIsLoading(true);
      await getListMyNFT(params).then((res: any) => {
        if (res) {
          setListNFTs(res?.data);
          setIsLoading(false);
        } else {
          setListNFTs([]);
        }
      });
    } catch (error) {
      console.error("error getListNFT", error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (account) {
      getListNFT();
    }
  }, [account]);


  const gotoScan = () => {
    window.open(`https://snowtrace.dev/address/${account}`)
  }

  const renderRankColor = (num: any) => {
    console.log('num', num)
    if (num > 1 && num <= 80) {
      return 'total-collec rank1'
    } else if (num > 80 && num <= 403) {
      return 'total-collec rank2'
    } else if (num > 403 && num <= 1210) {
      return 'total-collec rank3'
    } else if (num > 1210 && num <= 2824) {
      return 'total-collec rank4'
    } else if (num > 2824 && num <= 4842) {
      return 'total-collec rank5'
    } else if (num > 4842 && num <= 10000) {
      return 'total-collec rank6'
    } else {
      return 'total-collec'
    }

  }

  return (
    <div style={{ overflowX: "hidden" }}>

      <div className="collection">
        <div className="header-collection">
          <div className="top-collect">
            <div className="group1-header-collec">
              <div className="avatar-collec">
                <img src="./images/nft/xyz.gif" alt="" />
              </div>
              <div className="item-header-collec">
                <div className="text-item-header">Wallet address</div>
                <div className="num-item-collec">
                  {/* <img src="./images/nft/avax-icon.png" alt="" /> */}
                  <span>
                    {/* 0x3f22...42cf10 */}
                    {account ? (
                      <div onClick={gotoScan}>
                        {account?.slice(0, 6)}...{account?.slice(-6)}
                        <img className="link-acc" src="./images/nft/link.png" alt="" />
                      </div>
                    ) : 'N/A'}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="mint-bottom collec-tab">
            <Tabs defaultActiveKey={tabKey} items={items} onChange={onChange} />
          </div>
        </div>
        {tabKey === "1" ? (
          <>
            {listNFTs.length === 0 ? (

              <div className="empty">
                <img src="./images/nft/no.png" alt="" />
                <span>No Gecko are here</span>

              </div>

            ) : (
              <>
                {/* <div className="content-collection">
                  <div className="control-collec">
                    {isMobile ? (
                      <>
                        <div
                          className='control-left'
                        >
                          <div
                            className='fillter'
                            onClick={showDrawerFilter}
                          >
                            <img src="./images/nft/fillter.png" alt="" />

                          </div>
                          <div className="search-collec">
                            <input
                              type="text"
                              name=""
                              id=""
                              placeholder="Search Items"
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <div
                        className={showRow ? "control-left collapse" : "control-left"}
                      >
                        <div
                          className={showRow ? "fillter acti" : "fillter"}
                          onClick={handleChangeSize}
                        >
                          {showRow ? (
                            <img src="./images/nft/fillter-acti.png" alt="" />
                          ) : (
                            <img src="./images/nft/fillter.png" alt="" />
                          )}
                        </div>
                        <div className="search-collec">
                          <input
                            type="text"
                            name=""
                            id=""
                            placeholder="Search Items"
                          />
                        </div>
                      </div>
                    )}
                    <div className="control-right">
                      {isMobile ? (
                        <div className="ctr-right-mo" onClick={showDrawer}>
                          <img src="./images/nft/ctr-mo.png" alt="" />
                        </div>
                      ) : (
                        <Select
                          defaultValue="0"
                          style={{ width: 230, height: 44 }}
                          popupClassName="select-collec-cus"
                          onChange={handleChange}
                          options={[
                            { value: "0", label: "Price: Low to High" },
                            { value: "1", label: "Price: High to Low" },
                            { value: "2", label: "Recently Listed" },
                            { value: "3", label: "Common to Rare" },
                            { value: "4", label: "Rare to Common" },
                          ]}
                        />
                      )}

                    </div>
                  </div>
                </div> */}
                {/* <div className={showRow ? "line-collec show" : "line-collec"}></div> */}
                <div className={showRow ? "bottom-collec active" : "bottom-collec"}>
                  {/* <div className="list-select-left">
                    <div className="select-left">
                      <span className="lable-select">
                        Background <span className="num-select">(8)</span>
                      </span>
                      <Select
                        mode="multiple"
                        placeholder="Select"
                        defaultValue={["a10", "c12"]}
                        onChange={handleChangeSelectLeft}
                        popupClassName="select-collec-cus"
                        style={{
                          width: "100%",
                        }}
                        options={options}
                      />
                    </div>
                    <div className="select-left">
                      <span className="lable-select">
                        Skin <span className="num-select">(8)</span>
                      </span>
                      <Select
                        mode="multiple"
                        placeholder="Select"
                        defaultValue={["a10", "c12"]}
                        onChange={handleChangeSelectLeft}
                        popupClassName="select-collec-cus"
                        style={{
                          width: "100%",
                        }}
                        options={options}
                      />
                    </div>
                    <div className="select-left">
                      <span className="lable-select">
                        Mouth <span className="num-select">(8)</span>
                      </span>
                      <Select
                        mode="multiple"
                        placeholder="Select"
                        defaultValue={["a10", "c12"]}
                        onChange={handleChangeSelectLeft}
                        popupClassName="select-collec-cus"
                        style={{
                          width: "100%",
                        }}
                        options={options}
                      />
                    </div>
                    <div className="select-left">
                      <span className="lable-select">
                        Eyes <span className="num-select">(8)</span>
                      </span>
                      <Select
                        mode="multiple"
                        placeholder="Select"
                        defaultValue={["a10", "c12"]}
                        onChange={handleChangeSelectLeft}
                        popupClassName="select-collec-cus"
                        style={{
                          width: "100%",
                        }}
                        options={options}
                      />
                    </div>
                    <div className="select-left">
                      <span className="lable-select">
                        Headwear <span className="num-select">(8)</span>
                      </span>
                      <Select
                        mode="multiple"
                        placeholder="Select"
                        defaultValue={["a10", "c12"]}
                        onChange={handleChangeSelectLeft}
                        popupClassName="select-collec-cus"
                        style={{
                          width: "100%",
                        }}
                        options={options}
                      />
                    </div>
                    <div className="select-left">
                      <span className="lable-select">
                        Clothing <span className="num-select">(8)</span>
                      </span>
                      <Select
                        mode="multiple"
                        placeholder="Select"
                        defaultValue={["a10", "c12"]}
                        onChange={handleChangeSelectLeft}
                        popupClassName="select-collec-cus"
                        style={{
                          width: "100%",
                        }}
                        options={options}
                      />
                    </div>
                  </div> */}
                  <div className="list-item-collecs">
                    {listNFTs?.map((data: any, index: any) => {
                      return (
                        <>
                          <div className="item-collecs">
                            <div className="bao">
                              <div
                                className="img-items"
                                style={{ cursor: "pointer" }}
                                onClick={() => gotoDetail(data?.queryKey)}
                              >
                                <img
                                  style={{ borderRadius: "10px 10px 0 0" }}
                                  src={data?.nftUrl}
                                  alt={data?.name}
                                />
                              </div>
                              <div className="item-content">
                                <div className="id-item">
                                  <div className="id-collec">
                                    {data?.name}
                                  </div>

                                  <div className={renderRankColor(data?.rarityScore)}>
                                    {data?.rarityScore}
                                  </div>
                                </div>
                                {/* <div className="price-collec">
                                  <img
                                    src="./images/nft/avax-icon.png"
                                    alt=""
                                  />
                                  <span>{data?.price}</span>
                                  <img
                                    className="icon-inf"
                                    src="./images/nft/info.png"
                                    alt=""
                                  />
                                </div> */}
                                {/* <div className="last-collec">
                                  <span>Last</span>
                                  <img
                                    src="./images/nft/avax-icon.png"
                                    alt=""
                                  />
                                  <span>154</span>
                                </div> */}
                              </div>
                            </div>

                          </div>
                          {/* <div className="item-collec">
                            <div className="img-item" onClick={() => gotoDetail(data?.queryKey)} >
                              <img src={data?.nftUrl} alt="" />
                            </div>
                            <div className="item-content">
                              <div className="id-item">
                                <div className="id-collec">{data?.name}</div>
                                <div className="total-collec">{data?.price}</div>
                              </div>
                              <div className="price-collec">
                                <img src="./images/nft/avax-icon.png" alt="" />
                                <span>123,22</span>
                                <img
                                  className="icon-inf"
                                  src="./images/nft/info.png"
                                  alt=""
                                />
                              </div>
                              <div className="last-collec">
                                <span>Last</span>
                                <img src="./images/nft/avax-icon.png" alt="" />
                                <span>154</span>
                              </div>
                            </div>
                          </div> */}
                        </>
                      )
                    })}
                  </div>
                </div>

              </>
            )}
          </>
        ) : (
          <>
            {tabKey === "2" ? (
              <>
                <div className="activity-tab">
                  <div className="content-acti-group1">
                    <div className="acti-group1-title">Offer history </div>
                    <div className="group3-table">
                      <div className="group3-table-new">
                        <table className="table-offer">
                          <tr className="head-table-offer">
                            <th className="fixed-item">Item NFT</th>
                            <th className="fixed1">Type</th>
                            <th>Price</th>
                            <th>Time</th>
                            <th>From </th>
                            <th>Status</th>
                            <th>--</th>
                            <th>--</th>
                          </tr>
                          <tr className="body-table-offer">
                            <td className="fixed-name">
                              <img
                                className="img-item-offer"
                                src="./images/nft/acti-item.png"
                                alt=""
                              />
                              &ensp; Gecko #1023
                            </td>
                            <td className="fixed3">
                              <span className="type-acti">Transfer</span>
                            </td>

                            <td>
                              <img
                                className="icon-acti"
                                src="./images/nft/avax-icon.png"
                                alt=""
                              />
                              123 AVAX
                            </td>
                            <td>3h ago</td>

                            <td className="offer-add">0x517...579</td>
                            <td>
                              <span className="type-acti">Transfer</span>
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr className="body-table-offer">
                            <td className="fixed-name">
                              <img
                                className="img-item-offer"
                                src="./images/nft/acti-item.png"
                                alt=""
                              />
                              &ensp; Gecko #1023
                            </td>
                            <td className="fixed3">
                              <span className="type-acti">Transfer</span>
                            </td>

                            <td>
                              <img
                                className="icon-acti"
                                src="./images/nft/avax-icon.png"
                                alt=""
                              />
                              123 AVAX
                            </td>
                            <td>3h ago</td>

                            <td className="offer-add">0x517...579</td>
                            <td>
                              <span className="type-acti">Transfer</span>
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr className="body-table-offer">
                            <td className="fixed-name">
                              <img
                                className="img-item-offer"
                                src="./images/nft/acti-item.png"
                                alt=""
                              />
                              &ensp; Gecko #1023
                            </td>
                            <td className="fixed3">
                              <span className="type-acti">Transfer</span>
                            </td>

                            <td>
                              <img
                                className="icon-acti"
                                src="./images/nft/avax-icon.png"
                                alt=""
                              />
                              123 AVAX
                            </td>
                            <td>3h ago</td>

                            <td className="offer-add">0x517...579</td>
                            <td>
                              <span className="type-acti">Transfer</span>
                            </td>
                            <td></td>
                            <td></td>
                          </tr>

                        </table>
                      </div>

                      <div className="pagination">
                        <Pagination defaultCurrent={1} total={50} />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {tabKey === "3" ? (
                  <div className="activity-tab">
                    <div className="content-acti-group1">
                      <div className="acti-group1-title">Your offer</div>
                      <div className="group3-table">
                        <div className="group3-table-new">
                          <table className="table-offer">
                            <tr className="head-table-offer">
                              <th className="fixed-item">Item NFT</th>
                              <th className="fixed1">Type</th>
                              <th>Price</th>
                              <th>Seller</th>
                              <th>Floor Difference</th>
                              <th>Time</th>
                              <th>Status</th>
                              <th>--</th>
                            </tr>
                            <tr className="body-table-offer">
                              <td className="fixed-name">
                                <img
                                  className="img-item-offer"
                                  src="./images/nft/acti-item.png"
                                  alt=""
                                />
                                &ensp; Gecko #1023
                              </td>
                              <td className="fixed3">
                                <span className="type-acti">Fixed price</span>
                              </td>
                              <td>
                                <img
                                  className="icon-acti"
                                  src="./images/nft/avax-icon.png"
                                  alt=""
                                />
                                123 AVAX
                              </td>
                              <td className="offer-add">0x517...579</td>
                              <td>100%</td>
                              <td>2h ago</td>
                              <td>
                                <span className="type-acti">Success</span>
                              </td>
                              <td>View NFT</td>
                            </tr>
                            <tr className="body-table-offer">
                              <td className="fixed-name">
                                <img
                                  className="img-item-offer"
                                  src="./images/nft/acti-item.png"
                                  alt=""
                                />
                                &ensp; Gecko #1023
                              </td>
                              <td className="fixed3">
                                <span className="type-acti">Fixed price</span>
                              </td>
                              <td>
                                <img
                                  className="icon-acti"
                                  src="./images/nft/avax-icon.png"
                                  alt=""
                                />
                                123 AVAX
                              </td>
                              <td className="offer-add">0x517...579</td>
                              <td>100%</td>
                              <td>2h ago</td>
                              <td>
                                <span className="type-acti">Success</span>
                              </td>
                              <td>View NFT</td>
                            </tr>
                            <tr className="body-table-offer">
                              <td className="fixed-name">
                                <img
                                  className="img-item-offer"
                                  src="./images/nft/acti-item.png"
                                  alt=""
                                />
                                &ensp; Gecko #1023
                              </td>
                              <td className="fixed3">
                                <span className="type-acti">Fixed price</span>
                              </td>
                              <td>
                                <img
                                  className="icon-acti"
                                  src="./images/nft/avax-icon.png"
                                  alt=""
                                />
                                123 AVAX
                              </td>
                              <td className="offer-add">0x517...579</td>
                              <td>100%</td>
                              <td>2h ago</td>
                              <td>
                                <span className="type-acti">Success</span>
                              </td>
                              <td>View NFT</td>
                            </tr>
                            <tr className="body-table-offer">
                              <td className="fixed-name">
                                <img
                                  className="img-item-offer"
                                  src="./images/nft/acti-item.png"
                                  alt=""
                                />
                                &ensp; Gecko #1023
                              </td>
                              <td className="fixed3">
                                <span className="type-acti">Fixed price</span>
                              </td>
                              <td>
                                <img
                                  className="icon-acti"
                                  src="./images/nft/avax-icon.png"
                                  alt=""
                                />
                                123 AVAX
                              </td>
                              <td className="offer-add">0x517...579</td>
                              <td>100%</td>
                              <td>2h ago</td>
                              <td>
                                <span className="type-acti">Success</span>
                              </td>
                              <td>View NFT</td>
                            </tr>

                          </table>
                        </div>

                        <div className="pagination">
                          <Pagination defaultCurrent={1} total={50} />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {tabKey === "4" ? (
                      <div className="activity-tab">
                        <div className="content-acti-group1">
                          <div className="acti-group1-title">Activity</div>
                          <div className="group3-table">
                            <div className="group3-table-new">
                              <table className="table-offer activity">
                                <tr className="head-table-offer">
                                  <th className="fixed-item">Item NFT</th>
                                  <th className="fixed1">Type</th>
                                  <th>Rarity</th>
                                  <th>Price</th>
                                  <th>Time</th>
                                </tr>
                                <tr className="body-table-offer">
                                  <td className="fixed-name">
                                    <img
                                      className="img-item-offer"
                                      src="./images/nft/acti-item.png"
                                      alt=""
                                    />
                                    &ensp; Gecko #1023
                                  </td>
                                  <td className="fixed3">
                                    <span className="type-acti">Sale</span>
                                  </td>
                                  <td>
                                    <span className="type-acti">10,000</span>
                                  </td>
                                  <td>
                                    <img
                                      className="icon-acti"
                                      src="./images/nft/avax-icon.png"
                                      alt=""
                                    />
                                    123 AVAX
                                  </td>

                                  <td>2h ago</td>
                                </tr>
                                <tr className="body-table-offer">
                                  <td className="fixed-name">
                                    <img
                                      className="img-item-offer"
                                      src="./images/nft/acti-item.png"
                                      alt=""
                                    />
                                    &ensp; Gecko #1023
                                  </td>
                                  <td className="fixed3">
                                    <span className="type-acti">Sale</span>
                                  </td>
                                  <td>
                                    <span className="type-acti">10,000</span>
                                  </td>
                                  <td>
                                    <img
                                      className="icon-acti"
                                      src="./images/nft/avax-icon.png"
                                      alt=""
                                    />
                                    123 AVAX
                                  </td>

                                  <td>2h ago</td>
                                </tr>
                                <tr className="body-table-offer">
                                  <td className="fixed-name">
                                    <img
                                      className="img-item-offer"
                                      src="./images/nft/acti-item.png"
                                      alt=""
                                    />
                                    &ensp; Gecko #1023
                                  </td>
                                  <td className="fixed3">
                                    <span className="type-acti">Sale</span>
                                  </td>
                                  <td>
                                    <span className="type-acti">10,000</span>
                                  </td>
                                  <td>
                                    <img
                                      className="icon-acti"
                                      src="./images/nft/avax-icon.png"
                                      alt=""
                                    />
                                    123 AVAX
                                  </td>

                                  <td>2h ago</td>
                                </tr>
                              </table>
                            </div>

                            <div className="pagination">
                              <Pagination defaultCurrent={1} total={50} />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>

      <Drawer
        title="Sort by"
        placement='bottom'
        className='sort-mobie'
        height={290}
        onClose={onClose}
        open={open}
      >
        <div className="sort-mo">
          <div className="sort-mo1">Price: Low to High</div>
          <div className="sort-mo1">Price: High to Low</div>
          <div className="sort-mo1">Recently Listed</div>
          <div className="sort-mo1">Common to Rare</div>
          <div className="sort-mo1">Rare to Common</div>
        </div>
      </Drawer>
      <Drawer
        title="Filter items"
        placement='bottom'
        className='filter-mobie'
        // height={290}
        onClose={onCloseFilter}
        open={openFilter}
      >
        <div className="fiter-mo">
          <div className="item-fiter-mo">
            <div className="txt-filter-mo">
              Background <span>(8)</span>
            </div>
            <Select
              mode="multiple"
              placeholder="Select"
              defaultValue={["a10", "c12"]}
              onChange={handleChangeSelectLeft}
              popupClassName="select-collec-cus"
              style={{
                width: "100%",
              }}
              options={options}
            />
          </div>
          <div className="item-fiter-mo">
            <div className="txt-filter-mo">
              Skin <span>(23)</span>
            </div>
            <Select
              mode="multiple"
              placeholder="Select"
              defaultValue={["a10", "c12"]}
              onChange={handleChangeSelectLeft}
              popupClassName="select-collec-cus"
              style={{
                width: "100%",
              }}
              options={options}
            />
          </div>
          <div className="item-fiter-mo">
            <div className="txt-filter-mo">
              Mouth <span>(13)</span>
            </div>
            <Select
              mode="multiple"
              placeholder="Select"
              defaultValue={["a10", "c12"]}
              onChange={handleChangeSelectLeft}
              popupClassName="select-collec-cus"
              style={{
                width: "100%",
              }}
              options={options}
            />
          </div>
          <div className="item-fiter-mo">
            <div className="txt-filter-mo">
              Eyes<span>(20)</span>
            </div>
            <Select
              mode="multiple"
              placeholder="Select"
              defaultValue={["a10", "c12"]}
              onChange={handleChangeSelectLeft}
              popupClassName="select-collec-cus"
              style={{
                width: "100%",
              }}
              options={options}
            />
          </div>
          <div className="item-fiter-mo">
            <div className="txt-filter-mo">
              Headwear <span>(23)</span>
            </div>
            <Select
              mode="multiple"
              placeholder="Select"
              defaultValue={["a10", "c12"]}
              onChange={handleChangeSelectLeft}
              popupClassName="select-collec-cus"
              style={{
                width: "100%",
              }}
              options={options}
            />
          </div>
          <div className="item-fiter-mo">
            <div className="txt-filter-mo">
              Clothing<span>(50)</span>
            </div>
            <Select
              mode="multiple"
              placeholder="Select"
              defaultValue={["a10", "c12"]}
              onChange={handleChangeSelectLeft}
              popupClassName="select-collec-cus"
              style={{
                width: "100%",
              }}
              options={options}
            />
          </div>

        </div>
      </Drawer>
    </div>
  );
};

export default MyNFT;
