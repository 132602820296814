/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState, useCallback, useRef } from "react";
import "./style.css";
import Header from "../../../components/common/header";
import ConnectWallet from "../../../components/connect-wallet/ConnectWallet";
import { Pagination, Empty, Radio, Modal, Skeleton } from "antd";
import { getListMyNFT } from "../../../axios/marketNFT";
import { useActiveWeb3React } from "../../../hooks";
import { useNavigate } from "react-router-dom";
import { postData } from "../../../axios/marketNFT";
import {
  API_URL,
  BID_NFT,
  MARKET_NFT,
  MINT_NFT_CONTRACT,
  FEE_CREATOR,
  FEE_SERVICE,
  TOTAL_NFT_MINT,
} from "../../../constants";
import { formatAddress } from "./../../../utils/formats";
import { convertFromWei, convertToWei } from "../../../utils/convertNumber";
import { AbiItem } from "web3-utils";
import TimeAgo from "./../../../utils/timeAgo";
import Web3 from "web3";
import {
  notiError,
  notiSuccess,
  notiWarning,
} from "./../../../utils/notication";
import switchNetworkChain from "../../../utils/walletChain";
import { getDataByTokenID } from "./../../../hooks/getAllDataMarkets";
import {
  _approveSell,
  _listing,
  _editListing,
  _cancelListing,
  _listingBid,
  _editListingBid,
  _cancelListingBid,
} from "../../../utils/utilsNFT";
import { useContract } from "../../../hooks/useContract";
import abiMintNFT from "../../../constants/abi/abiMintNFT.json";
import abiBidNFT from "../../../constants/abi/abiBidNFT.json";
import abiNFTMarket from "../../../constants/abi/abiNFTMarket.json";
import Loading from "../../../components/Loading";
declare const window: Window & typeof globalThis & { ethereum: any };

const DetailNft = () => {
  let navigate = useNavigate();
  const currentTime = Math.floor(Date.now() / 1000);

  // common
  const web3 = new Web3(window.ethereum);
  const { account, chainId }: any = useActiveWeb3React();
  const [loadingSwitch, setLoadingSwitch] = useState<any>(false);
  const [priceNFT, setPriceNFT] = useState<any>("0");
  const [detailNFT, setDetailNFT] = useState<any>();
  const [_auctionId, setAuctionId] = useState<any>("");
  const [tokenID, setTokenID] = useState<any>("");
  const [loadingApprove, setLoadingApprove] = useState<any>(false);
  const [objData, setObjData] = useState<any>();

  // fixcel price
  const [loading, setLoading] = useState<any>(false);
  const [loadingCancel, setLoadingCancel] = useState<any>(false);
  const [loadingUpdate, setLoadingUpdate] = useState<any>(false);
  const [priceSell, setPriceSell] = useState<any>("0");
  const [history, setHistory] = useState<any>("");
  const [isChoose1, setIsChoose1] = useState(true);
  const [isChoose2, setIsChoose2] = useState(false);

  // sell to highest
  const [currentSellect, setCurrentSellect] = useState<any>("");
  const [loadingCancelBid, setLoadingCancelBid] = useState<any>(false);
  const [loadingSetBid, setLoadingSetBid] = useState<any>(false);
  const [loadingUpdatePriceBid, setUpdatePriceBid] = useState<any>(false);
  const [timeBiding, setTimeBiding] = useState<any>("");
  const [priceToken, setPriceToken] = useState<any>("0");
  const pathSymbol = window.location.pathname.split("/");
  const queryKey = pathSymbol[2];
  const workChain = 43114;

  const creatorFee = (FEE_CREATOR / 100) * Number(priceSell);
  const serviceFee = (FEE_SERVICE / 100) * Number(priceSell);
  const totalReceived = Number(priceSell) - creatorFee - serviceFee || 0;

  const contractMint: any = useContract(MINT_NFT_CONTRACT, abiMintNFT);
  const marketNFT: any = useContract(MARKET_NFT, abiNFTMarket);
  const contractBid: any = useContract(BID_NFT, abiBidNFT);
  const bidContract = new web3.eth.Contract(
    abiBidNFT as unknown as AbiItem,
    BID_NFT
  );

  const handleChoose1 = (e: any) => {
    setIsChoose1(e.target.checked);
    setIsChoose2(!e.target.checked);
  };
  const handleChoose2 = (e: any) => {
    setIsChoose2(e.target.checked);
    setIsChoose1(!e.target.checked);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const gotoDetail = async (id: any) => {
    navigate(`${"/detailNFT"}/${id}`);
  };
  // common
  const getAlldata = async () => {
    await getDataByTokenID(tokenID).then((res: any) => {
      setObjData(res);
    });
  };
  const getDetailNFT = async () => {
    let data: any = {
      queryKey: queryKey,
      ownerAddress: account,
    };
    const detail = await postData(`${API_URL}/nft/detail`, data);

    if (detail?.data) {
      setDetailNFT(detail?.data);
      setTokenID(detail?.data?.tokenId);
      setPriceNFT(detail?.data?.price);
      setAuctionId(detail?.data?.auction?.auctionId);
    }
  };

  const asyncData = async () => {
    await getDetailNFT();
    await getAlldata();
  };

  const switchChain = async () => {
    try {
      if (chainId !== workChain) {
        setLoadingSwitch(true);
        await switchNetworkChain("0xa86a");
        setLoadingSwitch(false);
      }
    } catch (error) {
      console.error("error Switch", error);
      setLoadingSwitch(false);
    }
  };
  const savePrice = async (price: any, type: number, txHash: any) => {
    let data: any = {
      queryKey: queryKey,
      ownerAddress: account,
      setPrice: Number(price),
      nftType: type,
      txHash: txHash,
      source: "",
    };
    await postData(`${API_URL}/nft/set_price`, data).catch((err) => {
      console.error("save price", err?.message);
    });
  };

  const handleApproveList = async (contract: any) => {
    try {
      setLoadingApprove(true);
      if (account) {
        await _approveSell(contractMint, contract, tokenID)
          .then((res: any) => {
            res.wait().then((resSuccess: any) => {
              asyncData();
              notiSuccess("Approve successfully", 5);
              setLoadingApprove(false);
            });
          })
          .catch((err: any) => {
            console.error("error Approve", err);
            notiError(err?.reason);
            setLoadingApprove(false);
          });
      }
    } catch (error: any) {
      console.error("error Approve", error);
      notiError(error.message);
      setLoadingApprove(false);
    }
  };
  const createHash = async (txHash: any, type: number) => {
    let data: any = {
      queryKey: queryKey,
      ownerAddress: account,
      txHash: txHash,
      type: type,
    };

    await postData(`${API_URL}/nft/create_hash`, data).catch((err) => {
      console.error("save priceBid", err?.message);
    });
  };

  useEffect(() => {
    if (queryKey) {
      getDetailNFT();
    }
  }, [queryKey, account]);

  useEffect(() => {
    if (tokenID) {
      getAlldata();
    }
  }, [tokenID]);

  // end comon

  // Fixed price
  const handleListing = async () => {
    try {
      setLoading(true);
      setIsModalOpen(false);
      if (account && Number(priceSell) > 0) {
        await _listing(
          marketNFT,
          MINT_NFT_CONTRACT,
          tokenID,
          convertToWei(priceSell, 18),
          "0x0000000000000000000000000000000000000000" //  pay address
        )
          .then((resPending: any) => {
            if (resPending?.hash) {
              createHash(resPending.hash, 4);
            }
            resPending.wait().then((resSuccess: any) => {
              if (resSuccess) {
                savePrice(priceSell, 1, resSuccess?.transactionHash);
                notiSuccess("Listed successfully", 5);
                setLoading(false);
                setTimeout(() => {
                  navigate(`${"/detailNFT"}/${queryKey}`);
                }, 1000);
              }
            });
          })
          .catch((err: any) => {
            console.error("err Listing", err);
            notiError(err?.reason, 5);
            setLoading(false);
          });
      } else {
        notiWarning("The selling price must be greater than 0", 5);
        setLoading(false);
      }
    } catch (error: any) {
      notiError(error.message, 5);
      console.error("error Listing", error);
      setLoading(false);
    }
  };
  const handleUpdatePrice = async () => {
    try {
      setLoadingUpdate(true);
      if (account) {
        await _editListing(
          marketNFT,
          MINT_NFT_CONTRACT,
          tokenID,
          convertToWei(priceSell, 18)
        )
          .then((resUpdate: any) => {
            resUpdate.wait().then((resSuccess: any) => {
              if (resSuccess) {
                savePrice(priceSell, 1, resSuccess?.transactionHash);
                notiSuccess("Update price successfully", 5);
                setLoadingUpdate(false);
                setTimeout(() => {
                  navigate(`${"/detailNFT"}/${queryKey}`);
                }, 1000);
              }
            });
          })
          .catch((err: any) => {
            console.error("error Update", err);
            notiError(err?.reason, 10);
            setLoadingUpdate(false);
          });
      }
    } catch (error: any) {
      console.error("error Update", error);
      notiError(error.message, 10);
      setLoadingUpdate(false);
    }
  };
  const cancelListed = async () => {
    try {
      setLoadingCancel(true);
      if (account) {
        await _cancelListing(marketNFT, MINT_NFT_CONTRACT, tokenID)
          .then((resCancel: any) => {
            resCancel.wait().then((resSuccess: any) => {
              if (resSuccess) {
                savePrice(0, 0, resSuccess?.transactionHash);
                setPriceNFT("0");
                asyncData();
                notiSuccess("Cancel successfully", 5);
                setLoadingCancel(false);
              }
            });
          })
          .catch((err: any) => {
            console.error("error resCancel", err);
            notiError(err?.reason, 10);
            setLoadingCancel(false);
          });
      }
    } catch (error: any) {
      console.error("error resCancel", error);
      notiError(error.message, 10);
      setLoadingCancel(false);
    }
  };
  // end Fixed price

  // Sell to highest bidder
  const saveSettingPriceBid = async (
    price: any,
    auctionId: any,
    txHash: any,
    type: number
  ) => {
    let data: any = {
      queryKey: queryKey,
      ownerAddress: account,
      expireTime: timeBiding,
      setPrice: Number(price),
      auctionId: auctionId,
      txHash: txHash,
      nftType: type,
      source: "",
    };
    await postData(`${API_URL}/nft/set_auction`, data).catch((err) => {
      console.error("save priceBid", err?.message);
    });
  };

  const handleChangeTime = (event: any) => {
    const selectedValue = event.target.value;
    setCurrentSellect(selectedValue);
    if (selectedValue !== "") {
      let timestamp;
      switch (selectedValue) {
        case "1":
          timestamp = Math.floor(Date.now() / 1000) + 1 * 24 * 60 * 60;
          break;
        case "2":
          timestamp = Math.floor(Date.now() / 1000) + 7 * 24 * 60 * 60;
          break;
        case "3":
          timestamp = Math.floor(Date.now() / 1000) + 14 * 24 * 60 * 60;
          break;
        case "4":
          timestamp = Math.floor(Date.now() / 1000) + 30 * 24 * 60 * 60;
          break;
        case "5":
          timestamp = Math.floor(Date.now() / 1000) + 30 * 60;
          break;
        case "6":
          timestamp = Math.floor(Date.now() / 1000) + 10 * 60;
          break;
        default:
          timestamp = 0;
      }
      setTimeBiding(timestamp);
    } else {
      setTimeBiding("");
    }
  };
  const handleListingBid = async () => {
    try {
      setLoadingSetBid(true);
      if (account && Number(priceSell) > 0) {
        const gasPrice = await web3.eth.getGasPrice();
        const gasEstimate = await bidContract.methods
          .createAuction(
            MINT_NFT_CONTRACT,
            "0x0000000000000000000000000000000000000000",
            tokenID,
            convertToWei(priceSell, 18),
            timeBiding
          )
          .estimateGas({
            from: account,
            gasPrice: web3.utils.toHex(String(gasPrice)),
          });
        await bidContract.methods
          .createAuction(
            MINT_NFT_CONTRACT, // contract NFT mint
            "0x0000000000000000000000000000000000000000", // payment
            tokenID, // tokenId
            convertToWei(priceSell, 18), // price auction
            timeBiding // time
          )
          .send({
            from: account,
            gasPrice: web3.utils.toHex(String(gasPrice)),
            gas: gasEstimate,
          })
          .on("transactionHash", (tx: any) => {
            if (tx) {
              createHash(tx, 2);
            }
          })
          .then((resBiding: any) => {
            if (resBiding?.status) {
              saveSettingPriceBid(
                priceSell,
                Number(resBiding?.events?.NewAuction?.returnValues[0]),
                resBiding?.transactionHash,
                2
              );
              asyncData();
              notiSuccess("Listed successfully", 5);
              setLoadingSetBid(false);
              setTimeout(() => {
                navigate(`${"/detailNFT"}/${queryKey}`);
              }, 1000);
            }
          })
          .catch((err: any) => {
            console.error("error ListingBid", err);
            notiError(err.message, 5);
            setLoadingSetBid(false);
          });
      } else {
        notiWarning("The price must be greater than 0", 5);
        setLoadingSetBid(false);
      }
    } catch (error: any) {
      console.error("error ListingBid", error);
      notiError(error.message, 5);
      setLoadingSetBid(false);
    }
  };
  const cancelListedBid = async () => {
    try {
      setLoadingCancelBid(true);
      if (account) {
        await _cancelListingBid(contractBid, _auctionId)
          .then((resCancelBid: any) => {
            resCancelBid.wait().then((resSuccess: any) => {
              if (resSuccess) {
                saveSettingPriceBid(
                  0,
                  _auctionId,
                  resSuccess?.transactionHash,
                  0
                );
                setPriceNFT("0");
                asyncData();
                notiSuccess("Cancel successfully", 5);
                setLoadingCancelBid(false);
              }
            });
          })
          .catch((err: any) => {
            console.error("error resCancelBid", err);
            notiError(err?.reason, 10);
            setLoadingCancelBid(false);
          });
      }
    } catch (error: any) {
      console.error("error resCancelBid", error);
      notiError(error.message, 5);
      setLoadingCancelBid(false);
    }
  };
  const updatePriceBid = async () => {
    try {
      setUpdatePriceBid(true);
      if (account) {
        await _editListingBid(
          contractBid,
          _auctionId,
          convertToWei(priceSell, 18)
        )
          .then((resUpdateBid: any) => {
            resUpdateBid.wait().then((resSuccess: any) => {
              if (resSuccess) {
                saveSettingPriceBid(
                  priceSell,
                  _auctionId,
                  resSuccess?.transactionHash,
                  2
                );
                // asyncData();
                notiSuccess("Update price successfully", 5);
                setUpdatePriceBid(false);
                setTimeout(() => {
                  navigate(`${"/detailNFT"}/${queryKey}`);
                }, 1000);
              }
            });
          })
          .catch((err: any) => {
            console.error("error resUpdateBid", err);
            notiError(err?.reason, 10);
            setUpdatePriceBid(false);
          });
      }
    } catch (error: any) {
      console.error("error resUpdateBid", error);
      notiError(error.message, 10);
      setUpdatePriceBid(false);
    }
  };
  // end Sell to highest bidder

  // console.log("-------------------------");
  // console.log("detailNFT", detailNFT);
  // console.log("detailNFT?.ownerAddress", detailNFT?.ownerAddress);
  // console.log("objData?.mint_AddressApprove", objData?.mint_AddressApprove);
  // console.log("BID_NFT", BID_NFT);
  // console.log("Number(detailNFT?.price)", Number(detailNFT?.price));
  return (
    <div style={{ overflowX: "hidden" }}>

      <div className="detail-NFT owner">
        <div className="div-owner">
          <div
            className="image-detail-left"
            onClick={() => gotoDetail(detailNFT?.queryKey)}
          >
            <img className="img" alt="Image" src={detailNFT?.nftUrl} />
          </div>
          <div className="detail-top-right">
            <div className="detail-group-1">
              <div className="detail-group-2">
                <div className="group-2-name">List for Sale</div>
              </div>
            </div>
            <div className="detail-group-2">
              <div className="group-2-name">{detailNFT?.name}</div>
              <div className="group-2-id">{TOTAL_NFT_MINT}</div>
            </div>
            <div className="detail-group-3">
              <div className="group-3-add">
                Owned by{" "}
                <a
                  href={`https://snowtrace.io/address/${detailNFT?.ownerAddress}`}
                  target="_blank"
                  rel="noreferrer"
                  className="offer-add"
                >
                  <span>{formatAddress(detailNFT?.ownerAddress, 6, 6)}</span>
                </a>
              </div>
              <div className="group-3-share">
                <img src="../images/nft/share.png" alt="" /> Share
              </div>
            </div>
            <div className="detail-group-4">
              <div className={`group-4-box-owner ${isChoose1 ? "choose" : ""}`}>
                <div className="box4-left">
                  <div className="box-4-fixed">Fixed price</div>
                  <div className="box-4-text">
                    The item is listed at the price you set
                  </div>
                </div>
                <div className="box4-right">
                  <input
                    type="radio"
                    onClick={handleChoose1}
                    checked={isChoose1}
                    disabled={
                      loadingApprove ||
                      loadingCancel ||
                      loadingCancelBid ||
                      loading ||
                      loadingUpdate ||
                      loadingSetBid ||
                      loadingUpdatePriceBid
                    }
                  />
                </div>
              </div>
              <div className={`group-4-box-owner ${isChoose2 ? "choose" : ""}`}>
                <div className="box4-left">
                  <div className="box-4-fixed">Sell to highest bidder</div>
                  <div className="box-4-text">
                    The item is listed for auction
                  </div>
                </div>
                <div className="box4-right">
                  <input
                    type="radio"
                    onClick={handleChoose2}
                    checked={isChoose2}
                    disabled={
                      loadingApprove ||
                      loadingCancel ||
                      loadingCancelBid ||
                      loading ||
                      loadingUpdate ||
                      loadingSetBid ||
                      loadingUpdatePriceBid
                    }
                  />
                </div>
              </div>

              <div className="owner-input">
                <span>Price(AVAX):</span>
                <input
                  type="number"
                  min={0}
                  placeholder="0.00"
                  value={priceSell}
                  onChange={(e: any) => setPriceSell(e.target.value)}
                />
              </div>
              {isChoose2 && (
                <>
                  <div
                    className="owner-input"
                    hidden={Number(detailNFT?.price) > 0}
                    style={{ padding: "20px 0" }}
                  >
                    <span>Duration:</span>
                    <div>
                      <select
                        disabled={
                          loadingApprove ||
                          loadingCancel ||
                          loadingCancelBid ||
                          loading ||
                          loadingUpdate ||
                          loadingSetBid ||
                          loadingUpdatePriceBid
                        }
                        className="select-duration full-width"
                        value={currentSellect}
                        onChange={handleChangeTime}
                      >
                        <option value="" disabled>
                          Select Time
                        </option>
                        <option value="1">1 Day</option>
                        <option value="2">7 Days</option>
                        <option value="3">14 Days</option>
                        <option value="4">30 Days</option>
                        <option value="5">30 Phut</option>
                        <option value="6">10 Phut</option>
                      </select>
                    </div>
                  </div>
                </>
              )}

              {chainId !== workChain && account && (
                <div className="row-switch-chain">
                  <button
                    className="btn-set-price"
                    style={{ width: "100%" }}
                    disabled={loadingSwitch}
                    onClick={switchChain}
                  >
                    <Loading status={loadingSwitch} content="Switch Chain" />
                  </button>
                </div>
              )}
              {!objData || !detailNFT ? (
                <div style={{ paddingTop: "10px" }}>
                  <Skeleton active paragraph={{ rows: 3 }} />
                </div>
              ) : (
                <>
                  {isChoose1 && (
                    <>
                      <div className="detail-group-5-owner">
                        {objData?.mint_OwnerOf?.toLowerCase() ===
                          account?.toLowerCase() && (
                            <>
                              {objData?.mint_AddressApprove?.toLowerCase() ===
                                MARKET_NFT?.toLowerCase() ? (
                                <>
                                  <button
                                    className="btn-set-price full-width"
                                    disabled={
                                      loading ||
                                      Number(priceSell) <= 0 ||
                                      !account ||
                                      chainId !== workChain
                                    }
                                    onClick={showModal}
                                  >
                                    <Loading
                                      status={loading}
                                      content="Set Price"
                                    />
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    className="btn-set-price full-width"
                                    disabled={
                                      loadingApprove ||
                                      !account ||
                                      chainId !== workChain
                                    }
                                    onClick={() => handleApproveList(MARKET_NFT)}
                                  >
                                    <Loading
                                      status={loadingApprove}
                                      content="Approve Sell"
                                    />
                                  </button>
                                </>
                              )}
                            </>
                          )}
                        {Number(priceNFT) > 0 &&
                          detailNFT?.ownerAddress?.toLowerCase() ===
                          account?.toLowerCase() && (
                            <>
                              <button
                                type="button"
                                hidden={detailNFT?.buyType === 2}
                                disabled={
                                  loadingUpdate ||
                                  !account ||
                                  chainId !== workChain ||
                                  Number(priceSell) <= 0 ||
                                  loadingCancel
                                }
                                className="btn-set-price"
                                onClick={handleUpdatePrice}
                              >
                                <Loading
                                  status={loadingUpdate}
                                  content="Set new price"
                                />
                              </button>
                              <button
                                type="button"
                                hidden={detailNFT?.buyType === 2}
                                disabled={
                                  loadingCancel ||
                                  !account ||
                                  chainId !== workChain ||
                                  loadingUpdate
                                }
                                className="cancel-listing"
                                onClick={cancelListed}
                              >
                                <Loading
                                  status={loadingCancel}
                                  content="Cancel Listing"
                                />
                              </button>
                            </>
                          )}
                      </div>
                    </>
                  )}
                  {isChoose2 && (
                    <>
                      <div className="detail-group-5-owner">
                        {detailNFT?.ownerAddress?.toLowerCase() ===
                          account?.toLowerCase() && (
                            <>
                              {objData?.mint_AddressApprove?.toLowerCase() ===
                                BID_NFT?.toLowerCase() ||
                                Number(detailNFT?.price) > 0 ? (
                                <>
                                  <button
                                    type="button"
                                    hidden={Number(detailNFT?.price) > 0}
                                    disabled={
                                      loadingSetBid ||
                                      loadingUpdatePriceBid ||
                                      loadingCancelBid ||
                                      !priceSell ||
                                      !timeBiding ||
                                      Number(detailNFT?.price) > 0 ||
                                      !account ||
                                      chainId !== workChain
                                    }
                                    className="btn-set-price full-width"
                                    onClick={handleListingBid}
                                  >
                                    <Loading
                                      status={loadingSetBid}
                                      content="Set price"
                                    />
                                  </button>
                                  {detailNFT?.ownerAddress?.toLowerCase() ===
                                    account?.toLowerCase() &&
                                    Number(detailNFT?.price) > 0 && (
                                      <>
                                        <button
                                          type="button"
                                          className="btn-set-price"
                                          hidden={detailNFT?.buyType === 1}
                                          disabled={
                                            !_auctionId ||
                                            loadingCancelBid ||
                                            loadingUpdatePriceBid ||
                                            Number(detailNFT?.price) <= 0 ||
                                            Number(priceSell) <= 0 ||
                                            !account ||
                                            chainId !== workChain ||
                                            currentTime >
                                            detailNFT?.auction?.expireTime
                                          }
                                          onClick={updatePriceBid}
                                        >
                                          <Loading
                                            status={loadingUpdatePriceBid}
                                            content="Set new price"
                                          />
                                        </button>
                                        <button
                                          type="button"
                                          className="cancel-listing"
                                          hidden={detailNFT?.buyType === 1}
                                          disabled={
                                            !_auctionId ||
                                            loadingCancelBid ||
                                            loadingUpdatePriceBid ||
                                            Number(detailNFT?.price) <= 0 ||
                                            !account ||
                                            chainId !== workChain
                                          }
                                          onClick={cancelListedBid}
                                        >
                                          <Loading
                                            status={loadingCancelBid}
                                            content="Cancel"
                                          />
                                        </button>
                                      </>
                                    )}
                                </>
                              ) : (
                                <>
                                  <button
                                    type="button"
                                    disabled={
                                      loadingApprove ||
                                      !account ||
                                      chainId !== workChain
                                    }
                                    className="btn-set-price full-width"
                                    onClick={() => handleApproveList(BID_NFT)}
                                  >
                                    <Loading
                                      status={loadingApprove}
                                      content="Approve Sell"
                                    />
                                  </button>
                                </>
                              )}
                            </>
                          )}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        wrapClassName="modal-ow"
        footer={false}
        title=""
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="modal-owner">
          <div className="mo-title">
            <img src="../images/nft/title-mo.svg" alt="" />
          </div>
          <div className="mo-content">
            <div className="mo-content-img">
              <img src="../images/nft/mo-img.png" alt="" />
            </div>
            <div className="mo-content-right">
              <div className="mo-content-name">{detailNFT?.name}</div>
              <div className="mo-content-ow">
                Owned by:{" "}
                <span>{formatAddress(detailNFT?.ownerAddress, 6, 6)}</span>
              </div>
              <div className="mo-content-current">Current Price:</div>
              <div className="mo-content1">
                <img src="../images/nft/avax-icon.png" alt="" />
                <span>{priceSell} Avax</span>
              </div>
              {/* <div className="mo-content-usdt">$560.00</div> */}
            </div>
          </div>

          <div className="mo-box">
            <div className="mo-box-content">
              <div className="mo-box1">
                <div className="mo-box1-left">Listing price:</div>
                <div className="mo-box1-right">
                  <img src="../images/nft/avax-icon.png" alt="" />
                  <span>{priceSell} Avax</span>
                </div>
              </div>
              <div className="mo-box1">
                <div className="mo-box1-left">Creator earnings:</div>
                <div className="mo-box1-right">
                  <span>{FEE_CREATOR}%</span>
                </div>
              </div>
              <div className="mo-box1">
                <div className="mo-box1-left">Service fee:</div>
                <div className="mo-box1-right">
                  <span>{FEE_SERVICE}%</span>
                </div>
              </div>
              <div className="mo-box1">
                <div className="mo-box1-left">Type of Sale:</div>
                <div className="mo-box1-right">
                  <span>
                    {isChoose1 ? "Fixed price" : "Sell To Highest Bidder"}
                  </span>
                </div>
              </div>
              <div className="mo-box1">
                <div className="mo-box1-left">Total potential earnings:</div>
                <div className="mo-box1-right">
                  <img src="../images/nft/avax-icon.png" alt="" />
                  <span>{totalReceived} Avax</span>
                </div>
              </div>
            </div>
          </div>

          <div className="mo-action">
            <button
              disabled={
                loading || !priceSell || !account || chainId !== workChain
              }
              onClick={handleListing}
            >
              <Loading status={loading} content="Complete listing" />{" "}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DetailNft;
