import Web3 from "web3";
import {
  MINT_NFT_CONTRACT,
  BID_NFT,
  // BSCS_CONTRACT,
  MARKET_NFT,
} from "../constants/index";
import { convertFromWei, convertToWei } from "../utils/convertNumber";
import abiNFTMarket from "../constants/abi/abiNFTMarket.json";
import abiBidNFT from "../constants/abi/abiBidNFT.json";
import abiMintNFT from "../constants/abi/abiMintNFT.json";
// import abiTokenDefault from "../abi/abiTokenDefault.json";

const web3 = new Web3(window.ethereum);

const contractMint = new web3.eth.Contract(abiMintNFT, MINT_NFT_CONTRACT);
const uniCorn = new web3.eth.Contract(abiNFTMarket, MARKET_NFT);
const contractBid = new web3.eth.Contract(abiBidNFT, BID_NFT);
// const contractToken = new web3.eth.Contract(abiTokenDefault, BSCS_CONTRACT);

export const getDataByTokenID = async (tokenId) => {
  if (typeof window.ethereum !== "undefined") {
    // contract mint
    const mint_Name = await contractMint.methods.name().call();
    const mint_MintFeeToken = await contractMint.methods.mintFeeToken().call();
    const mint_TotalSupply = await contractMint.methods.totalSupply().call();
    const mint_AddressApprove = await contractMint.methods
      .getApproved(tokenId.toString())
      .call();
    const mint_OwnerOf = await contractMint.methods
      .ownerOf(tokenId.toString())
      .call();
    return {
      mint_Name: mint_Name,
      mint_MintFeeToken: mint_MintFeeToken,
      mint_TotalSupply: mint_TotalSupply,
      mint_AddressApprove: mint_AddressApprove,
      mint_OwnerOf: mint_OwnerOf,
    };
  }
};
export const getDataByAccountAndAuctionId = async (account, auctionId) => {
  let aution_AllAuctions;
  let auction_CurrentOwnerBid;
  let auction_Owner;
  let token_balance;
  let token_allowance_market;
  let token_allowance_bid;

  if (typeof window.ethereum !== "undefined") {
    // contract auction
    if (auctionId) {
      aution_AllAuctions = await contractBid.methods
        .allAuctions(auctionId.toString())
        .call();

      auction_CurrentOwnerBid = aution_AllAuctions?.currentBidOwner;
      auction_Owner = aution_AllAuctions?.creator;
    }

    return {
      token_balance: token_balance,
      token_allowance_market: token_allowance_market,
      token_allowance_bid: token_allowance_bid,
      auction_CurrentOwnerBid: auction_CurrentOwnerBid,
      auction_Owner: auction_Owner,
    };
  }
};
export const getAllDataMarkets = async (tokenId, auctionId, account) => {
  let aution_AllAuctions;
  let auction_CurrentPriceBid;
  let auction_CurrentOwnerBid;
  let auction_Owner;
  let auction_EndAuction;
  let auction_NFT_Id;
  let auction_Status;

  let token_balance;
  let token_allowance_market;
  let token_allowance_bid;
  if (typeof window.ethereum !== "undefined") {
    // contract mint
    const mint_Name = await contractMint.methods.name().call();
    const mint_MintFeeToken = await contractMint.methods.mintFeeToken().call();
    const mint_TotalSupply = await contractMint.methods.totalSupply().call();
    const mint_AddressApprove = await contractMint.methods
      .getApproved(tokenId.toString())
      .call();
    const mint_OwnerOf = await contractMint.methods
      .ownerOf(tokenId.toString())
      .call();

    // contract market
    const market_Listings = await uniCorn.methods
      .listings(MINT_NFT_CONTRACT, tokenId)
      .call();
    const market_Owner = market_Listings?.owner;
    const market_Price = market_Listings?.price;

    // contract auction
    if (auctionId) {
      aution_AllAuctions = await contractBid.methods
        .allAuctions(auctionId.toString())
        .call();
      auction_CurrentPriceBid = convertFromWei(
        aution_AllAuctions?.currentBidPrice,
        18
      );
      auction_CurrentOwnerBid = aution_AllAuctions?.currentBidOwner;
      auction_Owner = aution_AllAuctions?.creator;
      auction_EndAuction = aution_AllAuctions?.endAuction;
      auction_NFT_Id = aution_AllAuctions?.nftId;
      auction_Status = aution_AllAuctions?.status;
    }

    return {
      mint_Name: mint_Name,
      mint_MintFeeToken: mint_MintFeeToken,
      mint_TotalSupply: mint_TotalSupply,
      mint_AddressApprove: mint_AddressApprove,
      mint_OwnerOf: mint_OwnerOf,
      market_Owner: market_Owner,
      market_Price: market_Price,
      auction_CurrentPriceBid: auction_CurrentPriceBid,
      auction_CurrentOwnerBid: auction_CurrentOwnerBid,
      auction_Owner: auction_Owner,
      auction_EndAuction: auction_EndAuction,
      auction_NFT_Id: auction_NFT_Id,
      auction_Status: auction_Status,
      token_balance: token_balance,
      token_allowance_market: token_allowance_market,
      token_allowance_bid: token_allowance_bid,
    };
  }
};
