/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.css'

import { Navigation } from 'swiper/modules';
import { isMobile } from 'react-device-detect';
import { useHookStore } from './Store';

const GecVerse = () => {

    const [state, actions]: any = useHookStore();
    const [listGame, setListGame]: any = useState([])

    const getListGame = async () => {
        try {
            await actions.getListGame()
                .then((res: any) => {
                    setListGame(res || [])
                })
                .catch((err: any) => {
                    console.log('err', err)
                })
        } catch (error) {
            console.log('error', error)
        }
    }

    useEffect(() => {
        getListGame()
    }, [])

    // const listGame: any = [
    //     {
    //         id: 1,
    //         logo: '../images/verse/itemx.png',
    //         description: "Star Allies1",
    //         name: "Alien Worlds1",
    //         gameUrl: "https://www.google.com.vn/?hl=vi"
    //     },
    // ]

    const gotoGame = (url: any) => {
        window.open(url)
    }

    return (
        <div className='main-verse'>
            <div className="container-verse">
                <div className="row-top">
                    <div className="content-row-top">
                        <img src="../images/verse/game.png" alt="" />
                        <div className='text-verse-top'>
                            <span>Gecverse</span> - Gecko Inu
                        </div>
                    </div>
                    <div className="title-verse">
                        <div className="title-verse-txt">
                            Gecko Inu <br />
                            Gaming Marketplace
                        </div>
                        <div className="dec-verse-txt">
                            Hop into Gecverse — play games, unleash your inner builder, and make new buddies!
                        </div>
                    </div>
                    <img className='verse-top img1-verse-top' src="../images/verse/img1-verse.png" alt="" />
                    <img className='verse-top img2-verse-top' src="../images/verse/img2-verse.png" alt="" />
                    <img className='verse-top img3-verse-top' src="../images/verse/img3-verse.png" alt="" />
                    <img className='verse-top img4-verse-top' src="../images/verse/img4-verse.png" alt="" />
                    <img className='verse-top img5-verse-top' src="../images/verse/img5-verse.png" alt="" />
                </div>
                <div className="row-slide">
                    <div className="title-slide">Explore Games</div>
                    <div className="content-slide">
                        {listGame && listGame.length === 0 ? (
                            <div className="empty-game">
                                <div className="no-game">
                                    <button className='btn-nogame'>Games are deploying...</button>
                                </div>
                            </div>
                        ) : (
                            <>
                                {isMobile ? (
                                    <div className='slide-mo'>
                                        {listGame && listGame.map((item: any, index: any) => (
                                            <>
                                                <div onClick={() => gotoGame(item?.gameUrl)} className={`item-slide item${item?.id % 6}`}>
                                                    <img className='img-item' src={item?.logo} alt="" />
                                                    <div className="name-item">{item?.name}</div>
                                                    <div className="dec-item">{item?.description}</div>
                                                </div>
                                            </>
                                        ))}
                                    </div>
                                ) : (
                                    <>
                                        {listGame.length === 1 ? (
                                            <div className="one-item">
                                                {listGame && listGame.map((item: any, index: any) => (
                                                    <>
                                                        <div onClick={() => gotoGame(item?.gameUrl)} className={`item-slide item${item?.id}`}>
                                                            <img className='img-item' src={item?.logo} alt="" />
                                                            <div className="name-item">{item?.name}</div>
                                                            <div className="dec-item">{item?.description}</div>
                                                        </div>
                                                    </>
                                                ))}
                                            </div>

                                        ) : (
                                            <>
                                                {listGame.length > 1 && listGame.length <= 4 ? (
                                                    <>
                                                        <div className="tow-item">
                                                            {listGame && listGame.map((item: any, index: any) => (
                                                                <>
                                                                    <div onClick={() => gotoGame(item?.gameUrl)} className={`item-slide item${item?.id} item-slide2`}>
                                                                        <img className='img-item' src={item?.logo} alt="" />
                                                                        <div className="name-item">{item?.name}</div>
                                                                        <div className="dec-item">{item?.description}</div>
                                                                    </div>
                                                                </>
                                                            ))}
                                                        </div>

                                                    </>
                                                ) : (
                                                    <>
                                                        <Swiper
                                                            mousewheel={false}
                                                            slidesPerView={5}
                                                            spaceBetween={0}
                                                            modules={[Navigation]}
                                                            navigation={listGame.length === 5 ? false : true}
                                                            className="mySwiper"
                                                            loop={true}
                                                            centeredSlides={true}
                                                            centerInsufficientSlides={true}
                                                            allowTouchMove={false}
                                                        >

                                                            {listGame && listGame.map((item: any, index: any) => (
                                                                <>
                                                                    <SwiperSlide>
                                                                        {item?.id > 6 ? (
                                                                            <>
                                                                                <div onClick={() => gotoGame(item?.gameUrl)} className={`item-slide item${item?.id % 6}`}>
                                                                                    <img className='img-item' src={item?.logo} alt="" />
                                                                                    <div className="name-item">{item?.name}</div>
                                                                                    <div className="dec-item">{item?.description}</div>
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <div onClick={() => gotoGame(item?.gameUrl)} className={`item-slide item${item?.id}`}>
                                                                                <img className='img-item' src={item?.logo} alt="" />
                                                                                <div className="name-item">{item?.name}</div>
                                                                                <div className="dec-item">{item?.description}</div>
                                                                            </div>
                                                                        )}

                                                                    </SwiperSlide>
                                                                </>
                                                            ))}
                                                        </Swiper>
                                                    </>
                                                )}

                                            </>
                                        )}
                                    </>

                                )}

                            </>
                        )}




                    </div>
                </div>
            </div>
        </div>
    )
}

export default GecVerse