import {
    createStore,
    createHook,
    createContainer,
    createSubscriber,
} from "react-sweet-state";


const Store = createStore({
    initialState: {
        balance: 0,
        acc: "",
        net: "",
    },
    actions: {
        updateAcc:
            (acc) =>
                ({ setState, getState }) => {
                    setState({ acc: acc || "" });
                },

        updateNet:
            (net) =>
                ({ setState, getState }) => {
                    setState({ net: net || "" });
                },
        setBalace:
            (num) =>
                ({ setState, getState }) => {
                    setState({ balance: num || 0 });
                },
    },
    name: "Store",
});

export const useHook = createHook(Store);
export const Container = createContainer(Store, {
    onInit:
        () =>
            ({ setState }, props) => {
                setState({ ...props });
            },
});

export const Subscriber = createSubscriber(Store);
