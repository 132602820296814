import web3 from "web3";
import BigNumber from 'bignumber.js'

export const convertTokenToWei = (value, decimal) => {
  let amount = web3.utils.toWei(value.toString(), "ether");
  if (decimal !== 18)
    amount = new BigNumber(value)
      .times(new BigNumber(10).pow(decimal))
      .toString();
  return amount;
};

export const convertToWei = (value, decimal) => {
  console.log('value && decimal', value && decimal)
  if (value && decimal) {
    if (Number(decimal) === 18)
      return web3.utils.toWei(value.toString(), "ether");
    else if (Number(decimal) === 6)
      return web3.utils.toWei(value.toString(), "picoether");
    else if (Number(decimal) === 9)
      return web3.utils.toWei(value.toString(), "nanoether");
    else if (Number(decimal) === 12)
      return web3.utils.toWei(value.toString(), "microether");
    else if (Number(decimal) === 15)
      return web3.utils.toWei(value.toString(), "milliether");
    else return value.toWei() / 10 ** Number(decimal);
  } else {
    return "";
  }
};

export const convertFromWei = (value, decimal) => {
  try {
    if (Number(decimal) === 18)
      return web3.utils.fromWei(value.toString(), "ether");
    else if (Number(decimal) === 6)
      return web3.utils.fromWei(value.toString(), "picoether");
    else if (Number(decimal) === 9)
      return web3.utils.fromWei(value.toString(), "nanoether");
    else if (Number(decimal) === 12)
      return web3.utils.fromWei(value.toString(), "microether");
    else if (Number(decimal) === 15)
      return web3.utils.fromWei(value.toString(), "milliether");
    else return value.toString() / 10 ** Number(decimal);
  } catch (error) {
    return 0;
  }
};


const BIG_TEN = new BigNumber(10)
const AVAX_BLOCK_TIME = 2
const BLOCKS_PER_YEAR = new BigNumber(
  (60 / AVAX_BLOCK_TIME) * 60 * 24 * 365,
)

export const getPoolApr = (
  stakingTokenPrice,
  rewardTokenPrice,
  totalStaked,
  tokenPerBlock,
  decimalTokenEarning,
) => {

  const totalRewardPricePerYear = new BigNumber(rewardTokenPrice)
    .times(new BigNumber(tokenPerBlock).div(BIG_TEN.pow(decimalTokenEarning)))
    .times(BLOCKS_PER_YEAR)
  const totalStakingTokenInPool = new BigNumber(stakingTokenPrice).times(
    totalStaked,
  )
  const apr = totalRewardPricePerYear.div(totalStakingTokenInPool).times(100)
  // console.log('apr----', Number(apr))
  return apr.isNaN() || !apr.isFinite() ? null : apr.toNumber()
}