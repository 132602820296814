import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Collapse, Modal } from "antd";
import { isMobile } from 'react-device-detect';
import { CaretRightOutlined, SettingOutlined } from '@ant-design/icons';
import Marquee from "react-fast-marquee";
import AOS from 'aos';
import 'aos/dist/aos.css';

import Header from "../../../components/common/header";
import './style.css'
import { useActiveWeb3React } from '../../../hooks';
import { useWeb3ModalState } from '@web3modal/wagmi/react'
import {
    useAccount
} from "wagmi";

const HomeNFT = () => {
    const { selectedNetworkId } = useWeb3ModalState()
    const { address } = useAccount();
    const [comingsoon, setComingsoon] = useState('Mint Now')
    const [comingsoon1, setComingsoon1] = useState('')
    let navigate = useNavigate();

    const gotoExplore = () => {
        navigate("/gecko-nft");
    }

    const gotoMyNFT = () => {
        navigate("/my-nft");
    }

    const { Panel } = Collapse;
    const text1 = `
  We are the most friendly lizard on AVAX—Gecko Inu! Founded by well-respected members with a high AVAX culture. GEC is a community-driven project and will be only for the community. The contract has been fully renounced and liquidity has been burned.
`;
    const text2 = `
  NFTs? They're like collectible digital stickers that you totally own! Each one's a special snowflake on the blockchain, not like those copy-paste coins. Art, tunes, weird GIFs - if it's digital, it can be an NFT.
`;
    const text3 = `
  "Why AVAX? 'Cause it's fast, cheap, and cool, just like us memes! It's the speedy Gonzales of blockchains, making DeFi fun and NFTs a breeze. Low fees, high speed – AVAX is where it's at!
`;
    const text4 = `
  They're like your digital squad of cool lizards! 🦎 Each one's a unique digital gem with our own swag. Plus, owning one might snag you special community perks, making you part of the exclusive Gecko gang. It's like being in a VIP club, but with digital lizards!
`;
    const onChange = (key: string | string[]) => {
        console.log(key);
    };
    type ExpandIconPosition = 'start' | 'end';
    const [expandIconPosition, setExpandIconPosition] = useState<ExpandIconPosition>('end');

    useEffect(() => {
        AOS.init();
    }, [])

    const gotoTw = () => {
        window.open('https://Twitter.com/GeckoInuAvax')
    }
    const gotoTele = () => {
        window.open('https://t.me/geckoinuavax')
    }
    const onchageText = () => {
        // setComingsoon('Coming soon')
        setComingsoon1('activehover')
    }
    const onchageTextLea = () => {
        setComingsoon('Mint Now')
        setComingsoon1('')
    }


    useEffect(() => {
        let myID: any = document.getElementById("myID");
        console.log('myID', myID)
        const myScrollFunc = function () {
            var y = window.scrollY;
            if (myID) {
                if (y >= 200 && y < 1400) {
                    myID.className = "slide-home show"
                } else {
                    myID.className = "slide-home hide"
                }
            }

        };

        window.addEventListener("scroll", myScrollFunc);
    }, [])



    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const gotoJoe = () => {
        window.open('https://joepegs.com/collections/avalanche/0x2da215293f66319facdf8fe41d99524a406d4c8f')
    }
    const gotoOpen = () => {
        window.open('https://opensea.io/collection/gecko-inu-nft-collection-1?tab=items')
    }
    const gotoHyper = () => {
        window.open('https://avax.hyperspace.xyz/collection/avax/007f119d-e48c-4390-9331-0c2bde2468c1')
    }

    return (
        <div style={{ overflowX: "hidden" }}>

            <div className="container-home">
                <div className="home-top">
                    <div className="title-top-nft">
                        <div className="img-title-home">
                            {isMobile ? (
                                <img src="./images/nft/title-home-top-mo2.svg" alt="" />
                            ) : (
                                <img src="./images/nft/title-home-top2.svg" alt="" />
                            )}

                        </div>

                        <div className="mid-top">
                            <div className="mid-top-left">
                                <span className="mid-top-left1">GEC is a community-driven </span>
                                <span className="mid-top-left1">project and this new NFT </span>
                                <span className="mid-top-left1">collection is for the </span>
                                <span className="mid-top-left1">community. </span>
                            </div>
                            <div className="mid-top-button">
                                <button className="mint-home" onClick={gotoMyNFT}>My NFTs</button>
                                <button
                                    className={`explore-home`}
                                    onClick={showModal}
                                    onMouseOver={onchageText}
                                    onMouseLeave={onchageTextLea}
                                >Explore NFTs</button>
                            </div>
                            <div className="mid-top-left2">
                                <span className="mid-top-left1">10,000 distinct and dope </span>
                                <span className="mid-top-left1">Geckos are set to </span>
                                <span className="mid-top-left1">revolutionize Avax culture</span>
                                <span className="mid-top-left1">with unparalleled diversity</span>
                                <span className="mid-top-left1">and flair.</span>
                            </div>
                        </div>
                        <div className="bot-top">
                            <div className="meme1" data-aos="fade-up" data-aos-duration="1500" data-aos-anchor-placement="top-bottom">
                                <img src="./images/Left-1.png" alt="" />
                            </div>
                            <div className="meme2" data-aos="fade-up" data-aos-duration="1100" data-aos-anchor-placement="top-bottom">
                                <img src="./images/Left-2.png" alt="" />
                            </div>
                            <div className="meme3"
                                data-aos="fade-up" data-aos-duration="700"
                                data-aos-anchor-placement="top-bottom"
                            >
                                <img src="./images/Center-1.png" alt="" />
                            </div>

                            <div className="meme4" data-aos="fade-up" data-aos-duration="1100" data-aos-anchor-placement="top-bottom">
                                <img src="./images/Right-2.png" alt="" />
                            </div>
                            <div className="meme5" data-aos="fade-up" data-aos-duration="1500" data-aos-anchor-placement="top-bottom">
                                <img src="./images/Right-1.png" alt="" />
                            </div>

                        </div>
                    </div>
                </div>

                {/* <div className="infor-home-nft">
                    <div className="infor-item">
                        <div className="infor-item-text">Total volume</div>
                        <div className="infor-item-num">22,213,034</div>
                    </div>
                    <div className="infor-item">
                        <div className="infor-item-text">Floor price</div>
                        <div className="infor-item-num">23,034</div>
                    </div>
                   
                    <div className="infor-item">
                        <div className="infor-item-text">Listed</div>
                        <div className="infor-item-num">4.653%</div>
                    </div>
                    <div className="infor-item">
                        <div className="infor-item-text">Owner (Unique)</div>
                        <div className="infor-item-num">1,023 (17%)</div>
                    </div>
                </div> */}

                <div className="home-our">
                    <div className="our-left">
                        <img className="img-our1 animation-spin" src="./images/nft/our1.png" alt="" />
                        <img className="img-our" src="./images/nft/our.svg" alt="" />
                        <img className="img-our2 animation-spin1" src="./images/nft/our2.png" alt="" />
                    </div>
                    <div className="our-right">
                        <div className="text-our">
                            Get ready for the exclusive release of 10,000 UNIQUE GECKO CHARACTERS, each brimming with personality and style. This collection is a tribute to our vibrant community on AVAX, embodying the spirit of innovation and unity. Dive into the world of Gecko Inu NFTs and experience the diversity of our digital ecosystem!

                        </div>
                        <div className="action-our">
                            {/* <button
                                // className="our-ex"
                                className="our-mint"
                                // onClick={gotoMintNFT}
                                onMouseOver={onchageText}
                                onMouseLeave={onchageTextLea}
                            >{comingsoon}</button> */}
                            <button className="our-ex"
                                onMouseOver={onchageText}
                                onMouseLeave={onchageTextLea}
                                onClick={showModal}
                            >Explore NFTs</button>
                        </div>
                    </div>
                </div>

                <div id='myID' className="slide-home">
                    <div className="slide-nft">
                        <Marquee
                            speed={90}
                            pauseOnHover={true}
                        >
                            <img src="./images/Gec-NFT/1.png" alt="" />
                            <img src="./images/Gec-NFT/2.png" alt="" />
                            <img src="./images/Gec-NFT/3.png" alt="" />
                            <img src="./images/Gec-NFT/4.png" alt="" />
                            <img src="./images/Gec-NFT/5.png" alt="" />
                            <img src="./images/Gec-NFT/6.png" alt="" />
                            <img src="./images/Gec-NFT/7.png" alt="" />
                            <img src="./images/Gec-NFT/8.png" alt="" />
                            <img src="./images/Gec-NFT/9.png" alt="" />
                            <img src="./images/Gec-NFT/10.png" alt="" />
                            <img src="./images/Gec-NFT/11.png" alt="" />
                            <img src="./images/Gec-NFT/12.png" alt="" />
                        </Marquee>
                    </div>
                    <div className="slide-nft">
                        <Marquee
                            speed={90}
                            pauseOnHover={true}
                            direction='right'
                        >

                            <img src="./images/Gec-NFT/13.png" alt="" />
                            <img src="./images/Gec-NFT/14.png" alt="" />
                            <img src="./images/Gec-NFT/15.png" alt="" />
                            <img src="./images/Gec-NFT/16.png" alt="" />
                            <img src="./images/Gec-NFT/17.png" alt="" />
                            <img src="./images/Gec-NFT/18.png" alt="" />
                            <img src="./images/Gec-NFT/19.png" alt="" />
                            <img src="./images/Gec-NFT/20.png" alt="" />
                            <img src="./images/Gec-NFT/21.png" alt="" />
                            <img src="./images/Gec-NFT/22.png" alt="" />
                            <img src="./images/Gec-NFT/23.png" alt="" />
                            <img src="./images/Gec-NFT/24.png" alt="" />
                        </Marquee>
                    </div>
                </div>

                <div className="collapse-content">
                    <div className="collap-text">
                        <img src="./images/nft/collap-text.svg" alt="" />
                    </div>
                    <Collapse
                        defaultActiveKey={['0']}
                        onChange={onChange}
                        expandIcon={({ isActive }) =>
                            <img className="expan1" src={`${isActive ? ('./images/nft/expan.png') : ('./images/nft/expan2.png')}`}
                                alt="" />
                        }
                        expandIconPosition={expandIconPosition}
                    >
                        <Panel header="What is the gecko inu" key="1">
                            <div>{text1}</div>
                        </Panel>
                        <Panel header="What is NFT" key="2">
                            <div>{text2}</div>
                        </Panel>
                        <Panel header="Why avax chain" key="3">
                            <div>{text3}</div>
                        </Panel>
                        <Panel header="What are the benefits" key="4">
                            <div>{text4}</div>
                        </Panel>
                    </Collapse>
                </div>

                <div className="home-footer">
                    <div className="footer-content">
                        <div className="title-footer">GECKO INU NFT COLLECTION</div>
                        <div className="dec-footer">
                            <img src="./images/nft/flus.svg" alt="" />
                        </div>
                        <div className="social-footer">
                            <div className="tele-footer" onClick={gotoTele}>
                                <img src="./images/nft/tele-footer1.png" alt="" />
                            </div>
                            <div className="tw-footer" onClick={gotoTw}>
                                <img src="./images/nft/tw-footer1.png" alt="" />
                            </div>
                        </div>
                        <div className="copyright">Copyright © 2024. All rights reserved.</div>
                    </div>
                </div>
            </div>

            <Modal
                wrapClassName="modal-ow"
                footer={false}
                title=""
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <div className="modal-owner">
                    <div className="modal-link">
                        <div className="title-link">
                            Explore NFTs
                        </div>
                        <div className="trade-link">
                            <div className="item-trade" onClick={gotoJoe}>Trade on <p>Joepegs <p className="by-trade">by Trader Joe</p></p>

                            </div>
                            <div className="item-trade" onClick={gotoOpen}>Trade on <p>
                                <img src="./images/nft/opensea.png" alt="" />
                                OpenSea
                            </p> </div>
                            <div className="item-trade" onClick={gotoHyper}>Trade on <p>
                                <img src="./images/nft/hyper.jpeg" alt="" />
                                HyperSpace</p> </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default HomeNFT;