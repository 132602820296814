
import { isMobile } from 'react-device-detect';
import './style.css'

const Bot = () => {

    const url = 'https://t.me/gecko_inu_avax_bot'
    const urlMore = 'https://gecko-inu.gitbook.io/gecko-inu-ecosystem/gecko-bot/what-is-gecko-bot'
    const goToTele = () => {
        window.open(url)
    }
    const goToMore = () => {
        window.open(urlMore)
    }

    return (
        <>
            <div className='container-bot'>
                <div className="section-group1-bot">
                    <div className="group1-bot-left">
                        <img src="./images/bot/gifweb.gif" alt="" />

                    </div>
                    <div className="group1-bot-right">
                        {isMobile ? (
                            <img src="./images/bot/left-bot-mo.svg" alt="" />
                        ) : (
                            <img src="./images/bot/right-bot.svg" alt="" />
                        )}

                        <div className="group1-bot-text">
                            Make More Money Trading with Avax's Fastest Telegram Bot
                        </div>
                        <div className="group1-bot-action">
                            <button onClick={goToTele} className='btn-bot btn-trading-bot'>Start Trading</button>
                            <button onClick={goToMore} className='btn-bot btn-more-bot'>Learn more</button>
                        </div>
                    </div>
                </div>

                <div className="section-features">
                    <div className="img-fea">
                        <img src="./images/bot/feature.svg" alt="" />
                    </div>
                    <div className="dec-features">
                        Blazingly-fast trading at your fingertips with our free-to-use Telegram bot.
                    </div>
                    <div className="list-feature">
                        <div className="list-item-fea">
                            <div className="item-feature">
                                <div className="icon-item">
                                    <img src="./images/bot/icon-item1.png" alt="" />
                                </div>
                                <div className="title-item">Auto Buy</div>
                                <div className="dec-item-fea">
                                    Activate Auto Buy so that when you paste a token address GECKObot immediately purchases the coin. No faster way to trade.
                                </div>
                            </div>
                            <div className="item-feature">
                                <div className="icon-item">
                                    <img src="./images/bot/icon-item2.png" alt="" />
                                </div>
                                <div className="title-item">Referrals</div>
                                <div className="dec-item-fea">
                                    Refer your friends and earn 30% of their fees in the first month. 20% in the second and 10% forever!
                                </div>
                            </div>
                            <div className="item-feature">
                                <div className="icon-item">
                                    <img src="./images/bot/icon-item3.png" alt="" />
                                </div>
                                <div className="title-item">PNL Overview</div>
                                <div className="dec-item-fea">
                                    Comprehensive position management, tracking Market Cap, Profit After Fees, Liquidity, Token Balance, and Recent Price Changes to help you spot trends.                            </div>
                            </div>
                            <div className="item-feature">
                                <div className="icon-item">
                                    <img src="./images/bot/icon-item4.png" alt="" />
                                </div>
                                <div className="title-item">Buy and Burn</div>
                                <div className="dec-item-fea">
                                    100% of fees from trades go directly to buying $GEC, and 10% of all fees instantly burn $GEC                            </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="section-why w">
                <div className='container-bot'>
                    <div className="content-why">
                        <div className="img-why">
                            <img src="./images/bot/img-why.svg" alt="" />
                        </div>
                        <div className="dec-why">
                            GECKObot is the fastest way to buy, sell, and manage your trades - keeping you in control.
                        </div>
                        <div className="btn-why">
                            <button onClick={goToTele}>Start Trading</button>
                        </div>
                        <div className="table-why">
                            <img src="./images/bot/img-table.svg" alt="" />
                        </div>
                        {/* <div className="f2" onClick={() => setOpen(true)}>
                            <img src="./images/bot/f2.png" alt="" />
                        </div> */}
                    </div>

                </div>
                <img className='w1' src="./images/bot/w1.png" alt="" />
                {isMobile ? (
                    <img className='w2' src="./images/bot/w2-mo.png" alt="" />
                ) : (
                    <img className='w2' src="./images/bot/w2.png" alt="" />
                )}

            </div>
            <div className='container-bot-botom'>
                <div className="content-bottom">
                    <div className="botom-bot-left">
                        <div className="text-bot-botom">
                            All Fees Go To Buying $GEC
                        </div>
                        <button onClick={goToTele} className='btn-bot btn-trading-bot'>Start Trading</button>
                    </div>
                    <div className="botom-bot-right">
                        <img src="./images/bot/ps.gif" alt="" />
                    </div>
                </div>

            </div>
        </>

    )
}

export default Bot;