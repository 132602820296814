import moment from "moment";

export const formatNumber = (x, max) => {
  let tem = x * 10000000
  let tem1 = Math.floor(tem)
  let tem2 = tem1 / 10000000
  if (tem2) {
    return x.toLocaleString("en-US", {
      minimumFractionDigits: 1,
      maximumFractionDigits: max,
    });
  } else return 0;
};

export const formatDate = (timer) => {
  let dateFormat = new Date(timer).toLocaleTimeString("en-GB", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZone: "UTC",
    timeZoneName: "short",
    hour12: true,
  });

  dateFormat = dateFormat.replace(",", "");
  dateFormat = dateFormat.replace("UTC", "");

  return dateFormat;
};

export const formatUTCDate = (time) => {
  return moment(new Date(time).toUTCString()).utcOffset(0).utc();
};

export const convertDateTime = (dateTime) => {
  var options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "UTC",
    timeZoneName: "short",
    hour12: true,
  };

  return dateTime.toLocaleTimeString("en-GB", options);
};

export const formatAddress = (address, before, after) => address &&
  `${address.substring(0, before || 4)}.....${address.substring(address.length - after || 6)}`;

export const rendererCountDown = ({
  days,
  hours,
  minutes,
  seconds,
  completed,
}) => {
  // Render a countdown
  if (completed) {
    return <span>Finished!</span>;
  }
  if (days > 1) {
    return (
      <span>
        {days}days : {hours}h : {minutes}m : {seconds}s
      </span>
    );
  } else if (days === 1) {
    return (
      <span>
        {days}day : {hours}h : {minutes}m : {seconds}s
      </span>
    );
  } else {
    return (
      <span>
        {hours}h : {minutes}m : {seconds}s
      </span>
    );
  }
};