import React from 'react';
import './App.css';

import {
  Route,
  Routes,
  BrowserRouter as Router,
  Navigate,
} from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";


import Home from './page';

// import Refer from './page/refer';
import WallPaper from './page/wallpaper';
import MintNFT from './page/NFT/MintNFT';
import DetailNft from './page/NFT/DetailNFT';
import OwnerNFT from './page/NFT/OwnerNFT';
// import Collection from './page/NFT/Collection';
import LaunchPad from './page/LaunchPad';
import MyNFT from './page/NFT/MyNFT';
import HomeNFT from './page/NFT/HomeNFT';
import Launchpool from './page/LaunchPool';

// import ComingSoon from './page/Comingsoon/index'
// import ComingSoonLaunchPad from './page/ComingsoonLaunchpad';
// import ComingSoonVerse from './page/ComingsoonVesre';
import Main from './components/Main';
import SwapPage from './page/Swap';
import DetailsLaunchPad from './page/LaunchPad/Details';


import { createWeb3Modal } from '@web3modal/wagmi/react'
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'

import { WagmiProvider } from 'wagmi'
import { arbitrum, mainnet, avalanche } from 'wagmi/chains'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import GecVerse from 'page/GecVerse';
import Bot from 'page/Bot';
import Airdrop from 'page/Airdrop/airdrop';

// 0. Setup queryClient
const queryClient = new QueryClient()
// 1. Get projectId at https://cloud.walletconnect.com
const projectId = '90442772461be13996b4d4766e81fd8f'
// 2. Create wagmiConfig
const metadata = {
  name: 'Web3Modal',
  description: 'Web3Modal Example',
  url: 'https://web3modal.com', // origin must match your domain & subdomain
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}

const chains = [avalanche] as const
const config = defaultWagmiConfig({
  chains, // required
  projectId, // required
  metadata, // required
  enableWalletConnect: true, // Optional - true by default
  enableInjected: true, // Optional - true by default
  enableEIP6963: true, // Optional - true by default
  enableCoinbase: true, // Optional - true by default
})

// 3. Create modal
createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true // Optional - defaults to your Cloud configuration
})


function App() {
  i18next.init({
    interpolation: { escapeValue: false },
  });
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <I18nextProvider i18n={i18next}>
          <Main>
            <Routes>
              <Route path="/" element={<Home />} />
              {/* <Route path="/airdrop" element={<Airdrop />} /> */}
              <Route path='/nft' element={<HomeNFT />} />
              <Route path='/mint-nft' element={<MintNFT />} />
              <Route path='/gecko-art' element={<WallPaper />} />
              <Route path='/my-nft' element={<MyNFT />} />
              <Route path='/detailNFT/:id' element={<DetailNft />} />
              <Route path='/ownerNFT/:id' element={<OwnerNFT />} />
              <Route path='/staking' element={<Launchpool />} />
              <Route path='/bot' element={<Bot />} />

              {/* <Route path='/staking' element={<ComingSoon />} /> */}
              {/* <Route path='/launchpad' element={<ComingSoonLaunchPad />} /> */}
              <Route path='/launchpad' element={<LaunchPad />} />
              <Route path='/launchpad/details' element={<DetailsLaunchPad />} />
              {/* <Route path='/gecverse' element={<ComingSoonVerse />} /> */}
              <Route path='/gecverse' element={<GecVerse />} />

              <Route path='/swap/*' element={<SwapPage />} />
              <Route path="*" element={<Navigate to="/" replace />} />


              {/* <Route path='/detailNFT/:id' element={<DetailNft />} />
              <Route path='/ownerNFT/:id' element={<OwnerNFT />} />
              <Route path='/collection' element={<Collection />} />
              <Route path='/my-nft' element={<MyNFT />} />
              <Route path='/home-nft' element={<HomeNFT />} /> */}
              {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
            </Routes>
          </Main>
        </I18nextProvider>
      </QueryClientProvider>
    </WagmiProvider>

  );
}

export default App;
