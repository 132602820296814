/* eslint-disable prefer-template */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return, no-param-reassign */
import {
    createStore,
    createHook,
    createContainer,
    createSubscriber,
} from "react-sweet-state";
import axios from "axios";
import { API_IDO_URL } from '../../constants/index'


const Store = createStore({
    initialState: {},
    actions: {

        getListAllIDO:
            (data) =>
                ({ setState }) => {
                    return new Promise((resolve, reject) => {
                        axios.post(`${API_IDO_URL}/launchpad/all_idos`, data).then((res) => {
                            // console.log('res', res)
                            resolve(res?.data?.data);

                        });
                    });
                },
        getListTrendIDO:
            () =>
                ({ setState }) => {
                    return new Promise((resolve, reject) => {
                        axios.get(`${API_IDO_URL}/launchpad/trending`).then((res) => {
                            // console.log('res', res)
                            resolve(res?.data?.data);

                        });
                    });
                },
        getListEndIDO:
            (data) =>
                ({ setState }) => {
                    return new Promise((resolve, reject) => {
                        axios.post(`${API_IDO_URL}/launchpad/ended_idos`, data).then((res) => {
                            // console.log('res', res)
                            resolve(res?.data?.data);

                        });
                    });
                },
        getListUpComingIDO:
            (data) =>
                ({ setState }) => {
                    return new Promise((resolve, reject) => {
                        axios.post(`${API_IDO_URL}/launchpad/upcoming`, data).then((res) => {
                            // console.log('res', res)
                            resolve(res?.data?.data);

                        });
                    });
                },
        getListOpenIDO:
            () =>
                ({ setState }) => {
                    return new Promise((resolve, reject) => {
                        axios.get(`${API_IDO_URL}/launchpad/open_idos`).then((res) => {
                            // console.log('res', res)
                            resolve(res?.data?.data);

                        });
                    });
                },

        getDetailIDO:
            (data) =>
                ({ setState }) => {
                    return new Promise((resolve, reject) => {
                        axios.post(`${API_IDO_URL}/launchpad/detail`, data).then((res) => {

                            resolve(res?.data?.data);

                        });
                    });
                },
        submitAllocation:
            (data) =>
                ({ setState }) => {
                    return new Promise((resolve, reject) => {
                        axios.post(`${API_IDO_URL}/launchpad/commit_fund`, data).then((res) => {

                            resolve(res?.data?.data);

                        })
                            .catch((err) => {
                                reject(err)
                            })
                    });
                },
        commited:
            (data) =>
                ({ setState }) => {
                    return new Promise((resolve, reject) => {
                        axios.post(`${API_IDO_URL}/launchpad/commited`, data).then((res) => {
                            // console.log('res commited', res)
                            resolve(res?.data?.data);

                        })
                            .catch((err) => {
                                reject(err)
                            })
                    });
                },


    },

    name: "Store",
});

export const useHookStore = createHook(Store);
export const Container = createContainer(Store, {
    onInit:
        () =>
            ({ setState }, props) => {
                setState({ ...props });
            },
});
export const Subscriber = createSubscriber(Store);
