import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { message } from 'antd';
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Modal } from 'antd';
import { useNavigate } from "react-router-dom";

import './style.css'

import cn from '../components/cn/common.json'
import en from '../components/en/common.json'
import po from '../components/po/common.json'


i18next.init({
    interpolation: { escapeValue: false },
    lng: 'en',
    resources: {
        en: {
            common: en
        },
        cn: {
            common: cn
        },
        po: {
            common: po
        }
    },
});


const Home = () => {
    let navigate = useNavigate();
    const [showMore, setShowMore]: any = useState(false)


    const [isModalOpen, setIsModalOpen]: any = useState(0);

    const gotoBanner = () => {

        navigate("/launchpad");
    }


    useEffect(() => {
        if (!sessionStorage.getItem('banner')) {
            setTimeout(() => {
                setIsModalOpen(1)
            }, 500);
        }


    })

    useEffect(() => {
        if (sessionStorage.getItem('banner')) {
            if (sessionStorage.getItem('banner') === '1') {
                setIsModalOpen(2)
            } else {
                setIsModalOpen(1)
            }
        } else {
            setIsModalOpen(1)
        }
    }, [])

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(2);
        sessionStorage.setItem('banner', '1');
    };

    const handleCancel = () => {
        setIsModalOpen(2);
        sessionStorage.setItem('banner', '1');
    };

    const { t, i18n }: any = useTranslation('common');
    const gotoTw = () => {
        window.open('https://Twitter.com/GeckoInuAvax')
    }
    const gotoTele = () => {
        window.open('https://t.me/geckoinuavax')
    }
    const gotoSwap = () => {
        window.open('https://traderjoexyz.com/avalanche/trade?inputCurrency=AVAX&outputCurrency=0xE8385CECb013561b69bEb63FF59f4d10734881f3')
    }
    const gotoChart = () => {
        window.open('https://dexscreener.com/avalanche/0xE8385CECb013561b69bEb63FF59f4d10734881f3')
    }

    const gotoChartTool = () => {
        let date = Date.now()
        console.log('date', date)
        window.open(`https://www.dextools.io/app/en/avalanche/pair-explorer/0x2ade4f6d462d60523235650160844bb6b46ac7d9?t=${Date.now()}`)
    }


    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem('valuelang'))
    }, [])

    const handleReadmore = () => {
        setShowMore(!showMore)
    }

    const copiedHandle = () => {
        navigator.clipboard.writeText("0xE8385CECb013561b69bEb63FF59f4d10734881f3")
        message.success('Copied!!!');
    }

    const gotoBurn = () => {
        window.open('https://snowtrace.io/tx/0xac1747ace441fc5e4de944e9300ecc6d9d57be31f9cb5b0f32d27bc53b425694?chainId=43114')
    }

    const gotoJoe = () => {
        window.open('https://joepegs.com/collections/avalanche/0x2da215293f66319facdf8fe41d99524a406d4c8f')
    }

    const gotoOpen = () => {
        window.open('https://opensea.io/collection/gecko-inu-nft-collection-1?tab=items')
    }

    const gotoHype = () => {
        window.open('https://avax.hyperspace.xyz/collection/avax/007f119d-e48c-4390-9331-0c2bde2468c1')
    }

    function gotoGate() {
        window.open('https://www.gate.io/vi/trade/GEC_USDT')
    }

    function gotoBit() {
        window.open('https://www.bitget.online/spot/GECUSDT')
    }

    function gotoMexc() {
        window.open('https://www.mexc.com/exchange/GECKOAVAX_USDT')
    }

    function gotoCoinex() {
        window.open('https://www.coinex.com/en/exchange/gec-usdt')
    }

    return (
        <div style={{ overflowX: 'hidden' }}>
            <Modal className="modal-banner" title="" open={isModalOpen === 1} footer={false} onOk={handleOk} onCancel={handleCancel}>
                <div className="banner-visit shake" onClick={gotoBanner}>
                    <img src="./images/poog.jpeg" alt="" />
                </div>
            </Modal>

            <div className="top">
                <div className="top-left">
                    <div className="title-top">
                        <img src={t('content.t-26', { framework: 'React' })} alt="" />
                        <img className='avax-img' src="./images/avax.png" alt="" />
                    </div>
                    <div className="dec">
                        {t('content.t-3', { framework: 'React' })} $GEC Inu
                    </div>
                    <div className="action-top">
                        <button className='trader' onClick={gotoSwap}>
                            <span>
                                {t('content.t-1', { framework: 'React' })}

                            </span> <img src="./images/joe.png" alt="" />
                        </button>
                        <button className='dex' onClick={gotoChart}><span>
                            {t('content.t-2', { framework: 'React' })}
                        </span> <img src="./images/dex.png" alt="" />
                        </button>
                        <a className='dex' rel="noreferrer" target="_blank" href="https://www.dextools.io/app/en/avalanche/pair-explorer/0x2ade4f6d462d60523235650160844bb6b46ac7d9">
                            {t('content.t-221', { framework: 'React' })}
                            <img src="./images/dextool.png" alt="" />
                        </a>

                    </div>

                    <div className="action-top second">
                        <button className='trader-mexc' onClick={gotoGate}>
                            <span>
                                GATE
                            </span>
                            <img className="home-trade" src="./images/gate.png" alt="" />
                        </button>
                        <button className='trader-mexc' onClick={gotoBit}><span>
                            BITGET
                        </span> <img className="home-trade" src="./images/bitget.png" alt="" />
                        </button>
                        <button className='trader-mexc' onClick={gotoMexc}>
                            <span>
                                MEXC
                            </span>
                            <img className="home-trade" src="./images/mexc.png" alt="" />
                        </button>
                        <button className='trader-mexc' onClick={gotoCoinex}>
                            <span>
                                COINEX
                            </span>
                            <img className="home-trade" src="./images/coinex1.png" alt="" />
                        </button>
                    </div>

                    <div className="dec1">
                        Explore Gecko Inu NFTs
                    </div>

                    <div className="list-img">
                        <img onClick={gotoJoe} src="./images/joe.svg" alt="" />
                        <img onClick={gotoOpen} src="./images/opensea.svg" alt="" />
                        <img onClick={gotoHype} src="./images/hyper1.svg" alt="" />


                    </div>
                    <div className="social">
                        <div className="tele" onClick={gotoTele}>
                            <img src="./images/tele.png" alt="" />
                        </div>
                        <div className="tw" onClick={gotoTw}>
                            <img src="./images/tw.png" alt="" />
                        </div>
                    </div>

                </div>
                <div className="top-right">
                    <img src="images/banner-top.png" alt="" />
                </div>

            </div>

            <div className="mid-text">
                <div className="mid-text1">
                    {t('content.t-4', { framework: 'React' })}
                </div>
                <div className="mid-text2">{t('content.t-5', { framework: 'React' })}</div>
                {/* <div className="mid-text3">{t('content.t-6', { framework: 'React' })}</div> */}
                <div className="mid-text3">{t('content.t-601', { framework: 'React' })}</div>
                <div className="mid-text3">{t('content.t-602', { framework: 'React' })}</div>
            </div>

            <div className="twitter">
                <div className="mo-twitter">
                    <div className="top-tw">
                        <div className="logo-tw-top">
                            <img src="./images/FDN.jpeg" alt="" />
                        </div>
                        <div className="all-tw">
                            <div className="right-tw-top">
                                <div className="tw-name">
                                    <div className="tw-ava">AvalancheFDN</div>

                                </div>
                                <div className="avax">@AvalancheFDN</div>
                            </div>

                        </div>
                    </div>
                    <div className="text-tw">
                        {isMobile ? (
                            <>
                                <div className="last-year">
                                    {t('content.t-38', { framework: 'React' })}
                                    {showMore ? '' : (<span className="readmore" onClick={handleReadmore}>Show more</span>)}
                                </div>
                                {showMore ? (
                                    <>

                                        <div className="last-year">
                                            {t('content.t-39', { framework: 'React' })}
                                        </div>
                                        <div className="list-tw">
                                            $COQ, $KIMBO, $TECH, $GEC and $NOCHILL
                                        </div>

                                        <div className="last-year">

                                            {!showMore ? '' : (<span className="readmore" onClick={handleReadmore}>Show less</span>)}
                                        </div>

                                    </>
                                ) : ('')}
                            </>
                        ) : (
                            <>

                                <div className="last-year">
                                    {t('content.t-38', { framework: 'React' })}                                </div>
                                <div className="last-year">
                                    {t('content.t-39', { framework: 'React' })}                                </div>
                                <div className="list-tw">
                                    $COQ, $KIMBO, $TECH, $GEC and $NOCHILL
                                </div>
                            </>
                        )}

                    </div>
                    <div className="images-tw">
                        <img src="./images/tw-newimg.svg" alt="" />
                    </div>

                    <div className="tw1">
                        {t('content.t-53', { framework: 'React' })}    <span>569.4K</span>  {t('content.t-54', { framework: 'React' })}

                    </div>
                    <div className="line-tw"></div>
                    <div className="tw2">
                        <div className="detail-tw2">
                            <span>623</span> {t('content.t-55', { framework: 'React' })}
                        </div>
                        <div className="detail-tw2">
                            <span>270</span> {t('content.t-56', { framework: 'React' })}
                        </div>
                        <div className="detail-tw2">
                            <span>1,503</span> {t('content.t-57', { framework: 'React' })}
                        </div>
                        <div className="detail-tw2">
                            <span>146</span> {t('content.t-58', { framework: 'React' })}
                        </div>
                    </div>

                    {!isMobile ? (
                        <>
                            <div className="line-tw"></div>
                            <div className="tw-bot">
                                <img src="./images/tw-bot.svg" alt="" />
                            </div>
                            <div className="line-tw1"></div>
                        </>
                    ) : ('')}

                    {isMobile ? (
                        <>

                            <div className="tw3">
                                <div className="icons-tw">
                                    <img src="./images/tym.png" alt="" />
                                    <span>1,4K</span>
                                </div>
                                <div className="icons-tw">
                                    <img src="./images/rep.png" alt="" />
                                    <span>Reply</span>
                                </div>
                                <div className="icons-tw">
                                    <img src="./images/link.png" alt="" />
                                    <span>Copy link</span>
                                </div>
                            </div>
                            <button className="read-tw">Read 442 replies</button>
                        </>

                    ) : ('')}

                </div>
            </div>

            <div className="ecosystem">
                <img src="./images/eco.svg" alt="" />
            </div>

            <div className="how-buy">
                <div className="title-buy">
                    <img src={t('content.t-59', { framework: 'React' })} alt="" />
                </div>
                <div className="card-item">
                    <div className="logo-card">
                        <img src="./images/icon-wl.png" alt="" />
                    </div>
                    <div className="content-card">
                        <div className="title-card">
                            <img src={t('content.t-60', { framework: 'React' })} alt="" />
                        </div>
                        <div className="dec-card">
                            {t('content.t-43', { framework: 'React' })}
                        </div>
                    </div>
                </div>
                <div className="card-item">
                    <div className="logo-card">
                        <img style={{ width: '78px', height: '95px' }} src="./images/get-icon.png" alt="" />

                    </div>
                    <div className="content-card">
                        <div className="title-card">
                            <img src={t('content.t-61', { framework: 'React' })} alt="" />
                        </div>
                        <div className="dec-card">
                            {t('content.t-44', { framework: 'React' })}
                        </div>
                    </div>
                </div>
                <div className="card-item">
                    <div className="logo-card">
                        <img style={{ width: '78px', height: '95px' }} src="./images/item-trade.png" alt="" />
                    </div>
                    <div className="content-card">
                        <div className="title-card">
                            <img src={t('content.t-62', { framework: 'React' })} alt="" />
                        </div>
                        <div className="dec-card">
                            {t('content.t-45', { framework: 'React' })}
                        </div>
                    </div>
                </div>
                <div className="card-item">
                    <div className="logo-card">
                        <img src="./images/switch.png" alt="" />
                    </div>
                    <div className="content-card">
                        <div className="title-card">
                            <img src={t('content.t-63', { framework: 'React' })} alt="" />
                        </div>
                        <div className="dec-card">
                            {t('content.t-46', { framework: 'React' })}
                        </div>
                    </div>
                </div>
                <img className="buy-r" src="./images/buy-r.png" alt="" />
                <img className="buy-l" src="./images/buy-ll.png" alt="" />
            </div>

            <div className="tokennomic">
                <div className="token-title">
                    <img src={t('content.t-27', { framework: 'React' })} alt="" />
                </div>
                <div className="tokenomics-content">
                    <div className="token-left">
                        <div className="token-sup">{t('content.t-47', { framework: 'React' })}</div>
                        <div className="num-sup">69,420,000,000,000</div>
                        <div className="no-token">
                            {t('content.t-48', { framework: 'React' })}<br />
                            {t('content.t-49', { framework: 'React' })}
                        </div>
                        <div className="contract">
                            {isMobile ? ('0xE8385CE...34881f3') : ('0xE8385CECb013561b69bEb63FF59f4d10734881f3')}
                            <img onClick={copiedHandle} className="icon-cop" src="./images/icon-cop.png" alt="" />
                        </div>
                        <br />
                        <div className="text-allo">{t('content.t-7', { framework: 'React' })} (43114)</div>
                        <div className="text-presale">{t('content.t-8', { framework: 'React' })}: $GEC</div>
                        <div className="text-taxes">{t('content.t-9', { framework: 'React' })}: 18</div>

                    </div>
                    <div className="token-right">
                        <img className="chart-token" src="./images/tokenomics.svg" alt="" />
                        <div className="mid-token">
                            <div className="liq">
                                <div className="icon-liq">
                                    <img src="./images/liq.png" alt="" />
                                </div>
                                <div className="text-liq">95% {t('content.t-50', { framework: 'React' })}</div>
                            </div>
                            <div className="liq">
                                <div className="icon-liq">
                                    <img src="./images/air.png" alt="" />
                                </div>
                                <div className="text-liq">5% {t('content.t-51', { framework: 'React' })}</div>
                            </div>
                        </div>
                        <div className="burn">
                            <img src="./images/burn.png" alt="" />
                            <div className="txt-burn" onClick={gotoBurn}>
                                {t('content.t-52', { framework: 'React' })}: <span>4.653%</span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/* <div className="disclaimer">
                <div className="content-dis">
                    <div className="dis-text1">
                        <img src={t('content.t-28', { framework: 'React' })} alt="" />
                    </div>
                    <div className="dis-text2">{t('content.t-11', { framework: 'React' })}</div>
                </div>
            </div> */}






        </div>
    )
}

export default Home; 
