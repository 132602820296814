import React, { useState, useEffect } from 'react';
import { Widget, WidgetConfig } from "@rango-dev/widget-embedded";

import './style.css'


const SwapPage = () => {

    const config: WidgetConfig = {
        apiKey: '945f6ea8-10a2-41a1-a0fa-43a1000e415e',
        from: {
            blockchain: 'AVAX_CCHAIN',
            token: {
                blockchain: 'AVAX_CCHAIN',
                address: null,
                symbol: 'AVAX'
            }
        },
        to: {
            blockchain: 'AVAX_CCHAIN',
            token: {
                blockchain: 'AVAX_CCHAIN',
                address: '0xe8385cecb013561b69beb63ff59f4d10734881f3',
                symbol: 'GEC'
            }
        },
        theme: {
            mode: "light",
            borderRadius: 23,
            singleTheme: true,
            colors: {
                light: {
                    primary: '#23252d'
                }
            }
        },
        title: 'Gecko Swap',
        affiliate: {
            ref: 'AP2PTQ',
            percent: 0.1
        },
        enableNewLiquiditySources: false,
        walletConnectProjectId: 'e24844c5deb5193c1c14840a7af6a40b'
    }

    return (
        <div className="main-swap">
            <div className="container-swap">
                <div className="top-swap">
                    <div className="title-top-swap">
                        Swap galore with Gecko Inu! 🦎
                    </div>
                    <div className="dec-top-swap">
                        Dive into +10,000 tokens across +60 chains, grabbing top quotes from +80 sources.
                        Easy, fun, and limitless!
                    </div>
                </div>
                <div className="body-swap">

                    <Widget
                        config={config}
                    />

                </div>
            </div>
        </div>
    )
}

export default SwapPage;