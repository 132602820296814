/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Progress, Tabs, Skeleton, Modal, Tooltip } from "antd";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "antd/dist/antd.min.css";
import ConnectWallet from "../../../components/connect-wallet/ConnectWallet";
import abiMintNFT from "../../../constants/abi/abiMintNFT.json";
import { getInforContractMint } from "../../../hooks/getInforContractMint";
import {
    MINT_NFT_CONTRACT,
    TOTAL_NFT_MINT,
    API_URL,
} from "../../../constants/index";
import { notiError, notiSuccess } from "./../../../utils/notication";
import { convertFromWei, convertToWei } from "../../../utils/convertNumber";
import Loading from "./../../../components/Loading";
import { useActiveWeb3React } from "../../../hooks";
import Web3 from "web3";
import { useContract } from "../../../hooks/useContract";
import { AbiItem } from "web3-utils";
import "./style.css";
import Header from "../../../components/common/header";
import { _mintToken } from "./../../../utils/utilsNFT";
import switchNetworkChain from "../../../utils/walletChain";
import { postData, getData } from "../../../axios/marketNFT";
import Decimal from "decimal.js";
import moment from "moment";
import Countdown from "react-countdown";
import { rendererCountDown, formatAddress } from "./../../../utils/formats";
import { useNavigate } from "react-router-dom";
import { useHook } from "../../../components/connect-wallet/store";
import { useWeb3ModalState } from '@web3modal/wagmi/react'
import {
    useAccount,
} from "wagmi";
import WagmiConnect from "components/wagmi-connect";

declare const window: Window &
    typeof globalThis & {
        trustwallet: any;
        ethereum: any;
        bitkeep: any;
        okexchain: any;
        safepalProvider: any;
    };

const MintNFT = () => {
    const { selectedNetworkId } = useWeb3ModalState()
    const { address } = useAccount();
    let web3: any;
    let wallet: any = address || ""
    let chain: any = selectedNetworkId || ""
    const connectorId = window.localStorage.getItem("wagmi.recentConnectorId");
    console.log('connectorId', connectorId)
    if (connectorId === "io.metamask" || connectorId === "bsc") {
        web3 = new Web3(window.ethereum);
    } else if (connectorId === "com.trustwallet.app") {
        web3 = new Web3(window.trustwallet);
    } else if (connectorId === "com.bitget.web3") {
        web3 = new Web3(window.bitkeep.ethereum);
    } else if (connectorId === "com.okex.wallet") {
        web3 = new Web3(window.okexchain);
    } else {
        web3 = new Web3(window.ethereum);
    }

    // console.log("window", window);
    // console.log('web3',web3)
    // const web3 = new Web3(window.ethereum);
    const currentTime = Math.floor(Date.now() / 1000);
    const { account, chainId }: any = useActiveWeb3React();
    const [amountMint, setAmountMint]: any = useState(1);
    const [dataMint, setDataMint] = useState<any>();
    const [loadingMint, setLoadingMint] = useState<any>(false);
    const [listMint, setListMint] = useState<any>([]);
    const [schedules, setSchedules] = useState<any>();
    const [listSuggest, setListSuggest] = useState<any>();
    const [mFee, setMFee] = useState<any>("0");
    const [mAmount, setMAmount] = useState<any>("0");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [dataSign, setSign] = useState<any>();
    const [state, actions]: any = useHook();

    const [isWhileList, setIsWhileList] = useState<any>(false);

    // if (connectorId === "injected" || connectorId === "bsc") {
    //     wallet = account;
    //     chain = chainId;
    // } else if (connectorId === "trustWallet") {
    //     wallet = state.acc;
    //     chain = state.net;
    // } else if (connectorId === "bit") {
    //     wallet = state.acc;
    //     chain = state.net;
    // } else if (connectorId === "okx") {
    //     wallet = state.acc;
    //     chain = state.net;
    // } else if (connectorId === "safe") {
    //     wallet = state.acc;
    //     chain = state.net;
    // } else {
    //     wallet = account;
    //     chain = chainId;
    // }



    // console.log("connectorId", connectorId);
    // console.log("wallet", wallet);
    // console.log("chain", chain);

    let navigate = useNavigate();

    let mintStartTime = moment(schedules?.mintStartTime * 1000).format(
        "MMM DD YYYY h:mm A"
    );
    let holderFromTime = moment(schedules?.holder?.fromTime * 1000).format(
        "MMM DD YYYY h:mm A"
    );
    let holderToTime = moment(schedules?.holder?.toTime * 1000).format(
        "MMM DD YYYY h:mm A"
    );

    let wlFromTime = moment(schedules?.whitelist?.fromTime * 1000).format(
        "MMM DD YYYY h:mm A"
    );
    let wlToTime = moment(schedules?.whitelist?.toTime * 1000).format(
        "MMM DD YYYY h:mm A"
    );
    let fcfsFromTime = moment(schedules?.fcfs?.fromTime * 1000).format(
        "MMM DD YYYY h:mm A"
    );
    let fcfsToTime = moment(schedules?.fcfs?.toTime * 1000).format(
        "MMM DD YYYY h:mm A"
    );

    const settings = {
        dots: false,
        infinite: true,
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: (
            <img className="next-modal1" src="./images/nft/right.png" alt="" />
        ),
        prevArrow: (
            <img className="prev-modal1" src="./images/nft/left.png" alt="" />
        ),
    };
    const gotoDetail = async (id: any) => {
        navigate(`${"/detailNFT"}/${id}`);
    };
    const goHome = async () => {
        navigate(`${"/"}`);
    };
    const goContract = async () => {
        window.open(
            `https://snowtrace.io/token/${MINT_NFT_CONTRACT}?chainId=43114`
        );
    };
    const mint = new web3.eth.Contract(
        abiMintNFT as unknown as AbiItem,
        MINT_NFT_CONTRACT
    );

    const workChain = 43114;
    const getContractMint = async () => {
        const objMint = await getInforContractMint(web3);
        setDataMint(objMint);
    };
    useEffect(() => {
        if (!dataMint) {
            getContractMint();
        }
    }, []);

    const getSign = async () => {
        const data: any = {
            ownerAddress: wallet,
        };
        await postData(`${API_URL}/nft/gen_sign`, data).then((data) => {
            if (data?.data) {
                setSign(data?.data);
            }
        });
    };


    useEffect(() => {
        if (wallet) {
            getSign();
        }

    }, [wallet]);

    useEffect(() => {
        if (dataSign && dataSign.sign) {
            setIsWhileList(true)
        } else {
            setIsWhileList(false)
        }
    }, [dataSign, wallet])



    const handleChangeInput = (e: any) => {
        let x = e.target.value.replace(/[^0-9.]/g, "");
        setAmountMint(Number(x));
    };
    const handleNext = () => {
        if (amountMint <= 4) {
            setAmountMint(amountMint + 1);
        }
    };
    const handlePre = () => {
        if (amountMint !== 1 && amountMint !== "1") {
            setAmountMint(amountMint - 1);
        }
    };
    const onChange = (key: any) => {
        console.log(key);
    };

    const getSchedules = async () => {
        await getData(`${API_URL}/nft/schedule`).then((data) => {
            if (data?.data) {
                setSchedules(data?.data);
            }
        });
    };
    useEffect(() => {
        getSchedules();
    }, []);

    const getContract = async () => {
        if (wallet) {
            const amount = await mint.methods.walletToMinted(wallet).call(); // đã mint
            const fee = await mint.methods.walletToFreeMinted(wallet).call(); // đã mint free

            setMAmount(amount);
            setMFee(fee);
        }
    };
    useEffect(() => {
        getContract();
    }, []);



    const handleMintNFT = async () => {
        try {
            setLoadingMint(true);
            const mintFee: any = new Decimal(dataMint?.mintFee);
            const amount: any = new Decimal(amountMint);
            const totalFee = mintFee.mul(amount).toNumber();
            const gasPrice = await web3.eth.getGasPrice();
            let body: any = {
                ownerAddress: wallet,
                quantity: amountMint,
            };
            let data: any = {
                ownerAddress: wallet,
            };


            const item = await postData(`${API_URL}/nft/get_multi_tokenid`, body);
            const sign = await postData(`${API_URL}/nft/gen_sign`, data);

            const feeMint =
                Number(mFee) < Number(sign?.data?.tier)
                    ? 0
                    : convertToWei(totalFee.toString(), 18);
            const gasEstimate = await mint.methods
                .safeMintMany(
                    wallet,
                    sign?.data?.tier, //tier
                    amountMint, // amount mint
                    item?.data?.tokenIds, // array tokenId
                    sign?.data?.sign
                )
                .estimateGas({
                    from: wallet,
                    value: feeMint,
                    gasPrice: web3.utils.toHex(String(gasPrice)),
                });

            await mint.methods
                .safeMintMany(
                    wallet,
                    sign?.data?.tier, //tier
                    amountMint, // amount mint
                    item?.data?.tokenIds, // array tokenId
                    sign?.data?.sign
                )
                .send({
                    from: wallet,
                    gas: gasEstimate,
                    value: feeMint,
                    gasPrice: web3.utils.toHex(String(gasPrice)),
                })
                .on("transactionHash", (tx: any) => {
                    if (tx) {
                        console.log("tx", tx);
                        let resData: any = {
                            queryKey: item?.data?.queryKey,
                            ownerAddress: wallet,
                            txHash: tx,
                            type: 1,
                        };
                        postData(`${API_URL}/nft/create_hash`, resData);
                    }
                })
                .then((resSuccess: any) => {
                    console.log("----------resSuccess--------");
                    console.log("resSuccess: ", resSuccess);
                    if (resSuccess?.status) {
                        let dataMint: any = {
                            ownerAddress: wallet,
                            queryKey: item?.data?.queryKey,
                            txHash: resSuccess?.transactionHash,
                        };
                        postData(`${API_URL}/nft/mint_nft`, dataMint).then((resUpdate) => {
                            console.log("---------resUpdate---------");
                            console.log("resUpdate?.data", resUpdate?.data);
                            if (resUpdate?.succeeded) {
                                setLoadingMint(false);
                                setListMint(resUpdate?.data);
                                setIsModalOpen(true);
                                getSign();
                            }
                        });
                        notiSuccess("Mint successfully", 5);
                        setLoadingMint(false);
                        getContractMint();
                    }
                })
                .catch((err: any) => {
                    console.error("err Mint+++++:", err);
                    notiError(err?.message, 5);
                    setLoadingMint(false);
                });
        } catch (error: any) {
            console.log("error Mint-----", error?.message);
            notiError(error?.message, 5);
            setLoadingMint(false);
        }
    };

    const Team = () => {
        return (
            <div className="team-content">
                <div className="title-team">
                    <img src="./images/nft/team-title.svg" alt="" />
                </div>
                <div className="list-team">
                    <div className="item-team">
                        <img src="./images/nft/founder.png" alt="" />
                        <div className="name-team">GECKOINU OG</div>
                        <div className="dec-team">Founder</div>
                        <div className="tw-team">
                            <a
                                href="https://twitter.com/OGGec"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src="./images/nft/team-x.png" alt="" />
                            </a>
                        </div>
                    </div>
                    {/* <div className="item-team">
            <img src="./images/nft/bd.png" alt="" />
            <div className="name-team">NAME</div>
            <div className="dec-team">BD</div>
            <div className="tw-team">
              <a href="https://twitter.com" target="_blank" rel="noreferrer">
                <img src="./images/nft/team-x.png" alt="" />
              </a>
            </div>
          </div> */}
                    <div className="item-team">
                        <img src="./images/nft/cpo.png" alt="" />
                        <div className="name-team">WILL</div>
                        <div className="dec-team">CPO</div>
                        <div className="tw-team">
                            <a
                                href="https://twitter.com/GECITWILL"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src="./images/nft/team-x.png" alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="item-team">
                        <img src="./images/nft/cl.png" alt="" />
                        <div className="name-team">Benjen</div>
                        <div className="dec-team">Community Leader</div>
                        <div className="tw-team">
                            <a
                                href="https://twitter.com/BenjenGec"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src="./images/nft/team-x.png" alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="item-team">
                        <img src="./images/nft/pm.png" alt="" />
                        <div className="name-team">Alex Greenfield</div>
                        <div className="dec-team">Product Mind</div>
                        <div className="tw-team">
                            <a
                                href="https://twitter.com/alexggck"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src="./images/nft/team-x.png" alt="" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    const Overview = () => {
        return (
            <div className="overview-content">
                <div className="top-overview">
                    <img src="./images/nft/over.png" alt="" />
                    <div className="name-overview">Gecko Inu NFT Collection</div>
                    <img className="icon-view" src="./images/nft/icon-view.png" alt="" />
                </div>
                <div className="btn-view">
                    <div className="detail-view" onClick={goHome}>
                        <img src="./images/nft/link.png" alt="" />
                        <span>Project Details</span>
                    </div>
                    <div
                        className="detail-view link"
                        onClick={goContract}
                        style={{ cursor: "pointer" }}
                    >
                        <img src="./images/nft/link.png" alt="" />
                        <span>Contract: {formatAddress(MINT_NFT_CONTRACT, 3, 3)} </span>
                    </div>
                </div>
                <div className="dec-view">
                    <span>
                        We are the most friendly lizard on AVAX—Gecko Inu! Founded by
                        well-respected members with a high AVAX culture. GEC is a
                        community-driven project and will be only for the community. Get
                        ready for the exclusive release of 10,000 UNIQUE GECKO CHARACTERS,
                        each brimming with personality and style. This collection is a
                        tribute to our vibrant community on AVAX, embodying the spirit of
                        innovation and unity. Holders can expect an engaging,
                        subscription-based rewards system packed with unprecedented features
                        that truly capture the essence of Gecko Inu. Dive into the world of
                        Gecko Inu NFTs and experience the diversity of our digital
                        ecosystem!
                    </span>
                    <br />
                    <br />
                    <span className="view-uti">Utility</span>
                    <ul>
                        <li>Future rewards from our rewards campaigns</li>
                        <li>Early access to future Gecko Inu storyline comics</li>
                        <li>Elite Gecko Gang access</li>
                        <li>DAO NFT Voting</li>
                        <li>Early access to AI GECKO BOT</li>
                        <li>Access to Gecverse Game</li>
                        <li>Whitelist Allocation for Gecko Launchpad</li>
                    </ul>

                    <span className="view-uti">Partnerships:</span>
                    <ul>
                        <li>Avalabs</li>
                        <li>Hyperspace</li>
                        <li>Bitget Wallet</li>
                        <li>OKX Wallet</li>
                        <li>Trader Joe</li>
                        <li>Dokyo</li>
                        <li>Dokkaebi</li>
                        <li>MadSkullz</li>
                        <li>OOPA</li>
                        <li>Husky</li>
                        <li>Zealy</li>
                    </ul>
                </div>
            </div>
        );
    };

    const items = [
        {
            key: "1",
            label: "Overview",
            children: <Overview />,
        },
        {
            key: "2",
            label: "Team",
            children: <Team />,
        },
    ];

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const textTooltip = (
        <div>
            <div className="text-tooltip">
                <span className="text">
                    Whitelist for GEC holders and Gecko Alliances Communities.
                </span>
            </div>
        </div>
    );


    return (
        <div style={{ overflowX: "hidden" }}>

            <div className="container">
                <div className="mint-top">
                    <div className="mint-left">
                        <div className="title-mint">
                            <img src="./images/nft/title-min.svg" alt="" />
                        </div>
                        <div className="img-big-min">
                            <img
                                src="./images/nft/mint.gif"
                                alt=""
                                style={{ borderRadius: "20px" }}
                            />
                        </div>
                        <div className="img-small-min">
                            <img
                                className="image-small"
                                src="./images/nft/small1.png"
                                alt=""
                            />
                            <img
                                className="image-small"
                                src="./images/nft/small2.png"
                                alt=""
                            />
                            <img
                                className="image-small"
                                src="./images/nft/small3.png"
                                alt=""
                            />
                            <img
                                className="image-small"
                                src="./images/nft/small4.png"
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="mint-right">
                        <div className="content-mint-right">
                            <div className="title-min-right">Mint Stages</div>

                            <div className="whitelist">
                                <div className="box-title">
                                    <div className="title-wl">Holder</div>
                                    {currentTime < schedules?.holder?.fromTime ? (
                                        <div className="count-down-wl">
                                            <span>Start in: </span>{" "}
                                            {schedules?.holder?.fromTime > 0 ? (
                                                <Countdown
                                                    date={holderFromTime}
                                                    renderer={rendererCountDown}
                                                />
                                            ) : (
                                                "TBA"
                                            )}
                                        </div>
                                    ) : (
                                        <div className="count-down-wl">
                                            <span>End in: </span>{" "}
                                            {schedules?.holder?.toTime > 0 ? (
                                                <Countdown
                                                    date={holderToTime}
                                                    renderer={rendererCountDown}
                                                />
                                            ) : (
                                                "TBA"
                                            )}
                                        </div>
                                    )}
                                </div>
                                <div className="max-wl">
                                    MAX {schedules?.holder?.limitPerAddress} NFTs Price: FREE
                                    {/* <Tooltip
                    overlayClassName="tooltip-custom"
                    title={textTooltip}
                  >
                    <img src="./images/nft/noti-wl.png" alt="" />
                  </Tooltip> */}
                                </div>
                            </div>

                            <div className="whitelist">
                                <div className="box-title">
                                    <div className="title-wl">Whitelist</div>
                                    {currentTime < schedules?.whitelist?.fromTime ? (
                                        <div className="count-down-wl">
                                            <span>Start in: </span>{" "}
                                            {schedules?.whitelist?.fromTime > 0 ? (
                                                <Countdown
                                                    date={wlFromTime}
                                                    renderer={rendererCountDown}
                                                />
                                            ) : (
                                                "TBA"
                                            )}
                                        </div>
                                    ) : (
                                        <div className="count-down-wl">
                                            <span>End in: </span>{" "}
                                            {schedules?.whitelist?.toTime > 0 ? (
                                                <Countdown
                                                    date={wlToTime}
                                                    renderer={rendererCountDown}
                                                />
                                            ) : (
                                                "TBA"
                                            )}
                                        </div>
                                    )}
                                </div>
                                <div className="max-wl">
                                    MAX {schedules?.whitelist?.limitPerAddress} NFTs Price: FREE
                                    <Tooltip
                                        overlayClassName="tooltip-custom"
                                        title={textTooltip}
                                    >
                                        <img src="./images/nft/noti-wl.png" alt="" />
                                    </Tooltip>
                                </div>
                            </div>

                            <div className="whitelist">
                                <div className="box-title">
                                    <div className="title-public">Public</div>
                                    {currentTime < schedules?.fcfs?.fromTime ? (
                                        <div className="count-down-wl">
                                            <span>Start in: </span>
                                            {schedules?.fcfs?.fromTime > 0 ? (
                                                <Countdown
                                                    date={fcfsFromTime}
                                                    renderer={rendererCountDown}
                                                />
                                            ) : (
                                                "TBA"
                                            )}
                                        </div>
                                    ) : (
                                        <div className="count-down-wl">
                                            <span>End in: </span>
                                            {schedules?.fcfs?.toTime > 0 ? (
                                                <Countdown
                                                    date={fcfsToTime}
                                                    renderer={rendererCountDown}
                                                />
                                            ) : (
                                                "TBA"
                                            )}
                                        </div>
                                    )}
                                </div>
                                <div className="max-wl">
                                    MAX {schedules?.fcfs?.limitPerAddress} NFTs Price:{" "}
                                    {schedules?.fcfs?.price} AVAX

                                </div>
                            </div>

                            <div className="count-down-start">
                                <span>Start in: </span>
                                {schedules?.mintStartTime > 0 ? (
                                    <Countdown
                                        date={mintStartTime}
                                        renderer={rendererCountDown}
                                    />
                                ) : (
                                    "TBA"
                                )}
                            </div>
                            <div className="txt-progress">
                                <div className="total-minted">Total Minted</div>
                                <div className="num-total">
                                    {Number(
                                        (
                                            (dataMint?.totalSupply / TOTAL_NFT_MINT) * 100 || 0
                                        ).toFixed(2)
                                    )}
                                    %
                                </div>
                            </div>
                            <div className="progress">
                                <Progress
                                    percent={Number(
                                        (
                                            (dataMint?.totalSupply / TOTAL_NFT_MINT) * 100 || 0
                                        ).toFixed(2)
                                    )}
                                    status="active"
                                />
                            </div>
                            <div className="number-progress">
                                {Number(dataMint?.totalSupply || "0")}/
                                {Number(TOTAL_NFT_MINT || "0")} NFT
                            </div>
                            <div className="price-mint" style={{ display: 'none' }}>
                                <span>Price</span>
                                <div className="num-price" style={{ paddingBottom: "10px" }}>
                                    {dataMint && dataSign ? (
                                        <>
                                            <img src="./images/nft/icon-avax.png" alt="" />
                                            <div className="token-price">
                                                {/* {dataMint?.mintFee}  */}
                                                {schedules?.price} Avax
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <Skeleton.Avatar active={true} />
                                            <Skeleton.Input active={true} />
                                        </>
                                    )}
                                </div>
                                {/* <div className="usdt-price">$560.00</div> */}
                            </div>

                            <div className="action-mint" style={{ display: 'none' }}>
                                <div className="input-mint">
                                    <input
                                        onChange={(e) => handleChangeInput(e)}
                                        value={amountMint}
                                        className="input-mint"
                                        type="text"
                                    />

                                    <div
                                        className={`next ${amountMint <= 1 ? "dis" : ""}`}
                                        onClick={handlePre}
                                    >
                                        <img src="./images/nft/pre.png" alt="" />
                                    </div>
                                    <div
                                        className={`pre ${amountMint >= 10 ? "dis" : ""}`}
                                        onClick={handleNext}
                                    >
                                        <img src="./images/nft/add.png" alt="" />
                                    </div>
                                </div>
                                {/* mint whitelist */}
                                {schedules?.whitelist?.status && dataSign?.sign && (
                                    <div className="btn-mint">
                                        {wallet && chain !== workChain ? (
                                            <button className="btn-connectnew" disabled>
                                                Switch Chain AVAX
                                            </button>
                                        ) : (
                                            <button
                                                className="btn-connectnew"
                                                onClick={handleMintNFT}
                                                disabled={
                                                    !wallet ||
                                                    chain !== workChain ||
                                                    loadingMint ||
                                                    !dataMint ||
                                                    !dataSign?.sign ||
                                                    Number(dataMint?.totalSupply) ===
                                                    Number(TOTAL_NFT_MINT) ||
                                                    dataSign?.tier === 0 ||
                                                    schedules?.whitelist?.limitPerAddress <=
                                                    dataSign?.totalFreeMint ||
                                                    Number(amountMint) > dataSign?.tier
                                                }
                                            // disabled
                                            >
                                                <Loading
                                                    status={loadingMint}
                                                    content={
                                                        Number(amountMint) > dataSign?.tier
                                                            ? "Max Mint"
                                                            : "Mint Free"
                                                    }
                                                />
                                            </button>
                                        )}
                                    </div>
                                )}
                                {/* mint fcfs */}
                                {schedules?.fcfs?.status && dataSign?.sign && (
                                    <div className="btn-mint">
                                        {wallet && chain !== workChain ? (
                                            <button className="btn-connectnew" disabled>
                                                Switch Chain AVAX
                                            </button>
                                        ) : (
                                            <button
                                                className="btn-connectnew"
                                                onClick={handleMintNFT}
                                                disabled={
                                                    !wallet ||
                                                    chain !== workChain ||
                                                    loadingMint ||
                                                    !dataMint ||
                                                    !dataSign?.sign ||
                                                    Number(dataMint?.totalSupply) ===
                                                    Number(TOTAL_NFT_MINT) ||
                                                    schedules?.fcfs?.limitPerAddress <=
                                                    dataSign?.totalMint ||
                                                    schedules?.fcfs?.limitPerAddress < Number(amountMint)
                                                }
                                            // disabled
                                            >
                                                <Loading
                                                    status={loadingMint}
                                                    content={
                                                        schedules?.fcfs?.limitPerAddress <=
                                                            dataSign?.totalMint
                                                            ? "Max Mint"
                                                            : "Mint"
                                                    }
                                                />
                                            </button>
                                        )}
                                    </div>
                                )}
                                {/* mint holder */}
                                {schedules?.holder?.status && dataSign?.sign && (
                                    <div className="btn-mint">
                                        {wallet && chain !== workChain ? (
                                            <button className="btn-connectnew" disabled>
                                                Switch Chain AVAX
                                            </button>
                                        ) : (
                                            <button
                                                className="btn-connectnew"
                                                onClick={handleMintNFT}
                                                disabled={
                                                    !wallet ||
                                                    chain !== workChain ||
                                                    loadingMint ||
                                                    !dataMint ||
                                                    !dataSign?.sign ||
                                                    Number(dataMint?.totalSupply) ===
                                                    Number(TOTAL_NFT_MINT) ||
                                                    schedules?.holder?.limitPerAddress <=
                                                    dataSign?.totalMint ||
                                                    schedules?.holder?.limitPerAddress < Number(amountMint)
                                                }
                                            // disabled
                                            >
                                                <Loading
                                                    status={loadingMint}
                                                    content={
                                                        schedules?.holder?.limitPerAddress <=
                                                            dataSign?.totalMint
                                                            ? "Max Mint"
                                                            : "Mint"
                                                    }
                                                />
                                            </button>
                                        )}
                                    </div>
                                )}


                                {!schedules?.fcfs?.status && // stt =false > show
                                    !schedules?.whitelist?.status && // stt =false > show
                                    !schedules?.holder?.status &&
                                    schedules?.fcfs?.toTime === 0 && // time = 0 > show
                                    schedules?.holder?.toTime === 0 &&
                                    schedules?.whitelist?.toTime === 0 && ( // time = 0 > show
                                        <button className="btn-connectnew" disabled>
                                            Upcoming
                                        </button>
                                    )}

                                {!wallet ? (
                                    <div className="btn-mint">
                                        {/* <ConnectWallet /> */}
                                        <WagmiConnect />
                                    </div>
                                ) : (
                                    ''
                                )}

                                {wallet ? (
                                    <>
                                        {isWhileList === true ? (
                                            ''
                                        ) : (
                                            <>
                                                {dataSign?.isWhitelist ? (
                                                    <>
                                                        <button className="btn-connectnew" disabled>
                                                            Coming Soon
                                                        </button>
                                                        <div className="mess-sign">{dataSign?.message}</div>
                                                    </>


                                                ) : (
                                                    <button className="btn-connectnew" disabled>
                                                        You're not whitelisted
                                                    </button>
                                                )}
                                            </>

                                        )}
                                    </>
                                ) : (
                                    ''
                                )}



                            </div>
                        </div>
                    </div>
                </div>
                <div className="mint-bottom">
                    <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
                </div>
            </div>

            <Modal
                className="modal-detail-nft"
                title=""
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={false}
            >
                <div className="content-modal">
                    <div className="title-content-modal">
                        <img src="./images/nft/Congrats.svg" alt="" />
                    </div>

                    <Slider {...settings}>
                        {listMint?.map((item: any, index: any) => (
                            <div className="item-slide">
                                <div className="image-modal">
                                    <img
                                        className="img-modal2"
                                        style={{ borderRadius: "20px" }}
                                        src={item?.imageUrl}
                                        alt=""
                                    />
                                </div>

                                <div className="num1">
                                    {index + 1}/{listMint?.length}
                                </div>
                                <div className="name-modal">{item?.id}</div>
                                <div className="dec-modal">
                                    You just got for yourself a Gecko NFT
                                </div>
                                <div className="proper">
                                    <div className="prop-content">
                                        <div className="prop-name">Item Properties</div>
                                        <div className="mid-group1-list">
                                            {item?.attrs?.map((data: any, index: any) => {
                                                return (
                                                    <>
                                                        <div className="mid-group1-item">
                                                            <div className="group-item">
                                                                <div className="group1-item-name">
                                                                    {data?.unicorn}
                                                                </div>
                                                                <div className="group1-item-dec">
                                                                    {data?.name}
                                                                </div>
                                                                <div className="group1-item-num">
                                                                    <div className="item-num1">{data?.rate}%</div>
                                                                    {/* <div className="item-num2">
                                    {data?.floor}
                                    <img
                                      src="./images/nft/avax-icon.png"
                                      alt=""
                                    />
                                  </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className="button-list">
                                    <button onClick={() => gotoDetail(item?.queryKey)}>
                                        View your NFTs
                                    </button>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </Modal>
        </div>
    );
};

export default MintNFT;
