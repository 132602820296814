/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import Web3 from "web3";
import { isMobile } from 'react-device-detect';
import Countdown from 'react-countdown';
import { LoadingOutlined } from "@ant-design/icons";
import { Spin, Skeleton } from 'antd';
import { AbiItem } from "web3-utils";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useWeb3ModalState } from '@web3modal/wagmi/react'
import {
    useAccount
} from "wagmi";
import { formatNumber, rendererCountDown } from '../../../utils/formats';
import { useLocation, useNavigate } from 'react-router-dom';
import { STATUS_IDO } from '../index.d'
import { notiError, notiSuccess } from '../../../utils/notication';
import "./style.css"
import abiClaimChange from '../abi/abiClaimChange.json'
import abiJoin from '../abi/abiJoin.json'
import abiClaim from '../abi/abiClaim.json'
import abiTokenDefault from '../abi/abiTokenDefault.json'
// import { useActiveWeb3React } from '../../../hooks';
// import { useHook } from 'components/connect-wallet/store';
import { useHookStore } from '../Store'
import { convertToWei, convertTokenToWei } from 'utils/convertNumber';
import WagmiConnect from 'components/wagmi-connect';



const UINT256_MAX =
    "115792089237316195423570985008687907853269984665640564039457";
const addressTokenMain = '0x0000000000000000000000000000000000000000'

declare const window: Window &
    typeof globalThis & {
        trustwallet: any;
        ethereum: any;
        bitkeep: any;
        okexchain: any;
        safepalProvider: any;
    };
const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;
const workChain = 43114;


const ItemEnded = (props: any) => {
    const [state1, actions1]: any = useHookStore();
    const [loadingClaim, setLoadingClaim]: any = useState(false)
    const [loadingClaimChange, setLoadingClaimChange]: any = useState(false)
    const [loadingRefund, setLoadingRefund]: any = useState(false)
    const [isJoined, setIsJoined]: any = useState(false)
    const [showBtnClaim, setShowBtnClaim]: any = useState(false)
    const [userRefund, setUserRefund]: any = useState(0)
    const [skeleton, setSkeleton]: any = useState(true)
    let web3 = props.web3
    let chain = props.chain
    let wallet = props.wallet
    let item = props.item
    let tokenJoinPool = props.tokenJoin
    let addressTokenJoin = props.addressTokenJoin
    let decimalTokenJoin = props.decimalTokenJoin
    let symbolTokenClaim = props.symbolTokenClaim
    const scClaim = item?.allocation?.claimbles?.claimContract
    const scJoin = item?.commitFundContract
    const scClaimChange = item?.allocation?.claimChange?.refundContract

    const claimContract = new web3.eth.Contract(
        abiClaim as unknown as AbiItem,
        scClaim
    );
    const joinContract = new web3.eth.Contract(
        abiJoin as unknown as AbiItem,
        scJoin
    );

    const claimChangeContract = new web3.eth.Contract(
        abiClaimChange as unknown as AbiItem,
        scClaimChange
    );


    // function totalRaise(percentProcess: any, totalRaise: any) {
    //     if (totalRaise && percentProcess) {
    //         let total = totalRaise / 100 * percentProcess
    //         return formatNumber(total, 1)
    //     } else {
    //         return 0
    //     }
    // }

    const checkRealRefund = async () => {
        try {
            await claimContract.methods.realRefunded(wallet)
                .call()
                .then((res: any) => {

                    setUserRefund(Number(res))
                })
                .catch((err: any) => {
                    console.log('err---', err)
                })
        } catch (error) {

        }
    }

    useEffect(() => {
        if (wallet) {
            checkRealRefund()
        }
    }, [wallet, item])

    const checkShowBtnClaim = async () => {
        try {
            let amountClaimTemp = convertToWei(item?.allocation?.claimbles?.claimToken, item?.allocation?.claimbles?.tokenClaimDecimal)
            await claimContract.methods.showClaimBtn(wallet, amountClaimTemp)
                .call()
                .then((res: any) => {
                    setShowBtnClaim(res)

                })
                .catch((err: any) => {
                    console.log('err---', err)
                })
        } catch (error) {

        }
    }

    useEffect(() => {
        if (wallet && item?.allocation?.claimbles) {
            checkShowBtnClaim()
        }
    }, [wallet, item])

    const handleClaim = async () => {
        try {
            setLoadingClaim(true)
            const gasPrice = await web3.eth.getGasPrice();
            const amountTemp = convertTokenToWei(item?.allocation?.claimbles?.claimToken, item?.allocation?.claimbles?.tokenClaimDecimal);
            const refundUsd = convertTokenToWei(item?.allocation?.claimbles?.claimUsd, item?.allocation?.claimbles?.tokenRefundDecimal);
            const sigClaim = item?.allocation?.claimbles?.signToken
            const args: any = [amountTemp, refundUsd, sigClaim];
            const gasEstimate = await claimContract.methods
                .claimTokens(...args)
                .estimateGas({
                    from: wallet,
                    gasPrice: web3.utils.toHex(String(gasPrice)),
                })
            // .then((res: any) => {
            //     console.log('res--', res)
            // })
            // .catch((err: any) => {
            //     console.log('err--', err)
            // })


            await claimContract.methods.claimTokens(...args)
                .send({
                    from: wallet,
                    gas: gasEstimate,
                    gasPrice: web3.utils.toHex(String(gasPrice)),
                })
                .then(async (resClaim: any) => {
                    console.log('resClaim', resClaim)
                    let data = {
                        ownerAddress: wallet,
                        roundId: item?.id,
                        txHash: resClaim?.transactionHash
                    }
                    await actions1.commited(data)
                    const interval = setInterval(function () {
                        web3.eth.getTransactionReceipt(
                            resClaim?.transactionHash,
                            function (err: any, rec: any) {
                                if (rec) {
                                    setLoadingClaim(false)
                                    setShowBtnClaim(false)
                                    notiSuccess('Claim successfully', 3)
                                    clearInterval(interval);
                                }
                                if (err) {
                                    console.log("err", err);
                                    setLoadingClaim(false)
                                    notiError('Error Staking', 3)
                                }
                            }
                        )
                    }, 1000);
                })
                .catch((errClaim: any) => {
                    console.log('errClaim', errClaim)
                    notiError(errClaim?.message || errClaim, 3)
                    setLoadingClaim(false)
                })
        } catch (error: any) {
            setLoadingClaim(false)
            notiError(error?.message || error, 3)
            console.log('error', error)
        }
    }
    const handleRefund = async () => {
        try {
            setLoadingRefund(true)
            const gasPrice = await web3.eth.getGasPrice();
            const refundAllToken = convertTokenToWei(item?.allocation?.claimbles?.refundAllUsd, item?.allocation?.claimbles?.tokenRefundDecimal);
            const amountTemp = convertTokenToWei(item?.allocation?.claimbles?.refundAllToken, item?.allocation?.claimbles?.tokenClaimDecimal);
            const sigRefund = item?.allocation?.claimbles?.signBusd
            const args: any = [refundAllToken, amountTemp, sigRefund];
            const gasEstimate = await claimContract.methods
                .refund(
                    ...args
                )
                .estimateGas({
                    from: wallet,
                    gasPrice: web3.utils.toHex(String(gasPrice)),
                });



            await claimContract.methods.refund(
                ...args
            )
                .send({
                    from: wallet,
                    gas: gasEstimate,
                    gasPrice: web3.utils.toHex(String(gasPrice)),
                })
                .then(async (resRefund: any) => {
                    let data = {
                        ownerAddress: wallet,
                        roundId: item?.id,
                        txHash: resRefund?.transactionHash
                    }
                    await actions1.commited(data)
                    const interval = setInterval(function () {
                        web3.eth.getTransactionReceipt(
                            resRefund?.transactionHash,
                            function (err: any, rec: any) {
                                if (rec) {
                                    // setIsJoined(true)
                                    checkRealRefund()
                                    setLoadingRefund(false)
                                    setShowBtnClaim(false)
                                    notiSuccess('Refund successfully', 3)
                                    clearInterval(interval);
                                }
                                if (err) {
                                    console.log("err", err);
                                    setLoadingRefund(false)
                                    notiError('Error Refund', 3)
                                }
                            }
                        )
                    }, 1000);
                })
                .catch((errClaim: any) => {
                    console.log('errClaim', errClaim)
                    notiError(errClaim?.message || errClaim, 3)
                    setLoadingRefund(false)
                })
        } catch (error: any) {
            setLoadingRefund(false)
            notiError(error?.message || error, 3)
            console.log('error', error)
        }
    }
    const checkJoined = async () => {
        try {
            await joinContract.methods.isJoined(wallet, item?.id, item?.id)
                .call()
                .then((res: any) => {
                    setIsJoined(res);
                })
                .catch((err: any) => {
                    setIsJoined(false);
                })
        } catch (error) {
            setIsJoined(false);
        }
    }
    useEffect(() => {
        if (item?.allocation?.joinStatus === true) {
            setIsJoined(true);
        } else if (scJoin !== null && scJoin !== "") {
            checkJoined()
        }
    }, [wallet, item])

    const [isClaimChange, setIsClaimChange]: any = useState(false)
    const checkClaimChange = async () => {
        try {

            await claimChangeContract?.methods.userRefund(wallet)
                .call()
                .then((res: any) => {
                    console.log('res', res)
                    setIsClaimChange(res)
                    setSkeleton(false)

                })
                .catch((err: any) => {
                    console.log('err', err)
                    setSkeleton(false)

                })
        } catch (error) {
            console.log('error', error)
            setSkeleton(false)

        }
    }

    useEffect(() => {
        if (wallet && scClaimChange) {
            checkClaimChange()
        }
    }, [wallet, item, scClaimChange])


    const handleClaimChange = async () => {
        try {
            setLoadingClaimChange(true)

            const gasPrice = await web3.eth.getGasPrice();

            let amount = convertTokenToWei(item?.allocation?.claimChange?.refundBusd, item?.allocation?.claimChange?.refundDecimal)
            let sigClaimChange = item?.allocation?.claimChange?.signRefund
            let args = [amount, sigClaimChange];

            const gasEstimate = await claimChangeContract.methods
                .refund(...args)
                .estimateGas({
                    from: wallet,
                    gasPrice: web3.utils.toHex(String(gasPrice)),
                });
            await claimChangeContract.methods.refund(...args)
                .send({
                    from: wallet,
                    gas: gasEstimate,
                    gasPrice: web3.utils.toHex(String(gasPrice)),
                })
                .then(async (resClaimFund: any) => {
                    console.log('resClaimFund', resClaimFund)
                    let data = {
                        ownerAddress: wallet,
                        roundId: item?.id,
                        txHash: resClaimFund?.transactionHash
                    }
                    await actions1.commited(data)
                    const interval = setInterval(function () {
                        web3.eth.getTransactionReceipt(
                            resClaimFund?.transactionHash,
                            function (err: any, rec: any) {
                                if (rec) {
                                    // setIsJoined(true)
                                    setIsClaimChange(true)
                                    setLoadingClaimChange(false)
                                    notiSuccess('Claim Fund successfully', 3)
                                    clearInterval(interval);
                                }
                                if (err) {
                                    console.log("err", err);
                                    setLoadingClaimChange(false)
                                    notiError('Error Claim Fund', 3)
                                }
                            }
                        )
                    }, 1000);
                })
                .catch((errClaimFund: any) => {
                    console.log('errClaimFund', errClaimFund)
                    notiError(errClaimFund?.message || errClaimFund, 3)
                    setLoadingClaimChange(false)
                })
        } catch (error: any) {
            console.log('error', error)
            setLoadingClaimChange(false)
            notiError(error?.message || error, 3)

        }
    }

    const [valueYourToken, setValueYourToken]: any = useState()
    useEffect(() => {
        setValueYourToken(item?.allocation?.tokenAllocation || 0)
    }, [wallet, item])

    //--

    const [totalJoinedSC, setTotalJoinedSC] = useState(0)
    const getTotalJoinSC = async () => {
        try {
            await joinContract.methods.totalJoined()
                .call()
                .then((res: any) => {
                    setTotalJoinedSC(res / (1 * 10 ** decimalTokenJoin) || 0)
                })
                .catch((err: any) => {
                    console.log('err', err)
                    setTotalJoinedSC(0)
                })
        } catch (error) {
            console.log('error', error)
        }
    }

    useEffect(() => {
        if (joinContract) {
            getTotalJoinSC()
        }

    }, [joinContract])

    let _percentProcess = 0;
    let totalJoined = 0
    if (item?.totalRaise > 0) {
        const _percent = (totalJoinedSC / item?.totalRaise) * 100
        if (_percent > item.percentProcess) {
            _percentProcess = _percent;
            totalJoined = totalJoinedSC
        } else {
            _percentProcess = item.percentProcess;
            totalJoined = item.percentProcess * item?.totalRaise / 100
        }
    }

    let percentProcess: any = Number(_percentProcess).toFixed(1);

    return (
        <>
            <div className="list-content-right">
                <div className="item-content-right">
                    <div className="countdown-title-right">
                        <div className="countdown-left">{item?.name} ended</div>
                        <div className="countdown-right">
                            <div className="status-ido ended">
                                <img className='img-audit' src="../images/launchpad/status-end.png" alt="" />
                                <span className='info-ido-audit'>End</span>
                            </div>
                        </div>
                    </div>

                    <div className="total-raise-detail">
                        Total Raised:
                        <span>
                            {totalJoined} {tokenJoinPool} / {formatNumber(item?.totalRaise)} {tokenJoinPool} ({formatNumber(percentProcess, 1)}%)
                            {/* {totalRaise(item?.percentProcess, item?.totalRaise)} {tokenJoinPool}
                            /{formatNumber(item?.totalRaise)} {tokenJoinPool} ({item?.percentProcess}%) */}
                        </span>
                    </div>
                    <div className="allo-detail">
                        Your Investment <span>{item?.allocation?.commitFunds?.commitFund} {tokenJoinPool}</span>
                    </div>
                    {isJoined && item?.allocation ? (
                        <>
                            {item?.allocation?.claimChange?.refundContract !== null ? (
                                <>
                                    {item?.allocation?.claimChange?.signRefund !== null ? (
                                        <>
                                            {skeleton ? (
                                                <div className="ido-fund">
                                                    <div className="alo-num-end">
                                                        Remaining: <span>{item?.allocation?.claimChange?.refundBusd} {tokenJoinPool}</span>
                                                    </div>
                                                    <div className="button-fund">
                                                        <div className="ske-claim-chain">
                                                            <Skeleton.Button active={true} size={'large'} shape={'round'} block={false} />
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                    {isClaimChange ? (
                                                        <div className="ido-fund">
                                                            <div className="alo-num-end">
                                                                Remaining: <span>{item?.allocation?.claimChange?.refundBusd} {tokenJoinPool}</span>
                                                            </div>
                                                            <div className="button-fund ">
                                                                <button className='claim-fund dis-claim-fund'>Claimed Fund</button>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            {item?.allocation?.claimChange?.allowClaimChange === false ? (
                                                                <div className="ido-fund">
                                                                    <div className="alo-num-end">
                                                                        Remaining: <span>{item?.allocation?.claimChange?.refundBusd} {tokenJoinPool}</span>
                                                                    </div>
                                                                    <div className="button-fund ">
                                                                        <button className='claim-fund dis-claim-fund'>Claim Fund</button>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    <div className="ido-fund">
                                                                        <div className="alo-num-end">
                                                                            Remaining: <span>{item?.allocation?.claimChange?.refundBusd} {tokenJoinPool}</span>
                                                                        </div>
                                                                        {loadingClaimChange ? (
                                                                            <div className="button-fund">
                                                                                <button className='claim-fund dis-claim-fund'>
                                                                                    Waiting &#160; <Spin indicator={antIcon} />
                                                                                </button>
                                                                            </div>
                                                                        ) : (
                                                                            <div className="button-fund ">
                                                                                <button className='claim-fund' onClick={handleClaimChange}>Claim Fund</button>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <div className="ido-fund">
                                                <div className="alo-num-end">
                                                    Remaining: <span>NA {tokenJoinPool}</span>
                                                </div>
                                                <div className="button-fund ">
                                                    <button className='claim-fund dis-claim-fund'>Culcalating</button>
                                                </div>
                                            </div>
                                        </>
                                    )}

                                </>
                            ) : ('')}
                        </>
                    ) : ('')}
                    <div className="alo-num">Your Tokens</div>
                    <div className="input-contribute">
                        <input disabled className='input-con' value={valueYourToken} type="text" />
                        <div className="before-input">
                            <div className="text-before">{symbolTokenClaim}</div>
                        </div>
                    </div>
                    <div className="btn-detail">
                        {
                            item?.allocation !== null ? (
                                <>
                                    {isJoined ? (
                                        <>
                                            {
                                                !showBtnClaim ? (
                                                    <>
                                                        {userRefund > 0 ? (
                                                            <button className='btn-link btn-disable'>
                                                                Refuned
                                                            </button>
                                                        ) : (
                                                            <>
                                                                {item?.allocation?.claimbles === null ? (
                                                                    <>
                                                                        <button className='btn-link btn-disable'>
                                                                            Joined
                                                                        </button>
                                                                    </>
                                                                ) : (
                                                                    <button className='btn-link btn-disable'>
                                                                        Claimed
                                                                    </button>
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                ) : (
                                                    <div className='group-button-row'>

                                                        {loadingRefund ? (
                                                            <button className="btn-link  btn-disable">
                                                                Claim
                                                            </button>
                                                        ) : (
                                                            <>
                                                                {loadingClaim ? (
                                                                    <button className='btn-link btn-disable'>
                                                                        Waiting &#160; <Spin indicator={antIcon} />
                                                                    </button>
                                                                ) : (
                                                                    <button className='btn-link' onClick={handleClaim}>
                                                                        Claim
                                                                    </button>
                                                                )}
                                                            </>
                                                        )}

                                                        {item?.allocation?.claimbles?.signBusd ? (
                                                            <>

                                                                {loadingClaim ? (
                                                                    <button className="btn-link  btn-disable">
                                                                        Refund
                                                                    </button>
                                                                ) : (
                                                                    <>
                                                                        {loadingRefund ? (
                                                                            <button className="btn-link  btn-disable">
                                                                                Waiting &#160; <Spin indicator={antIcon} />
                                                                            </button>
                                                                        ) : (
                                                                            <button className='btn-link' onClick={handleRefund}>
                                                                                Refund
                                                                            </button>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </>
                                                        ) : ('')}
                                                    </div>
                                                )
                                            }
                                        </>
                                    ) : (
                                        <>
                                            <button className='btn-not-join'>
                                                You did not join the sale
                                            </button>
                                        </>
                                    )}
                                </>
                            ) : (
                                <button className='btn-not-join'>
                                    You’re not whitelisted
                                </button>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

const ItemOpen = (props: any) => {
    const [state1, actions1]: any = useHookStore();
    let web3 = props.web3
    let chain = props.chain
    let wallet = props.wallet
    let item = props.item
    let tokenJoinPool = props.tokenJoin
    let addressTokenJoin = props.addressTokenJoin
    let decimalTokenJoin = props.decimalTokenJoin
    const scJoin = item?.commitFundContract
    const [skeleton, setSkeleton]: any = useState(true)
    const joinContract = new web3.eth.Contract(
        abiJoin as unknown as AbiItem,
        scJoin
    );
    const contractTokenJoin = new web3.eth.Contract(
        abiTokenDefault as unknown as AbiItem,
        addressTokenJoin
    );
    // function totalRaise(percentProcess: any, totalRaise: any) {
    //     if (totalRaise && percentProcess) {
    //         let total = totalRaise / 100 * percentProcess
    //         return formatNumber(total, 1)
    //     } else {
    //         return 0
    //     }
    // }
    const [valueSubmit, setValueSubmit]: any = useState()
    const handleChainInputSubmit = (event: any) => {
        let value = event.target.value.replace(/[^0-9.]/g, "");
        setValueSubmit(value)

    };
    const [loadingSubmit, setLoadingSubmit]: any = useState(false)
    const handleSubmitAllocation = (roundId: any) => {
        try {
            setLoadingSubmit(true)
            if (!valueSubmit ||
                valueSubmit === 0 ||
                valueSubmit < item?.allocation?.commitFunds?.minCommitUsd ||
                valueSubmit > item?.allocation?.commitFunds?.maxCommitUsd
            ) {
                notiError('Invalid params', 3)
                setLoadingSubmit(false)
                return
            }

            let data = {
                ownerAddress: wallet,
                roundId: roundId,
                amount: Number(valueSubmit)
            }
            console.log('data submit', data)
            actions1.submitAllocation(data).then((res: any) => {
                console.log('res', res)
                setLoadingSubmit(false)
                window.location.reload()
            })
                .catch((err: any) => {
                    console.log('err', err?.message)
                    notiError(err?.message, 3)
                    setLoadingSubmit(false)
                })
        } catch (error) {
            console.log('error', error)
            setLoadingSubmit(false)
            notiError(error, 3)
        }
    }
    // join pool--------------------

    const [loadingJoin, setLoadingJoin]: any = useState(false)
    const [isJoined, setIsJoined]: any = useState(false)
    const handleJoinPool = async () => {
        try {
            setLoadingJoin(true)
            const gasPrice = await web3.eth.getGasPrice();
            let amountJoinToWei = convertToWei(item?.allocation?.commitFunds?.commitFund, decimalTokenJoin)
            let id = item?.id
            let signatureJoin = item?.allocation?.commitFunds?.commitFundSignature

            if (addressTokenJoin === addressTokenMain) {

                const gasEstimate = await joinContract.methods
                    .join(
                        amountJoinToWei,
                        id,
                        id,
                        signatureJoin
                    )
                    .estimateGas({
                        from: wallet,
                        value: amountJoinToWei,
                        gasPrice: web3.utils.toHex(String(gasPrice)),
                    });
                console.log('gasEstimate', gasEstimate)

                await joinContract.methods.join(
                    amountJoinToWei,
                    id,
                    id,
                    signatureJoin
                )
                    .send({
                        from: wallet,
                        value: amountJoinToWei,
                        gas: gasEstimate,
                        gasPrice: web3.utils.toHex(String(gasPrice)),
                    })
                    .then(async (resJoin: any) => {
                        console.log('resJoin', resJoin)
                        let data = {
                            ownerAddress: wallet,
                            roundId: id,
                            txHash: resJoin?.transactionHash
                        }
                        await actions1.commited(data)
                        const interval = setInterval(function () {
                            web3.eth.getTransactionReceipt(
                                resJoin?.transactionHash,
                                function (err: any, rec: any) {
                                    if (rec) {
                                        setIsJoined(true)
                                        notiSuccess('Join Pool successfully', 3)
                                        clearInterval(interval);
                                    }
                                    if (err) {
                                        console.log("err", err);
                                        setLoadingJoin(false)
                                        notiError('Error Staking', 3)
                                    }
                                }
                            )
                        }, 1000);
                    })
                    .catch((errJoin: any) => {
                        console.log('errJoin', errJoin)
                        notiError(errJoin?.message || errJoin, 3)
                        setLoadingJoin(false)
                    })
            } else {
                const gasEstimate = await joinContract.methods
                    .join(
                        amountJoinToWei,
                        id,
                        id,
                        signatureJoin
                    )
                    .estimateGas({
                        from: wallet,
                        gasPrice: web3.utils.toHex(String(gasPrice)),
                    });

                await joinContract.methods.join(
                    amountJoinToWei,
                    id,
                    id,
                    signatureJoin
                )
                    .send({
                        from: wallet,
                        gas: gasEstimate,
                        gasPrice: web3.utils.toHex(String(gasPrice)),
                    })
                    .then(async (resJoin: any) => {
                        console.log('resJoin', resJoin)
                        let data = {
                            ownerAddress: wallet,
                            roundId: id,
                            txHash: resJoin?.transactionHash
                        }
                        await actions1.commited(data)
                        const interval = setInterval(function () {
                            web3.eth.getTransactionReceipt(
                                resJoin?.transactionHash,
                                function (err: any, rec: any) {
                                    if (rec) {
                                        setIsJoined(true)
                                        notiSuccess('Join Pool successfully', 3)
                                        setLoadingJoin(false)
                                        clearInterval(interval);
                                    }
                                    if (err) {
                                        console.log("err", err);
                                        setLoadingJoin(false)
                                        notiError('Error Join Pool', 3)
                                    }
                                }
                            )
                        }, 1000);
                    })
                    .catch((errJoin: any) => {
                        console.log('errJoin', errJoin)
                        notiError(errJoin?.message || errJoin, 3)
                        setLoadingJoin(false)
                    })
            }
        } catch (error: any) {
            setLoadingJoin(false)
            console.log('error', error)
            notiError(error?.message || error, 3)
        }
    }
    const checkJoined = async () => {
        try {
            await await joinContract.methods.isJoined(wallet, item?.id, item?.id)
                .call()
                .then((res: any) => {
                    setIsJoined(res)
                    setSkeleton(false)
                })
                .catch((err: any) => {
                    console.log('err--', err)
                    setSkeleton(false)
                })
        } catch (error) {
            console.log('error--', error)
            setSkeleton(false)
        }
    }
    useEffect(() => {
        if (wallet && item) {
            checkJoined()
        } else {
            setIsJoined(false)
        }
    }, [wallet, item])
    // join pool--------------------
    // approve join--------------------
    const [loadingApprove, setLoadingApprove]: any = useState(false)
    const [isApprove, setIsApprove]: any = useState(false)
    const [amountApprove, setAmountApprove]: any = useState(0)

    const handleApprove = async () => {
        try {
            setLoadingApprove(true)
            const gasPrice = await web3.eth.getGasPrice();
            await contractTokenJoin.methods.approve(
                scJoin,
                UINT256_MAX
            )
                .send({
                    from: wallet,
                    gasPrice: web3.utils.toHex(String(gasPrice))
                })
                .then((resApprove: any) => {
                    console.log('resApprove', resApprove)
                    const interval = setInterval(function () {
                        web3.eth.getTransactionReceipt(
                            resApprove?.transactionHash,
                            function (err: any, rec: any) {
                                if (rec) {
                                    setLoadingApprove(false)
                                    // setIsApprove(true)
                                    checkApprove()
                                    notiSuccess('Approve successfully', 3)
                                    clearInterval(interval);
                                }
                                if (err) {
                                    console.log("err", err);
                                    setLoadingApprove(false)
                                    notiError('Error approve', 3)
                                }
                            }
                        )
                    }, 1000);
                })
                .catch((errAprove: any) => {
                    console.log('errAprove', errAprove)
                    notiError(errAprove?.message || 'Unknown error occurred', 4)
                    setLoadingApprove(false)
                })
        } catch (error: any) {
            console.log('error', error)
            setLoadingApprove(false)
        }
    }

    const checkApprove = async () => {
        try {
            await contractTokenJoin.methods.allowance(wallet, scJoin)
                .call()
                .then((res: any) => {
                    setAmountApprove(Number(res) / (1 * 10 ** decimalTokenJoin))
                })
                .catch((err: any) => {
                    console.log('err', err)
                    setAmountApprove(0)
                })
        } catch (error) {
            console.log('error', error)
            setAmountApprove(0)
        }
    }


    useEffect(() => {
        if (addressTokenJoin !== addressTokenMain && amountApprove < item?.allocation?.commitFunds?.commitFund) {
            setIsApprove(false)
        } else {
            setIsApprove(true)
        }
    }, [amountApprove, wallet, contractTokenJoin])
    useEffect(() => {
        if (addressTokenJoin === addressTokenMain) {
            setIsApprove(true)
        } else {
            checkApprove()
        }
    }, [addressTokenMain, addressTokenJoin, wallet])

    // approve join--------------------
    const [totalJoinedSC, setTotalJoinedSC] = useState(0)
    const getTotalJoinSC = async () => {
        try {
            await joinContract.methods.totalJoined()
                .call()
                .then((res: any) => {
                    setTotalJoinedSC(res / (1 * 10 ** decimalTokenJoin) || 0)
                })
                .catch((err: any) => {
                    console.log('err', err)
                    setTotalJoinedSC(0)
                })
        } catch (error) {
            console.log('error', error)
        }
    }

    useEffect(() => {
        if (joinContract) {
            getTotalJoinSC()
        }

    }, [joinContract])

    let _percentProcess = 0;
    let totalJoined = 0
    if (item?.totalRaise > 0) {
        const _percent = (totalJoinedSC / item?.totalRaise) * 100
        if (_percent > item.percentProcess) {
            _percentProcess = _percent;
            totalJoined = totalJoinedSC
        } else {
            _percentProcess = item.percentProcess;
            totalJoined = item.percentProcess * item?.totalRaise / 100
        }
    }

    let percentProcess: any = Number(_percentProcess).toFixed(1);



    return (
        <>
            <div className="list-content-right">
                <div className="item-content-right">
                    <div className="countdown-title-right">
                        <div className="countdown-left">{item?.name} end In</div>
                        <div className="countdown-right">
                            <div className="status-ido live">
                                <img className='img-audit' src="../images/launchpad/status-live.png" alt="" />
                                <span className='info-ido-audit'>Live</span>
                            </div>
                        </div>
                    </div>
                    <div className="countdown-detail-right">
                        <Countdown
                            date={item?.closeJoinIdo * 1000}
                            renderer={rendererCountDown}
                        />
                    </div>
                    <div className="total-raise-detail">
                        Total Raised:
                        <span>
                            {totalJoined} {tokenJoinPool} / {formatNumber(item?.totalRaise)} {tokenJoinPool} ({formatNumber(percentProcess, 1)}%)
                            {/* {totalRaise(item?.percentProcess, item?.totalRaise)} {tokenJoinPool}
                            /{formatNumber(item?.totalRaise)} {tokenJoinPool} ({item?.percentProcess}%) */}
                        </span>
                    </div>
                    <div className="allo-detail">
                        Your Allocation :
                        <span>
                            {item?.allocation?.commitFunds?.commitFund ? (
                                <>
                                    {item?.allocation?.commitFunds?.commitFund} {tokenJoinPool}
                                </>
                            ) : ('NAN')}

                        </span>
                    </div>
                    {item?.allocation?.commitFunds?.minCommitUsd !== 0 &&
                        item?.allocation?.commitFunds?.maxCommitUsd !== 0 &&
                        item?.allocation?.commitFunds?.commitFund === 0
                        ? (
                            <>
                                <div className="alo-num">
                                    Min:
                                    <span>
                                        {item?.allocation?.commitFunds?.minCommitUsd} {tokenJoinPool}
                                    </span>
                                    &ensp;
                                    Max:
                                    <span>{item?.allocation?.commitFunds?.maxCommitUsd} {tokenJoinPool}</span>
                                </div>
                                <div className="alo-num">Contribute:</div>
                                <div className="input-contribute">
                                    <input
                                        className='input-con'
                                        placeholder="0"
                                        onChange={handleChainInputSubmit}
                                        value={valueSubmit}
                                    />
                                    <div className="before-input">
                                        <div className="text-before">AVAX</div>
                                        <img src="../images/launchpad/mini-avax.png" alt="" />
                                    </div>
                                </div>
                            </>
                        ) : ('')}

                    <div className="btn-detail">
                        {wallet ? (
                            <>
                                {skeleton ? (
                                    <div className="skeleton">
                                        <Skeleton.Button active={true} size={'large'} shape={'round'} block={false} />
                                    </div>
                                ) : (
                                    <>
                                        {item?.allocation !== null ? (
                                            <>
                                                {item?.allocation?.commitFunds?.commitFund === 0 ? (
                                                    <>
                                                        {
                                                            loadingSubmit ? (
                                                                <button
                                                                    className='btn-link btn-disable'
                                                                >
                                                                    Waiting &#160; <Spin indicator={antIcon} />
                                                                </button>
                                                            ) : (
                                                                <button
                                                                    className='btn-link'
                                                                    onClick={() => handleSubmitAllocation(item?.id)}
                                                                >
                                                                    Contribute
                                                                </button>
                                                            )
                                                        }
                                                    </>
                                                ) : (
                                                    <>
                                                        {
                                                            <>
                                                                {isApprove ? (

                                                                    <>
                                                                        {isJoined ? (
                                                                            <button className='btn-not-join'>
                                                                                Joined
                                                                            </button>
                                                                        ) : (
                                                                            <>
                                                                                {loadingJoin ? (
                                                                                    <button className='btn-link btn-disable'>
                                                                                        Waiting &#160; <Spin indicator={antIcon} />
                                                                                    </button>
                                                                                ) : (
                                                                                    <button className='btn-link' onClick={handleJoinPool}>
                                                                                        Join Pool
                                                                                    </button>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </>

                                                                ) : (
                                                                    <>
                                                                        {loadingApprove ? (
                                                                            <button className='btn-link btn-disable'>
                                                                                Waiting &#160; <Spin indicator={antIcon} />
                                                                            </button>
                                                                        ) : (
                                                                            <button className='btn-link' onClick={handleApprove}>
                                                                                Approve
                                                                            </button>
                                                                        )}
                                                                    </>

                                                                )}
                                                            </>
                                                        }
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <button className='btn-not-join'>
                                                You’re not whitelisted
                                            </button>
                                        )}
                                    </>
                                )}

                            </>
                        ) : (
                            <WagmiConnect />
                        )}









                        {/* <button className='btn-not-join'>
                                                    You’re not whitelisted
                                                </button> */}
                        {/* <button className='btn-not-join'>
                                                    Joined
                                                </button> */}
                        {/* <button className='btn-link' onClick={handleApprove}>
                                                    Approve &#160;
                                                    <Spin indicator={antIcon} />
                                                </button> */}
                        {/* <button className='btn-link'>
                                                    Join
                                                    <Spin indicator={antIcon} />
                                                </button> */}
                        {/* <button className='btn-link'>
                                                    Contribute
                                                </button> */}
                    </div>


                </div>
            </div>
        </>
    )
}

const ItemUpComing = (props: any) => {
    let tokenJoinPool = props.tokenJoin
    function totalRaise(percentProcess: any, totalRaise: any) {
        if (totalRaise && percentProcess) {
            let total = totalRaise / 100 * percentProcess
            return formatNumber(total, 1)
        } else {
            return 0
        }
    }
    return (
        <>
            <div className="list-content-right">
                <div className="item-content-right">
                    <div className="countdown-title-right">
                        <div className="countdown-left">{props?.name} starts In</div>
                        <div className="countdown-right">
                            <div className="status-ido upcoming">
                                <img className='img-audit' src="../images/launchpad/audit.png" alt="" />
                                <span className='info-ido-audit'>Upcoming</span>
                            </div>
                        </div>
                    </div>
                    <div className="countdown-detail-right">
                        <Countdown
                            date={props?.time * 1000}
                            renderer={rendererCountDown}
                        />
                    </div>
                    <div className="total-raise-detail">
                        Total Raised:
                        <span>
                            {totalRaise(props?.percentProcess, props?.totalRaise)} {props?.symbol}
                            /{formatNumber(props?.totalRaise)} {props?.symbol} ({props?.percentProcess}%)

                        </span>
                    </div>
                    <div className="allo-detail">
                        Committed Fund:
                        <span>
                            {props?.allocation ? (
                                <>
                                    {props?.allocation} {props?.symbol}
                                </>
                            ) : (
                                'TBA'
                            )}

                        </span>
                    </div>

                    {props?.item?.allocation?.commitFunds?.minCommitUsd !== 0 &&
                        props?.item?.allocation?.commitFunds?.maxCommitUsd !== 0 &&
                        props?.item?.allocation?.commitFunds?.commitFund === 0
                        ? (
                            <>
                                <div className="alo-num">
                                    Min:
                                    <span>
                                        {props?.item?.allocation?.commitFunds?.minCommitUsd} {tokenJoinPool}
                                    </span>
                                    &ensp;
                                    Max:
                                    <span>{props?.item?.allocation?.commitFunds?.maxCommitUsd} {tokenJoinPool}</span>
                                </div>
                                {/* <div className="alo-num">Contribute:</div>
                                <div className="input-contribute">
                                    <input
                                        className='input-con'
                                        placeholder="0"
                                        onChange={handleChainInputSubmit}
                                        value={valueSubmit}
                                    />
                                    <div className="before-input">
                                        <div className="text-before">AVAX</div>
                                        <img src="../images/launchpad/mini-avax.png" alt="" />
                                    </div>
                                </div> */}
                            </>
                        ) : ('')}

                    <div className="alo-num">
                        Sale Format:
                        <span>
                            Public - Everyone can join
                        </span>
                    </div>

                </div>
            </div>
        </>
    )
}

const DetailsLaunchPad = () => {

    let navigate = useNavigate();
    const [state1, actions1]: any = useHookStore();
    let location = useLocation();
    const [dataDetail, setDataDetail]: any = useState()
    const [skeDetail, setSkeDetail]: any = useState(true)

    const { selectedNetworkId } = useWeb3ModalState()
    const { address } = useAccount();

    let wallet: any = address || ""
    let chain: any = selectedNetworkId || ""
    let web3: any



    const connectorId = window.localStorage.getItem("wagmi.recentConnectorId");

    if (connectorId === "io.metamask" || connectorId === "bsc") {
        web3 = new Web3(window.ethereum);
    } else if (connectorId === "com.trustwallet.app") {
        web3 = new Web3(window.trustwallet);
    } else if (connectorId === "com.bitget.web3") {
        web3 = new Web3(window.bitkeep.ethereum);
    } else if (connectorId === "com.okex.wallet") {
        web3 = new Web3(window.okexchain);
    } else {
        web3 = new Web3(window.ethereum);
    }

    const gotoLaunchPool = () => {
        navigate("/staking");
    }
    const back = () => {
        navigate('/launchpad')
    }
    window.scrollTo(0, 0)

    let status = dataDetail?.status
    //--call api detail

    const getDetail = (address: any, keywork: any) => {
        try {
            let data = {
                address: address || "",
                keywork
            }
            actions1.getDetailIDO(data).then((res: any) => {
                setDataDetail(res)
                setSkeDetail(false)
            })
                .catch((err: any) => {
                    console.log('err', err)
                })
        } catch (error) {

        }
    }

    let path = location.search?.split('?')[1]
    useEffect(() => {
        // if (wallet) {
        getDetail(wallet, path)
        // }

    }, [wallet, path])


    function gotoWeb(website: any): void {
        window.open(website)

    }

    function gotoX(twitter: any): void {
        window.open(twitter)
    }

    function gotoTele(telegram: any): void {
        window.open(telegram)
    }

    function gotoYoutobe(medium: any): void {
        window.open(medium)
    }

    function handleJoinNow() {
        window.open("https://t.me/geckoinuavax")
    }
    function gotoLink(linkUrl: any): void {
        window.open(linkUrl)
    }

    return (
        <div className="wrap-detail">
            <div className='container-ido-detail'>
                {skeDetail ? (
                    <>
                        <Skeleton active />
                        <Skeleton active />
                        <Skeleton active />
                        <Skeleton active />
                        <Skeleton active />
                    </>
                ) : (
                    <>
                        <div className="back-top">
                            <img onClick={back} src="../images/launchpad/back.png" alt="" />
                            <span onClick={back}>Back</span>
                        </div>
                        <div className="detail-lp-content">
                            <div className="detail-lp-left">
                                <div className="detail-content-left">
                                    <div className="banner-detail">
                                        {/* <img src="../images/launchpad/banner-detail-lp.png" alt="" /> */}
                                        <img src={dataDetail?.bannerUrl} alt="" />
                                    </div>
                                    <div className="sub-detail-bot">
                                        <div className="list-info-img">
                                            <div className="logo-detail-lp">
                                                {/* <img src="../images/launchpad/logo-detail-lp.png" alt="" /> */}
                                                <img src={dataDetail?.claimTokenInfo?.logoUrl} alt="" />
                                            </div>
                                            {isMobile ? (
                                                <div className="list-social-detail">
                                                    <div className="name-detail">{dataDetail?.name}</div>
                                                    <div className="list-social-lp">
                                                        <div className="social-web" onClick={() => gotoWeb(dataDetail?.socical?.website)}>
                                                            <img src="../images/launchpad/web.png" alt="" />
                                                        </div>
                                                        <div className="social-tw" onClick={() => gotoX(dataDetail?.socical?.twitter)}>
                                                            <img src="../images/launchpad/social-tw.png" alt="" />
                                                        </div>
                                                        <div className="social-tele" onClick={() => gotoTele(dataDetail?.socical?.telegram)}>
                                                            <img src="../images/launchpad/social-tele.png" alt="" />
                                                        </div>
                                                        <div className="social-youtube" onClick={() => gotoYoutobe(dataDetail?.socical?.medium)}>
                                                            <img src="../images/launchpad/youtube.png" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                            <div className="list-info-detail">
                                                {/* {dataDetail?.documents && dataDetail?.documents?.length > 0 && dataDetail?.documents
                                                    .filter((doc: any) => doc.name === 'Audit')
                                                    .map((obj: any, index: any) => (
                                                        <>
                                                            <div className="item-info-ido-audit" onClick={() => gotoLink(obj?.linkUrl)}>
                                                                <img className='img-audit' src="../images/launchpad/audit.png" alt="" />
                                                                <span className='info-ido-audit'>{obj?.name}</span>
                                                            </div>
                                                        </>
                                                    ))}
                                                {dataDetail?.documents && dataDetail?.documents?.length > 0 && dataDetail?.documents
                                                    .filter((doc: any) => doc.name === 'KYC')
                                                    .map((obj: any, index: any) => (
                                                        <>
                                                            <div className="item-info-ido-kyc" onClick={() => gotoLink(obj?.linkUrl)}>
                                                                <img className='img-kyc' src="../images/launchpad/kyc.png" alt="" />
                                                                <span className='info-ido-kyc'>{obj?.name}</span>
                                                            </div>
                                                        </>
                                                    ))}
                                                {dataDetail?.documents && dataDetail?.documents?.length > 0 && dataDetail?.documents
                                                    .filter((doc: any) => doc.name === 'Doxx')
                                                    .map((obj: any, index: any) => (
                                                        <>
                                                            <div className="item-info-ido-doxx" onClick={() => gotoLink(obj?.linkUrl)}>
                                                                <img className='img-doxx' src="../images/launchpad/doxx.png" alt="" />
                                                                <span className='info-ido-doxx'>{obj?.name}</span>
                                                            </div>
                                                        </>
                                                    ))} */}

                                                <div className="item-info-ido-audit">
                                                    <img className='img-audit' src="../images/launchpad/audit.png" alt="" />
                                                    <span className='info-ido-audit'>Audit</span>
                                                </div>
                                                <div className="item-info-ido-kyc">
                                                    <img className='img-kyc' src="../images/launchpad/kyc.png" alt="" />
                                                    <span className='info-ido-kyc'>KYC</span>
                                                </div>
                                                <div className="item-info-ido-doxx">
                                                    <img className='img-doxx' src="../images/launchpad/doxx.png" alt="" />
                                                    <span className='info-ido-doxx'>Doxx</span>
                                                </div>
                                                <div className="item-info-ido-safu">
                                                    <img className='img-safu' src="../images/launchpad/safu.png" alt="" />
                                                    <span className='info-ido-safu'>Safu</span>
                                                </div>
                                            </div>
                                        </div>
                                        {isMobile ? ('') : (
                                            <div className="list-social-detail">
                                                <div className="name-detail">{dataDetail?.name}</div>
                                                <div className="list-social-lp">
                                                    {dataDetail?.socical?.website !== null || dataDetail?.socical?.website !== '' ? (
                                                        <div className="social-web" onClick={() => gotoWeb(dataDetail?.socical?.website)}>
                                                            <img src="../images/launchpad/web.png" alt="" />
                                                        </div>
                                                    ) : ('')}

                                                    {dataDetail?.socical?.twitter !== null || dataDetail?.socical?.twitter !== '' ? (
                                                        <div className="social-tw" onClick={() => gotoWeb(dataDetail?.socical?.twitter)}>
                                                            <img src="../images/launchpad/social-tw.png" alt="" />
                                                        </div>
                                                    ) : ('')}

                                                    {dataDetail?.socical?.telegram !== null || dataDetail?.socical?.telegram !== '' ? (
                                                        <div className="social-tele" onClick={() => gotoWeb(dataDetail?.socical?.telegram)}>
                                                            <img src="../images/launchpad/social-tele.png" alt="" />
                                                        </div>
                                                    ) : ('')}

                                                    {dataDetail?.socical?.medium !== null || dataDetail?.socical?.medium !== '' ? (
                                                        <div className="social-youtube" onClick={() => gotoWeb(dataDetail?.socical?.medium)}>
                                                            <img src="../images/launchpad/youtube.png" alt="" />
                                                        </div>
                                                    ) : ('')}

                                                </div>
                                            </div>
                                        )}

                                        <div className="row-address">
                                            <div className="row-add-left">
                                                <div className="row-add-text">
                                                    Token Address:
                                                </div>
                                                <div className="row-add-btn">
                                                    <button>
                                                        {dataDetail?.claimTokenInfo?.tokenAddress ? (
                                                            <>
                                                                {dataDetail?.claimTokenInfo?.tokenAddress.slice(0, 5)}...{dataDetail?.claimTokenInfo?.tokenAddress.slice(-5)}
                                                            </>
                                                        ) : ('NA')}
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row-add-right">
                                                <CopyToClipboard
                                                    text={`${dataDetail?.claimTokenInfo?.tokenAddress}`}
                                                    onCopy={() => {
                                                        notiSuccess("Copied", 0.4)

                                                    }}
                                                >
                                                    <button>Copy address</button>
                                                </CopyToClipboard>

                                            </div>
                                        </div>
                                        <div className="row-dec">
                                            {dataDetail?.description}
                                        </div>
                                        <div className="row-ticker">
                                            Ticker Token: <span>{dataDetail?.claimTokenInfo?.symbol}</span>
                                        </div>
                                        <div className="row-ticker">
                                            Token Supply:
                                            <span>
                                                {formatNumber(Number(dataDetail?.totalSupply))} {dataDetail?.claimTokenInfo?.symbol}
                                            </span>
                                        </div>
                                        <div className="row-ticker">
                                            Softcap:
                                            <span>
                                                {dataDetail?.totalRaise} {dataDetail?.joinPoolInfo?.symbol}
                                            </span>
                                        </div>
                                        <div className="row-bot-left">
                                            You will claim the Token and remaining fund when sale ends.
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="detail-lp-right">
                                {
                                    status === STATUS_IDO.UPCOMING ? (
                                        <>
                                            {dataDetail?.details && dataDetail?.details.map((item: any, index: any) => (
                                                <>
                                                    <ItemUpComing
                                                        item={item}
                                                        allocation={item?.allocation?.commitFunds?.commitFund}
                                                        totalRaise={item?.totalRaise}
                                                        percentProcess={item?.percentProcess}
                                                        name={item?.name}
                                                        tokenJoin={dataDetail?.joinPoolInfo?.symbol}
                                                        symbol={dataDetail?.joinPoolInfo?.symbol}
                                                        time={item?.startOpenIdo} />


                                                </>
                                            ))}

                                            <div className="list-content-right">
                                                <div className="item-content-right">
                                                    <div className="countdown-title-right">
                                                        <div className="countdown-left">GEC Staking Bonus</div>
                                                    </div>
                                                    <div className="total-raise-detail">
                                                        Stake min <span>2,500,000,000 $GEC</span>  to get <span>5%</span> additional token bonus (based on commit fund, after final allocation calculated)
                                                    </div>

                                                    <div className="btn-detail">
                                                        <button className='btn-link goto' onClick={gotoLaunchPool}>
                                                            Go to Launchpool
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>
                                        </>
                                    ) : ('')
                                }
                                {/* ---------------------------- */}
                                {
                                    status === STATUS_IDO.LIVE ? (
                                        <>
                                            {dataDetail?.details && dataDetail?.details.map((item: any, index: any) => (
                                                <>
                                                    <ItemOpen
                                                        chain={chain}
                                                        web3={web3}
                                                        wallet={wallet}
                                                        item={item}
                                                        tokenJoin={dataDetail?.joinPoolInfo?.symbol}
                                                        addressTokenJoin={dataDetail?.joinPoolInfo?.tokenAddress}
                                                        decimalTokenJoin={dataDetail?.joinPoolInfo?.tokenDecimal}

                                                    />
                                                </>
                                            ))}

                                        </>
                                    ) : ('')
                                }

                                {/* ---------------------------- */}

                                {
                                    status === STATUS_IDO.ENDED ? (
                                        <>
                                            {dataDetail?.details && dataDetail?.details.map((item: any, index: any) => (
                                                <>
                                                    <ItemEnded
                                                        chain={chain}
                                                        web3={web3}
                                                        wallet={wallet}
                                                        item={item}
                                                        tokenJoin={dataDetail?.joinPoolInfo?.symbol}
                                                        addressTokenJoin={dataDetail?.joinPoolInfo?.tokenAddress}
                                                        decimalTokenJoin={dataDetail?.joinPoolInfo?.tokenDecimal}
                                                        symbolTokenClaim={dataDetail?.claimTokenInfo?.symbol}
                                                    />
                                                </>
                                            ))}
                                        </>
                                    ) : ('')
                                }

                            </div>
                        </div>
                        <div className="detail-lp-bot">
                            <div className="detail-bot-left">
                                <img src="../images/launchpad/img-detail-bot3.png" alt="" />

                            </div>
                            <div className="detail-bot-right">
                                <div className="gec-com-detail">
                                    <span>Gecko Inu</span> Community
                                </div>
                                <div className="dec-com-detail">
                                    There is something for everybody. Follow us and read up on what we are doing. Peace!
                                </div>
                                <div className="join-now">
                                    <div className='btn-join-now' onClick={handleJoinNow}>Join now</div>
                                </div>
                                {isMobile ? (
                                    <div className="social-bot">
                                        <ul className="list-social-bot">
                                            <li>
                                                <a href="https://t.me/geckoinuavax" target='blank'><img src="../images/tele-lp.png" alt="" /></a>
                                            </li>
                                            <li>
                                                <a href="https://twitter.com/GeckoInuAvax" target='blank'><img src="../images/x-lp.png" alt="" /></a>
                                            </li>
                                        </ul>
                                    </div>
                                ) : ('')}

                            </div>
                        </div>
                    </>
                )}

            </div>
        </div>

    )
}

export default DetailsLaunchPad;