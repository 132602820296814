import React, { useState, useEffect } from "react";
import { Dropdown, Modal } from "antd";
import {
    useAccount,
    useConnect,
    useDisconnect,
} from "wagmi";
import axios from "axios";
import web3 from "web3";
import { useNavigate } from "react-router-dom";
import "./style.css"
import { useWeb3Modal } from '@web3modal/wagmi/react'
import { useWeb3ModalState } from '@web3modal/wagmi/react'


declare const window: Window & typeof globalThis & { ethereum: any; trustwallet: any; bitkeep: any; okxwallet: any; safepalProvider: any; safepal: any };

const WagmiConnect = (props: any) => {

    let closeDrawer = props.open
    const { open } = useWeb3Modal()
    const { selectedNetworkId } = useWeb3ModalState()
    const chainId: any = selectedNetworkId
    const { connect, connectors } = useConnect();
    const { address, connector, isConnected } = useAccount();
    let account = address
    const { disconnect } = useDisconnect();
    const [bnbBalance, setBnbBalance] = useState(0);
    const [usdPrice, setusdPrice] = useState(0);
    const provider = "https://avalanche.public-rpc.com";
    let w3: any = "";
    if (typeof window !== "undefined") {
        w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);
    }

    const handleDisconnect = () => {
        disconnect();
        setIsModalVisible(false);
    };

    const handleConnect = (connector: any) => {
        if (connector?.connector?.ready) {
            connect(connector);
        } else {
            window.open(
                "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
            );
        }
        setIsModalVisible(false);
    };

    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        if (account) {
            w3.eth.getBalance(account, (err: any, balance: any) => {
                if (balance) {
                    let _balance: any = web3.utils.fromWei(
                        web3.utils.toBN(balance),
                        "ether"
                    );
                    let bnbBl = Math.round(parseFloat(_balance) * 10000) / 10000;
                    setBnbBalance(bnbBl);
                }
            });
        }
    }, [account]);

    const GetPriceOKX = async (chainId: any, tokenAddress: any) => {
        let OKLINK_API_KEY = "f66ae46a-9fdc-4030-8f21-63c8ee2ab957"
        return new Promise(async (resolve, reject) => {
            await axios.get(`https://www.oklink.com/api/v5/explorer/tokenprice/price-multi`,
                {
                    params: {
                        chainId: chainId,
                        tokenContractAddress: tokenAddress
                    },
                    headers: {
                        'OK-ACCESS-KEY': OKLINK_API_KEY,
                    }
                })
                .then((msg: any) => {
                    setusdPrice(Number(msg.data.data[0].lastPrice))
                    resolve(msg.data.data[0].lastPrice)
                })
                .catch(err => {
                    reject(err)
                })
        })
    };

    let navigate = useNavigate();
    const gotoMyNFT = () => {
        navigate('/my-nft');
    }

    const gotoLaunchpad = () => {
        navigate("/launchpad");
    }
    const gotoLaunchpool = () => {
        navigate("/staking");
    }
    const gotoScan = () => {
        window.open(`https://snowtrace.dev/address/${account}`)
    }

    const handleConnectWagmi = () => {
        open();
        props.onClose(false)
    }

    const items = (
        <div className="modal-dis-new">
            <div className="top-dis">
                <div className="left-dis">
                    <img className="icon-meme" src="../images/icon-meme.png" alt="" />
                    <div className="dis1">
                        <span>Wallet connected</span>
                        <span className="dis1-add" onClick={gotoScan}>
                            {account?.slice(0, 4)}...{account?.slice(-4)}
                            {/* {account?.slice(0, 4)}...{account?.slice(-4)} */}
                            <img src="../images/link-add.png" alt="" />
                        </span>
                    </div>
                </div>

                <div className="dis2">
                    <button onClick={handleDisconnect}>
                        Disconnect
                        <img src="../images/icon-dis.png" alt="" />
                    </button>
                </div>
            </div>
            <div className="top-dis2">
                <div className="dis2-left">
                    <img src="../images/avax-add.png" alt="" />
                    <div className="top-dis2-text">
                        <span className="top-dis2-text1">AVAX</span>
                        <span className="top-dis2-text2">Avalanche C-Chain</span>
                    </div>
                </div>
                <div className="dis2-right">
                    <div className="dis2-num1">{bnbBalance}</div>
                    <div className="dis2-num2">${(bnbBalance * usdPrice)?.toFixed(3)}</div>
                </div>

            </div>
            <div className="top-dis3 d-flex-btw" onClick={gotoLaunchpad}>
                <div>
                    <img src="../images/my-lau1.png" alt="" />
                    <span className="">Launchpad</span>
                    {/* <span className="menu-disable">Launchpad <small className="text-red ml-5">(Soon)</small></span> */}
                </div>
                <img src="../images/arrow-right.svg" alt="" />
            </div>
            <div className="top-dis3 d-flex-btw" onClick={gotoLaunchpool} >
                <div>
                    <img src="../images/my-lau.png" alt="" />
                    <span className="">Launch Pool </span>
                </div>
                <img src="../images/arrow-right.svg" alt="" />
            </div>
            <div className="top-dis3 d-flex-btw" onClick={gotoMyNFT}>
                <div className="">
                    <img src="../images/my-nft.png" alt="" />
                    <span>My NFTs</span>
                </div>
                <img src="../images/arrow-right.svg" alt="" />
            </div>

        </div>
    )


    useEffect(() => {
        GetPriceOKX(43114, '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7')
    }, [account])

    return (
        <>
            {isConnected ? (
                <>
                    <Dropdown
                        // trigger={['click']}
                        overlay={
                            items
                        }
                        placement="bottomRight"
                    >
                        <button className={`address-header ${chainId !== 43114 ? 'header-wrong' : ''}`}>
                            {chainId !== 43114 ?
                                <>
                                    <div className="wrong-net">Wrong Network</div>
                                    {address?.substring(0, 4)}...{address?.substring(address.length - 4)}
                                </>
                                :
                                <>
                                    <img src="../images/avax-add.png" alt="" />
                                    {address?.substring(0, 4)}...{address?.substring(address.length - 4)}
                                    <img src="../images/arr.png" alt="" />
                                </>
                            }
                        </button>
                    </Dropdown>
                </>
            ) : (
                <>
                    <button className="btn-connectnew" onClick={handleConnectWagmi}>
                        <img src="../../../images/bot/wallet-money.png" alt="" /> Connect Wallet
                    </button>
                </>
            )}
            <Modal
                title={
                    <div className="text-md connect-wallet-title">
                        {!account ? (
                            <><img src="./images/connect-text.svg" alt="" /></>
                        ) : (
                            "Note"
                        )}
                    </div>
                }
                visible={isModalVisible}
                onOk={handleOk}
                footer={false}
                onCancel={handleCancel}
                className="modal-connect"
            >
                <div className="modal-content-connect">
                    {!isConnected ? (
                        <>
                            {connectors.map((connector) => (
                                <div
                                    className="btn-item-connect"
                                    // disabled={!connector.ready}
                                    key={connector.id}
                                    onClick={() => open()}
                                >
                                    {connector.name === "MetaMask" ? (
                                        <>
                                            <img src="./images/meta-mmm.png" alt="" />
                                        </>
                                    ) : (
                                        <>
                                            <img src="./images/wl.png" alt="" />
                                        </>
                                    )}
                                    <span className="name-chain-wl">{connector.name}</span>
                                </div>
                            ))}
                        </>
                    ) : (
                        <>
                            <button className="btn-connect" onClick={handleDisconnect}>
                                Disconnect
                            </button>
                        </>
                    )}
                </div>
            </Modal>
        </>
    );
};
export default WagmiConnect;
function navigate(arg0: string) {
    throw new Error("Function not implemented.");
}

