import React from "react";
import { Layout } from "antd";
import Header from "../common/header";
import Footer from "../common/footer";
import '.'

const { Content } = Layout;
function Main({ children }: any) {
    return (
        <Layout>
            <Header />
            <Content>{children}</Content>
            <div className="footer">
                <Footer />
            </div>

        </Layout>
    );
}

export default Main;