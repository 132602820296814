/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Switch, Tooltip, Collapse, Tabs, Modal, Spin } from 'antd';
import { LoadingOutlined } from "@ant-design/icons";
import './style.css'
import { isMobile } from 'react-device-detect';
import Web3 from "web3";
import { AbiItem } from "web3-utils";
import { useHookStoreLaunchPool } from './Store'
import { addTokenToWallet } from '../../utils/token';
import abiStakingPool from './abi/stakingPool.json'
import abiTokenDefault from './abi/abiTokenDefault.json'
import { notiError, notiSuccess } from '../../utils/notication';
import { convertToWei, getPoolApr } from '../../utils/convertNumber';
import { formatNumber, rendererCountDown } from '../../utils/formats';
import axios from 'axios';
import Countdown from 'react-countdown';
import { useHook } from 'components/connect-wallet/store';
import { useWeb3ModalState } from '@web3modal/wagmi/react'
import {
    useAccount,
    Config,
    useConnectorClient,
    useChainId
} from "wagmi";
import WagmiConnect from 'components/wagmi-connect';

import { providers } from 'ethers'
import type { Account, Chain, Client, Transport } from 'viem'

const UINT256_MAX =
    "115792089237316195423570985008687907853269984665640564039457";

declare const window: Window &
    typeof globalThis & {
        trustwallet: any;
        ethereum: any;
        bitkeep: any;
        okexchain: any;
        safepalProvider: any;
    };
const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;
const workChain = 43114;


const Launchpool = () => {
    const [state, actions]: any = useHookStoreLaunchPool();
    const { selectedNetworkId } = useWeb3ModalState()
    const { address } = useAccount();
    const chainId = useChainId()
    const [view, setView]: any = useState(1);
    const [listPool, setListPool]: any = useState([])
    const [priceGec, setPriceGec]: any = useState(0);
    const [totalValueLock, setTotalValueLock]: any = useState(0);
    let wallet: any = address || ""
    let chain: any = selectedNetworkId || ""
    let web3: any
    let web3New: any = new Web3('https://api.avax.network/ext/bc/C/rpc');

    const connectorId = window.localStorage.getItem("wagmi.recentConnectorId");

    if (connectorId === "io.metamask" || connectorId === "bsc") {
        web3 = new Web3(window.ethereum);
    } else if (connectorId === "com.trustwallet.app") {
        web3 = new Web3(window.trustwallet);
    } else if (connectorId === "com.bitget.web3") {
        web3 = new Web3(window.bitkeep.ethereum);
    } else if (connectorId === "com.okex.wallet") {
        web3 = new Web3(window.okexchain);
    } else {
        web3 = new Web3(window.ethereum);
    }

    const { Panel } = Collapse;

    const onChange = (checked: boolean) => {
        getListPool(checked)
    };

    const handleChoseView = (value: any) => {
        setView(value)
    }

    const getPrice = async (chainId: any, tokenAddress: any) => {
        let OKLINK_API_KEY = "f66ae46a-9fdc-4030-8f21-63c8ee2ab957"
        return new Promise(async (resolve, reject) => {
            await axios.get(`https://www.oklink.com/api/v5/explorer/tokenprice/price-multi`,
                {
                    params: {
                        chainId: chainId,
                        tokenContractAddress: tokenAddress
                    },
                    headers: {
                        'OK-ACCESS-KEY': OKLINK_API_KEY,
                    }
                })
                .then((msg: any) => {
                    setPriceGec(Number(msg.data.data[0].lastPrice).toFixed(14))
                    resolve(msg.data.data[0].lastPrice)
                })
                .catch(err => {
                    reject(err)
                })
        })
    };

    useEffect(() => {
        getPrice(workChain, '0xE8385CECb013561b69bEb63FF59f4d10734881f3')
    }, [])

    const getListPool = async (onlyStake: any) => {
        try {
            let data = {
                ownerAddress: wallet || '',
                isOnlyStaked: onlyStake

            }
            await actions.getListPools(data).then((res: any) => {
                setListPool(res || [])
            })
                .catch((err: any) => {
                    console.log('err', err)
                })
        } catch (error) {
            console.log('error', error)
        }
    }



    // useEffect
    useEffect(() => {
        getListPool(false)
    }, [wallet])



    const text = (
        <div className='text-tip'>
            <div className="text-t">
                <span className='fee-unstake'>Unstaking fee: <span className='txt-while'>25%</span> </span>
                <br /><br />
                <span className='content-fee'>Applies within <span className='txt-while'>3 days</span> of staking.</span> <br />
                <span className='content-fee'>Unstaking after <span className='txt-while'>3 days</span> will not include a fee. Timer resets every time you stake in the pool.</span>
            </div>

        </div>
    );

    const titleModal = (
        <div className="title-modal">
            <div className="big-title">Stake in pool</div>
            <div className="dec-title">Stake GEC  </div>
            <div className="line-modal"></div>
        </div>
    )

    function viewPool(contractAddress: any, chain: any): void {
        window.open(`https://snowtrace.io/address/${contractAddress}`)
    }

    function viewSwap(address: any, chain: any): void {
        window.open(`https://traderjoexyz.com/avalanche/trade?inputCurrency=AVAX&outputCurrency=${address}`)
    }

    const stakeContract1 = new web3New.eth.Contract(
        abiStakingPool as unknown as AbiItem,
        listPool[0]?.contractAddress
    );

    const getTotalStakedAllPool = async () => {
        try {
            await stakeContract1.methods.totalStaked()
                .call()
                .then((res: any) => {
                    // console.log('res------', res)
                    setTotalValueLock(Number(res) / (1 * 10 ** 18))
                })
                .catch((err: any) => {
                    console.log('err-------', err)
                })
        } catch (error) {
            console.log('error-----', error)
        }
    }

    useEffect(() => {
        if (listPool.length > 0) {
            getTotalStakedAllPool()
        }
    }, [listPool])

    const ItemView1 = (data: any) => {
        let item = data.item
        const [balanceTokenStake, setBalanceTokenStake]: any = useState(0);
        const [balanceMainToken, setBalanceMainToken]: any = useState(0);

        const [amountApprove, setAmountApprove]: any = useState(0);

        const [loadingStake, setLoadingStake]: any = useState(false);
        const [loadingApprove, setLoadingApprove]: any = useState(false);
        const [isApprove, setIsApprove]: any = useState(false);

        const [valueStake, setValueStake]: any = useState(0);
        const [amountTokenYourStaked, setAmountTokenYourStaked]: any = useState(0);
        const [amountTotalStaked, setAmountTotalStaked]: any = useState(0);
        const [amountEarn, setAmountEarn]: any = useState(0);


        const [isModalOpen, setIsModalOpen] = useState(false);


        const [timeUnstake, setTimeUnstake] = useState(0);


        const showModal = () => {
            setIsModalOpen(true);
        };

        const handleOk = () => {
            setIsModalOpen(false);
        };

        const handleCancel = () => {
            setIsModalOpen(false);
        };
        const showModalStake = () => {
            showModal()
        }

        useEffect(() => {
            if (amountApprove === 0 || amountApprove < valueStake) {
                setIsApprove(false)
            } else {
                setIsApprove(true)
            }
        }, [amountApprove, valueStake, isApprove])

        const stakeContract = new web3.eth.Contract(
            abiStakingPool as unknown as AbiItem,
            item?.contractAddress
        );


        const contractTokenStake = new web3.eth.Contract(
            abiTokenDefault as unknown as AbiItem,
            item?.stakingToken?.address
        );


        const getBalanceTokenStake = async () => {
            try {
                let balanMainToken = await web3.eth.getBalance(wallet);
                setBalanceMainToken(Number(balanMainToken) / 1e18)
                await contractTokenStake.methods
                    .balanceOf(wallet)
                    .call()
                    .then((res: any) => {
                        setBalanceTokenStake(Number(res) / (1 * 10 ** item?.stakingToken?.decimals))
                    })
                    .catch((err: any) => {
                        setBalanceTokenStake(0)
                        console.log("err---", err);
                    });
            } catch (error) {

            }
        }

        const getTokenYourStakedBySC = async () => {
            try {
                await stakeContract.methods.balanceOf(wallet)
                    .call()
                    .then((res: any) => {
                        // console.log('res', res)
                        setAmountTokenYourStaked(Number(res) / (1 * 10 ** item?.stakingToken?.decimals))
                    })
                    .catch((err: any) => {
                        setAmountTokenYourStaked(0)
                        console.log('err', err)
                    })
            } catch (error) {
                console.log('error', error)
            }
        }

        const getTotalStaked = async () => {
            try {
                await stakeContract.methods.totalStaked()
                    .call()
                    .then((res: any) => {
                        // console.log('res------', res)
                        setAmountTotalStaked(Number(res) / (1 * 10 ** item?.stakingToken?.decimals))
                    })
                    .catch((err: any) => {
                        console.log('err-------', err)
                    })
            } catch (error) {
                console.log('error-----', error)
            }
        }

        const getAmountEarn = async () => {
            try {
                await stakeContract.methods.pendingRewardByToken(wallet, item?.earningToken?.address)
                    .call()
                    .then((res: any) => {
                        // console.log('res earningToken------', res)
                        setAmountEarn(Number(res) / (1 * 10 ** item?.earningToken?.decimals))
                    })
                    .catch((err: any) => {
                        console.log('err-------', err)
                    })
            } catch (error) {
                console.log('error-----', error)
            }
        }

        useEffect(() => {
            if (wallet && stakeContract) {
                getTokenYourStakedBySC()
                getAmountEarn()
            }
        }, [wallet, stakeContract])

        useEffect(() => {
            if (stakeContract) {
                getTotalStaked()
            }

        }, [stakeContract])

        useEffect(() => {
            if (wallet && item?.contractAddress) {
                getBalanceTokenStake()
            }
        }, [wallet, item?.contractAddress])
        useEffect(() => {
            if (wallet && item?.contractAddress) {
                getAmountApprove()
            }
        }, [wallet, item?.contractAddress, isApprove])

        const handleChangeInput = (e: any) => {
            let x = e.target.value.replace(/[^0-9.]/g, "");
            setValueStake(x)
        }

        const getAmountApprove = async () => {
            try {
                await contractTokenStake.methods.allowance(wallet, item?.contractAddress)
                    .call()
                    .then((resAmountApprove: any) => {
                        setAmountApprove(Number(resAmountApprove) / (1 * 10 ** item?.stakingToken?.decimals))
                    })
                    .catch((errAmountApprove: any) => {
                        console.log('errAmountApprove', errAmountApprove)
                    })
            } catch (error) {
                console.log('error', error)
            }
        }

        const handleApprove = async () => {
            try {
                setLoadingApprove(true)
                const gasPrice = await web3.eth.getGasPrice();
                await contractTokenStake.methods.approve(
                    item?.contractAddress,
                    UINT256_MAX
                )
                    .send({
                        from: wallet,
                        gasPrice: web3.utils.toHex(String(gasPrice))
                    })
                    .then((resApprove: any) => {
                        console.log('resApprove', resApprove)
                        const interval = setInterval(function () {
                            web3.eth.getTransactionReceipt(
                                resApprove?.transactionHash,
                                function (err: any, rec: any) {
                                    if (rec) {
                                        setLoadingApprove(false)
                                        setIsApprove(true)
                                        notiSuccess('Approve successfully', 3)
                                        clearInterval(interval);
                                    }
                                    if (err) {
                                        console.log("err", err);
                                        setLoadingApprove(false)
                                        notiError('Error approve', 3)
                                    }
                                }
                            )
                        }, 1000);
                    })
                    .catch((errAprove: any) => {
                        console.log('errAprove', errAprove)
                        notiError(errAprove?.message || 'Unknown error occurred', 4)
                        setLoadingApprove(false)
                    })
            } catch (error: any) {
                console.log('error', error)
                setLoadingApprove(false)
            }
        }

        const handleStaking = async () => {
            try {
                setLoadingStake(true)
                if (chain !== workChain) {
                    notiError('Wrong network.Pleasr select Avax Chain your wallet', 4)
                    setLoadingStake(false)
                    return
                }
                if (valueStake === 0) {
                    notiError('Amount must be greater than zero', 4)
                    setLoadingStake(false)
                    return
                }
                let valueStakeConverted = convertToWei(valueStake, 18)
                const gasPrice = await web3.eth.getGasPrice();
                const gasEstimate = await stakeContract.methods
                    .deposit(
                        valueStakeConverted
                    )
                    .estimateGas({
                        from: wallet,
                        // value: valueStakeConverted,
                        gasPrice: web3.utils.toHex(String(gasPrice)),
                    });

                await stakeContract.methods.deposit(valueStakeConverted)
                    .send({
                        from: wallet,
                        gas: gasEstimate,
                        gasPrice: web3.utils.toHex(String(gasPrice)),
                    })
                    .then(async (resStake: any) => {
                        console.log('resStake', resStake)
                        let data = {
                            ownerAddress: wallet,
                            poolContract: item?.contractAddress,
                            txHash: resStake?.transactionHash
                        }
                        await actions.setData(data)
                        const interval = setInterval(function () {
                            web3.eth.getTransactionReceipt(
                                resStake?.transactionHash,
                                function (err: any, rec: any) {
                                    if (rec) {
                                        getBalanceTokenStake()
                                        setLoadingStake(false)
                                        getTokenYourStakedBySC()
                                        getTotalStaked()
                                        getblockStaking()
                                        getFeePeriod()
                                        notiSuccess('Staking successfully', 3)
                                        clearInterval(interval);
                                    }
                                    if (err) {
                                        console.log("err", err);
                                        setLoadingStake(false)
                                        notiError('Error Staking', 3)
                                    }
                                }
                            )
                        }, 1000);
                    })
                    .catch((errStake: any) => {
                        console.log('errStake', errStake)
                        notiError(errStake?.message || errStake, 3)
                        setLoadingStake(false)
                    })

            } catch (error: any) {
                console.log('error', error?.message)
                notiError(error?.message || 'Unknown error occurred', 4)
                setLoadingStake(false)
            }
        }

        // unstake-----------------------------------------------------------
        const [loadingUnstake, setLoadingUnstake]: any = useState(false);

        const handleUnstaking = async () => {
            try {
                setLoadingUnstake(true)
                if (amountTokenYourStaked === 0) {
                    notiError('Your staked amount is zero', 4)
                    setLoadingUnstake(false)
                    return
                }
                const gasPrice = await web3.eth.getGasPrice();
                const gasEstimate = await stakeContract.methods
                    .withdrawNew(
                        false
                    )
                    .estimateGas({
                        from: wallet,
                        // value: valueStakeConverted,
                        gasPrice: web3.utils.toHex(String(gasPrice)),
                    });
                await stakeContract.methods.withdrawNew(false)
                    .send({
                        from: wallet,
                        gas: gasEstimate,
                        gasPrice: web3.utils.toHex(String(gasPrice)),
                    })
                    .then(async (resUnstake: any) => {
                        console.log('resUnstake', resUnstake)
                        let data = {
                            ownerAddress: wallet,
                            poolContract: item?.contractAddress,
                            txHash: resUnstake?.transactionHash
                        }
                        await actions.setData(data)
                        const interval = setInterval(function () {
                            web3.eth.getTransactionReceipt(
                                resUnstake?.transactionHash,
                                function (err: any, rec: any) {
                                    if (rec) {
                                        getBalanceTokenStake()
                                        setLoadingUnstake(false)
                                        getTokenYourStakedBySC()
                                        getTotalStaked()
                                        getblockStaking()
                                        getFeePeriod()
                                        notiSuccess('Unstaking successfully', 3)
                                        clearInterval(interval);
                                    }
                                    if (err) {
                                        console.log("err", err);
                                        setLoadingUnstake(false)
                                        notiError('Error Staking', 3)
                                    }
                                }
                            )
                        }, 1000);

                    })
                    .catch((errUnstake: any) => {
                        console.log('errUnstake', errUnstake)
                        notiError(errUnstake, 4)
                        setLoadingUnstake(false)
                    })
            } catch (error: any) {
                console.log('error', error)

                notiError(error?.message || error, 3)
                setLoadingUnstake(false)
            }
        }
        // unstake-----------------------------------------------------------

        // claim------------------------------------------------
        const [loadingClaim, setLoadingClaim]: any = useState(false);
        const handleClaim = async () => {
            try {
                setLoadingClaim(true)
                if (amountEarn === 0) {
                    notiError('Your claim amount is zero', 4)
                    setLoadingClaim(false)
                    return
                }
                const gasPrice = await web3.eth.getGasPrice();
                const gasEstimate = await stakeContract.methods
                    .withdrawNew(
                        true
                    )
                    .estimateGas({
                        from: wallet,
                        // value: valueStakeConverted,
                        gasPrice: web3.utils.toHex(String(gasPrice)),
                    });
                await stakeContract.methods.withdrawNew(true)
                    .send({
                        from: wallet,
                        gas: gasEstimate,
                        gasPrice: web3.utils.toHex(String(gasPrice)),
                    })
                    .then(async (resClaim: any) => {
                        console.log('resClaim', resClaim)
                        let data = {
                            ownerAddress: wallet,
                            poolContract: item?.contractAddress,
                            txHash: resClaim?.transactionHash
                        }
                        await actions.setData(data)
                        const interval = setInterval(function () {
                            web3.eth.getTransactionReceipt(
                                resClaim?.transactionHash,
                                function (err: any, rec: any) {
                                    if (rec) {
                                        getAmountEarn()
                                        // getBalanceTokenStake()
                                        setLoadingClaim(false)
                                        // getTokenYourStakedBySC()
                                        getTotalStaked()
                                        notiSuccess('Claim successfully', 3)
                                        clearInterval(interval);
                                    }
                                    if (err) {
                                        console.log("err", err);
                                        setLoadingClaim(false)
                                        notiError('Error Staking', 3)
                                    }
                                }
                            )
                        }, 1000);

                    })
                    .catch((errClaim: any) => {
                        console.log('errClaim', errClaim)
                        notiError(errClaim?.message, 4)
                        setLoadingClaim(false)
                    })
            } catch (error: any) {
                console.log('error', error)
                notiError(error?.message || error, 3)
                setLoadingClaim(false)
            }
        }
        // getinfor
        const [blockStaking, setBlockStaking]: any = useState();
        const [feePeriod, setFeePeriod]: any = useState();

        const getblockStaking = async () => {
            try {
                await stakeContract.methods.userInfo(wallet)
                    .call()
                    .then((resInfo: any) => {
                        // console.log('lastStakingBlock--', resInfo?.lastStakingBlock)

                        setBlockStaking(resInfo?.lastStakingBlock)
                    })
                    .catch((errInfo: any) => {
                        console.log('errInfo', errInfo)
                    })
            } catch (error) {
                console.log('error', error)
            }
        }
        const getFeePeriod = async () => {
            try {
                await stakeContract.methods.feePeriod()
                    .call()
                    .then((resfeePeriod: any) => {
                        setFeePeriod(resfeePeriod)
                    })
                    .catch((errInfo: any) => {
                        console.log('errInfo', errInfo)
                    })
            } catch (error) {
                console.log('error', error)
            }
        }



        const [dailyReward, setDailyReward] = useState(0);
        const getDailyReward = async () => {
            try {
                await stakeContract.methods.rewardPerBlock(item?.stakingToken?.address)
                    .call()
                    .then((res: any) => {
                        // console.log('res getDailyReward', res)
                        setDailyReward(Number(res) / (1 * 10 ** item?.stakingToken?.decimals))
                    })
                    .catch((err: any) => {
                        console.log('err', err)
                    })
            } catch (error) {
                console.log('error', error)
            }
        }

        useEffect(() => {
            if (wallet && stakeContract) {
                getblockStaking()
                getDailyReward()
            }

        }, [wallet, item])
        useEffect(() => {
            if (stakeContract) {
                getFeePeriod()
            }

        }, [stakeContract])

        const handleMaxStake = () => {
            if (balanceTokenStake > 0.01) {
                setValueStake(balanceTokenStake - 0.01)
            } else {
                setValueStake(0)
            }

        }

        let timePeriod = Number(feePeriod) * 2.071282530
        let unstakeBlock = Number(blockStaking)


        const getTimeUnstake = async () => {
            try {
                let data = {
                    jsonrpc: "2.0",
                    id: 0, method: 'eth_getBlockByNumber',
                    params: [`0x${unstakeBlock.toString(16)}`, true]
                }
                await actions.getTimeUnstake(data).then((res: any) => {
                    setTimeUnstake(Number(res) || 0)
                })
                    .catch((err: any) => {
                        console.log('err timeunstake', err)
                    })
            } catch (error) {
                console.log('error', error)
            }
        }

        useEffect(() => {
            if (unstakeBlock) {
                getTimeUnstake()
            }
        }, [unstakeBlock, blockStaking, feePeriod])


        const [blockStart, setBlockStart] = useState(0);
        const [blockEnds, setBlockEnds] = useState(0);

        const getStartBlock = async () => {
            try {
                await stakeContract.methods.stakingBlock()
                    .call()
                    .then((res: any) => {
                        // console.log('stakingBlock---', res)
                        setBlockStart(res)
                    })
                    .catch((err: any) => {
                        console.log('err', err)
                    })
            } catch (error) {
                console.log('error', error)
            }
        }

        const getEndBlock = async () => {
            try {
                await stakeContract.methods.stakingEndBlock()
                    .call()
                    .then((res: any) => {
                        // console.log('stakingEndBlock---', res)
                        setBlockEnds(res)
                    })
                    .catch((err: any) => {
                        console.log('err', err)
                    })
            } catch (error) {
                console.log('error', error)
            }
        }

        useEffect(() => {
            if (stakeContract) {
                getStartBlock()
                getEndBlock()
            }

        }, [stakeContract])

        let timeStartPool = blockStart * 2.071282530 * 1000
        let timeNow = Date.now()
        //------------

        let apr = getPoolApr(Number(priceGec), Number(priceGec), amountTotalStaked, dailyReward * 1e18, 18)





        return (
            <>
                <div className="item-pool">

                    {wallet ? (
                        <>
                            <div className="top-item">
                                <div className="top-item-left">
                                    <div className="logo-stake">
                                        <img
                                            src={item?.stakingToken?.logoUrl}
                                            alt="" />
                                        <span>Stake {item?.stakingToken?.symbol}</span>
                                    </div>
                                    {isMobile ? ('') : (
                                        <div className="count-down-stake">
                                            {item?.isFinished ? (<span>Finished</span>) : (
                                                <>
                                                    {timeNow > timeStartPool ? (
                                                        <>
                                                            <span>Ends in </span>
                                                            {blockEnds ? (
                                                                <Countdown
                                                                    date={(((blockEnds - 42080943) * 2) + 1708774614) * 1000
                                                                    }
                                                                    renderer={rendererCountDown}
                                                                />
                                                            ) : '--'}

                                                        </>

                                                    ) : (
                                                        <>
                                                            <span>Starts in </span>
                                                            <Countdown
                                                                date={timeStartPool}
                                                                renderer={rendererCountDown}
                                                            />
                                                        </>

                                                    )}
                                                </>
                                            )}

                                        </div>
                                    )}

                                </div>
                                {/* <div className="day-lock">
                                    <img src="./images/launchpool/clock1.png" alt="" />
                                    {item?.isFinished ? (
                                        <span>7 days lock</span>
                                    ) : (
                                        <span>3 days lock</span>
                                    )}
                                </div> */}

                            </div>
                            {isMobile ? (
                                <div className="count-down-stake mo">
                                    {item?.isFinished ? (<span>Finished</span>) : (
                                        <>
                                            {timeNow > timeStartPool ? (
                                                <>
                                                    <span>Ends in </span>
                                                    {blockEnds ? (
                                                        <Countdown
                                                            date={(((blockEnds - 42080943) * 2) + 1708774614) * 1000
                                                            }
                                                            renderer={rendererCountDown}
                                                        />
                                                    ) : '--'}
                                                </>

                                            ) : (
                                                <>
                                                    <span>Starts in </span>
                                                    <Countdown
                                                        date={timeStartPool}
                                                        renderer={rendererCountDown}
                                                    />
                                                </>

                                            )}
                                        </>
                                    )}

                                </div>
                            ) : (
                                ''
                            )}
                            <div className="apr">
                                <div className="left-apr">APR:</div>
                                <div className="right-apr">
                                    <span className='apr-num'>{apr && apr > 0 ? formatNumber(apr, 3) : '--'} %</span>
                                </div>
                            </div>

                            <div className="apr">
                                <div className="left-apr">Earn in:</div>
                                <div className="right-apr">
                                    <img
                                        src={item?.earningToken?.logoUrl}
                                        alt="" />
                                    <span className='earn-token'>{item?.earningToken?.symbol}</span>
                                </div>
                            </div>
                            <div className="add-meta" onClick={() =>
                                addTokenToWallet({
                                    image: item?.earningToken?.logoUrl,
                                    address: item?.earningToken?.address,
                                    symbol: item?.earningToken?.symbol,
                                    decimals: item?.earningToken?.decimals,
                                })
                            }>
                                <img src="./images/launchpool/meta.png" alt="" /> Add to MetaMask
                            </div>


                            <div className="row-claim">
                                <div className="row-claim-left">
                                    <div className="text-claim">
                                        <span className="txt-red">{item?.earningToken?.symbol}</span>
                                        &nbsp; earned
                                    </div>
                                    <div className="num-claim">
                                        {formatNumber(amountEarn, 2)}  (${formatNumber((priceGec * amountEarn), 4)})
                                    </div>
                                </div>
                                <div className="button-claim">
                                    {amountEarn > 0 ? (
                                        <>
                                            {loadingClaim ? (
                                                <button className="btn-lp btn-claim dis">
                                                    Waiting <Spin indicator={antIcon} />
                                                </button>
                                            ) : (
                                                <button className="btn-lp btn-claim" onClick={handleClaim}>
                                                    Claim
                                                </button>
                                            )}
                                        </>

                                    ) : (
                                        <button className="btn-lp btn-claim dis">Claim</button>
                                    )}

                                </div>
                            </div>
                            <div className="row-claim">
                                <div className="row-claim-left">
                                    <div className="text-claim">
                                        <span className="txt-red">{item?.stakingToken?.symbol}</span>
                                        &nbsp; staked
                                    </div>
                                    <div className="num-claim">
                                        {formatNumber(amountTokenYourStaked, 2)}  (${formatNumber((priceGec * amountTokenYourStaked), 4)})
                                    </div>
                                </div>
                                <div className="button-claim">
                                    {item?.isFinished ? ('') : (
                                        <button className="btn-lp btn-claim" onClick={showModalStake}>
                                            Stake
                                        </button>
                                    )}

                                    {
                                        <>
                                            {loadingUnstake ? (
                                                <button className="btn-lp btn-unstake">
                                                    <Spin indicator={antIcon} />
                                                </button>
                                            ) : (
                                                <button className="btn-lp btn-unstake" onClick={handleUnstaking}>
                                                    -
                                                </button>
                                            )}
                                        </>
                                    }

                                </div>
                            </div>


                            {/* <div className="btn-enable">
                        <button className='button-enable'>Enable Contract</button>
                    </div> */}

                            <div className="box-info">
                                <div className="item-info">
                                    <div className="item-info-left">Total Staked</div>
                                    <div className="item-info-right">{formatNumber(amountTotalStaked, 2)} {item?.stakingToken?.symbol}
                                        <span >
                                            (${formatNumber((priceGec * amountTotalStaked), 2)})
                                        </span>
                                    </div>
                                </div>
                                <div className="item-info">
                                    <div className="item-info-left">Daily rewards</div>
                                    <div className="item-info-right">{dailyReward > 0 ? formatNumber(dailyReward, 2) : '--'} {item?.stakingToken?.symbol}</div>
                                </div>

                                {item?.isFinished ? ('') : (
                                    <div className="item-info">
                                        <div className="item-info-left">Unstaking fee period &nbsp;
                                            <Tooltip className='lp-tooltip' title={text}>
                                                <img src="./images/launchpool/help.png" alt="" />
                                            </Tooltip>

                                        </div>
                                        <div className="item-info-right">

                                            <div className="right-countdown">
                                                {timeUnstake !== 0 && amountTokenYourStaked > 0 ? (
                                                    <Countdown
                                                        date={(timeUnstake + timePeriod) * 1000}
                                                        renderer={rendererCountDown}
                                                    />
                                                ) : '--'}

                                            </div>

                                        </div>
                                    </div>
                                )}


                                <div className="view-pool" onClick={() => viewPool(item?.contractAddress, item?.chain)}>View pool contract <img src="./images/launchpool/open.png" alt="" /></div>
                                <div className="view-pool" onClick={() => viewSwap(item?.stakingToken?.address, item?.chain)}>Buy {item?.stakingToken?.symbol} Token <img src="./images/launchpool/open.png" alt="" /></div>
                            </div>
                        </>
                    ) : (
                        <div className="btc-conn">
                            <WagmiConnect />
                        </div>
                    )}


                </div>

                <Modal footer={false} className='modal-staking' title={titleModal} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                    <div className="content-modal-staking">
                        <div className="title-input-modal">
                            <div className="modal-left">Stake</div>
                            <div className="modal-right">{formatNumber(balanceTokenStake, 2)} {item?.stakingToken?.symbol} available</div>
                        </div>
                        <div className="box-input">
                            <input
                                onChange={handleChangeInput}
                                placeholder="0"
                                value={valueStake}
                            />
                            <div className="box-input-right">
                                <div className="img-input">
                                    <img src="./images/launchpool/gec.png" alt="" />
                                </div>
                                <div className="text-input">{item?.stakingToken?.symbol}</div>
                                <div className="btn-input-max" onClick={handleMaxStake}>
                                    <button>Max</button>
                                </div>
                            </div>
                        </div>
                        <div className="box-action">
                            <button className='box-btn btn-cancel' onClick={handleCancel}>Cancel</button>
                            {valueStake === 0 || !valueStake ? (
                                <>
                                    {loadingStake ? (
                                        <button className='box-btn btn-st dis'>
                                            Waiting <Spin indicator={antIcon} />
                                        </button>
                                    ) : (
                                        <button className='box-btn btn-st' onClick={handleStaking}>
                                            Stake
                                        </button>
                                    )}
                                </>
                            ) : (
                                <>
                                    {!isApprove ? (
                                        <>
                                            {loadingApprove ? (
                                                <button className='box-btn btn-st dis'>
                                                    Waiting <Spin indicator={antIcon} />
                                                </button>
                                            ) : (
                                                <button className='box-btn btn-st' onClick={handleApprove}>
                                                    Approve
                                                </button>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {loadingStake ? (
                                                <button className='box-btn btn-st dis'>
                                                    Waiting <Spin indicator={antIcon} />
                                                </button>
                                            ) : (
                                                <button className='box-btn btn-st' onClick={handleStaking}>
                                                    Stake
                                                </button>
                                            )}
                                        </>
                                    )}
                                </>
                            )}


                        </div>
                        <div className="modal-stake-link" onClick={() => viewSwap(item?.stakingToken?.address, item?.chain)}>
                            Buy {item?.stakingToken?.symbol} Token
                            <img src="./images/launchpool/open.png" alt="" />
                        </div>
                    </div>
                </Modal>


            </>
        )
    }


    const headerColl = (item: any, amountEarn: any, amountTotalStaked: any, priceGec: any, blockEnds: any, timeStartPool: any, apr: any) => {
        let timeNow = Date.now()
        // console.log('blockEnds', blockEnds)
        return (
            <div className='header-collapse'>
                <div className="header-col1">
                    <div className="col1-logo">
                        <img className="col1-logo1" src={item?.stakingToken?.logoUrl} alt="" />
                        <img className="col1-logo2" src={item?.earningToken?.logoUrl} alt="" />
                    </div>
                    <div className="col1-text">
                        <div className="col1-earn ">Earn {item?.earningToken?.symbol}</div>
                        <div className="col1-stake ">Stake {item?.stakingToken?.symbol}</div>

                        {/* {item?.isFinished ? (
                            <div className="col1-lock">7 days lock</div>
                        ) : (
                            <div className="col1-lock">3 days lock</div>
                        )} */}

                    </div>
                </div>
                <div className="header-col2">
                    <div className="col2-top">{item?.earningToken?.symbol} earned</div>
                    <div className="col2-bot">{formatNumber(amountEarn, 2)} (${formatNumber((priceGec * amountEarn), 4)})</div>
                </div>
                <div className="header-col2">
                    <div className="col2-top">APR</div>
                    <div className="col2-bot txt-while">{apr && apr > 0 ? formatNumber(apr, 3) : '--'}%
                        {/* <img src="./images/launchpool/apr.png" alt="" /> */}
                    </div>
                </div>
                <div className="header-col2">
                    <div className="col2-top">Total Staked</div>
                    <div className="col2-bot">
                        <span className='txt-while'>{formatNumber(amountTotalStaked, 2)} {item?.stakingToken?.symbol}</span> <br /> (${formatNumber((priceGec * amountTotalStaked), 2)})
                    </div>
                </div>
                <div className="header-col2">
                    {item?.isFinished ? (
                        <div className="col2-top">Finished</div>
                    ) : (
                        <>
                            <div className="col2-top">Ends in</div>
                            <div className="col2-bot">
                                <span className='txt-while'>
                                    {timeNow > timeStartPool ? (
                                        <>

                                            {blockEnds ? (
                                                <Countdown
                                                    date={(((blockEnds - 42080943) * 2) + 1708774614) * 1000
                                                    }
                                                    renderer={rendererCountDown}
                                                />
                                            ) : '--'}

                                        </>

                                    ) : (
                                        <>
                                            <span>Starts in </span>
                                            <Countdown
                                                date={timeStartPool}
                                                renderer={rendererCountDown}
                                            />
                                        </>

                                    )}

                                </span>
                            </div>
                        </>
                    )}

                </div>
                <div className="header-col-end">
                    <div className="col-end">Detail</div>
                </div>
            </div>
        )
    }

    const ItemView2 = (data: any) => {
        let item = data.item
        const [balanceTokenStake, setBalanceTokenStake]: any = useState(0);
        const [balanceMainToken, setBalanceMainToken]: any = useState(0);

        const [amountApprove, setAmountApprove]: any = useState(0);

        const [loadingStake, setLoadingStake]: any = useState(false);
        const [loadingApprove, setLoadingApprove]: any = useState(false);
        const [isApprove, setIsApprove]: any = useState(false);

        const [valueStake, setValueStake]: any = useState(0);
        const [amountTokenYourStaked, setAmountTokenYourStaked]: any = useState(0);
        const [amountTotalStaked, setAmountTotalStaked]: any = useState(0);
        const [amountEarn, setAmountEarn]: any = useState(0);

        const [isModalOpen, setIsModalOpen] = useState(false);

        const [timeUnstake, setTimeUnstake] = useState(0);


        const showModal = () => {
            setIsModalOpen(true);
        };

        const handleOk = () => {
            setIsModalOpen(false);
        };

        const handleCancel = () => {
            setIsModalOpen(false);
        };
        const showModalStake = () => {
            showModal()
        }
        const handleMaxStake = () => {
            if (balanceTokenStake > 0.01) {
                setValueStake(balanceTokenStake - 0.01)
            } else {
                setValueStake(0)
            }

        }



        useEffect(() => {
            if (amountApprove === 0 || amountApprove < valueStake) {
                setIsApprove(false)
            } else {
                setIsApprove(true)
            }
        }, [amountApprove, valueStake, isApprove])

        const stakeContract = new web3.eth.Contract(
            abiStakingPool as unknown as AbiItem,
            item?.contractAddress
        );

        const contractTokenStake = new web3.eth.Contract(
            abiTokenDefault as unknown as AbiItem,
            item?.stakingToken?.address
        );

        const getBalanceTokenStake = async () => {
            try {
                let balanMainToken = await web3.eth.getBalance(wallet);
                setBalanceMainToken(Number(balanMainToken) / 1e18)
                await contractTokenStake.methods
                    .balanceOf(wallet)
                    .call()
                    .then((res: any) => {
                        setBalanceTokenStake(Number(res) / (1 * 10 ** item?.stakingToken?.decimals))
                    })
                    .catch((err: any) => {
                        setBalanceTokenStake(0)
                        console.log("err---", err);
                    });
            } catch (error) {

            }
        }

        const getTokenYourStakedBySC = async () => {
            try {
                await stakeContract.methods.balanceOf(wallet)
                    .call()
                    .then((res: any) => {
                        // console.log('res', res)
                        setAmountTokenYourStaked(Number(res) / (1 * 10 ** item?.stakingToken?.decimals))
                    })
                    .catch((err: any) => {
                        setAmountTokenYourStaked(0)
                        console.log('err', err)
                    })
            } catch (error) {
                console.log('error', error)
            }
        }
        const getTotalStaked = async () => {
            try {
                await stakeContract.methods.totalStaked()
                    .call()
                    .then((res: any) => {
                        // console.log('res------', res)
                        setAmountTotalStaked(Number(res) / (1 * 10 ** item?.stakingToken?.decimals))
                    })
                    .catch((err: any) => {
                        console.log('err-------', err)
                    })
            } catch (error) {
                console.log('error-----', error)
            }
        }

        const getAmountEarn = async () => {
            try {
                await stakeContract.methods.pendingRewardByToken(wallet, item?.earningToken?.address)
                    .call()
                    .then((res: any) => {
                        // console.log('res earningToken------', res)
                        setAmountEarn(Number(res) / (1 * 10 ** item?.earningToken?.decimals))
                    })
                    .catch((err: any) => {
                        console.log('err-------', err)
                    })
            } catch (error) {
                console.log('error-----', error)
            }
        }

        useEffect(() => {
            if (wallet && stakeContract) {
                getTokenYourStakedBySC()
                getAmountEarn()
            }
        }, [wallet, stakeContract])

        useEffect(() => {
            if (stakeContract) {
                getTotalStaked()
            }

        }, [stakeContract])

        useEffect(() => {
            if (wallet && item?.contractAddress) {
                getBalanceTokenStake()
            }
        }, [wallet, item?.contractAddress])

        useEffect(() => {
            if (wallet && item?.contractAddress) {
                getAmountApprove()
            }
        }, [wallet, item?.contractAddress, isApprove])

        const handleChangeInput = (e: any) => {
            let x = e.target.value.replace(/[^0-9.]/g, "");
            setValueStake(x)
        }

        const getAmountApprove = async () => {
            try {
                await contractTokenStake.methods.allowance(wallet, item?.contractAddress)
                    .call()
                    .then((resAmountApprove: any) => {
                        // console.log('resAmountApprove', resAmountApprove)
                        setAmountApprove(Number(resAmountApprove) / (1 * 10 ** item?.stakingToken?.decimals))
                    })
                    .catch((errAmountApprove: any) => {
                        console.log('errAmountApprove', errAmountApprove)
                    })
            } catch (error) {
                console.log('error', error)
            }
        }

        const handleApprove = async () => {
            try {
                setLoadingApprove(true)
                const gasPrice = await web3.eth.getGasPrice();
                await contractTokenStake.methods.approve(
                    item?.contractAddress,
                    UINT256_MAX
                )
                    .send({
                        from: wallet,
                        gasPrice: web3.utils.toHex(String(gasPrice))
                    })
                    .then((resApprove: any) => {
                        console.log('resApprove', resApprove)
                        const interval = setInterval(function () {
                            web3.eth.getTransactionReceipt(
                                resApprove?.transactionHash,
                                function (err: any, rec: any) {
                                    if (rec) {
                                        setLoadingApprove(false)
                                        setIsApprove(true)
                                        notiSuccess('Approve successfully', 3)
                                        clearInterval(interval);
                                    }
                                    if (err) {
                                        console.log("err", err);
                                        setLoadingApprove(false)
                                        notiError('Error approve', 3)
                                    }
                                }
                            )
                        }, 1000);
                    })
                    .catch((errAprove: any) => {
                        console.log('errAprove', errAprove)
                        notiError(errAprove?.message || 'Unknown error occurred', 4)
                        setLoadingApprove(false)
                    })
            } catch (error: any) {
                console.log('error', error)
                setLoadingApprove(false)
            }
        }

        const handleStaking = async () => {
            try {
                setLoadingStake(true)
                if (chain !== workChain) {
                    notiError('Wrong network.Pleasr select Avax Chain your wallet', 4)
                    setLoadingStake(false)
                    return
                }
                if (valueStake === 0) {
                    notiError('Amount must be greater than zero', 4)
                    setLoadingStake(false)
                    return
                }
                let valueStakeConverted = convertToWei(valueStake, 18)
                const gasPrice = await web3.eth.getGasPrice();
                const gasEstimate = await stakeContract.methods
                    .deposit(
                        valueStakeConverted
                    )
                    .estimateGas({
                        from: wallet,
                        // value: valueStakeConverted,
                        gasPrice: web3.utils.toHex(String(gasPrice)),
                    });

                await stakeContract.methods.deposit(valueStakeConverted)
                    .send({
                        from: wallet,
                        gas: gasEstimate,
                        gasPrice: web3.utils.toHex(String(gasPrice)),
                    })
                    .then(async (resStake: any) => {
                        console.log('resStake', resStake)
                        let data = {
                            ownerAddress: wallet,
                            poolContract: item?.contractAddress,
                            txHash: resStake?.transactionHash
                        }
                        await actions.setData(data)
                        const interval = setInterval(function () {
                            web3.eth.getTransactionReceipt(
                                resStake?.transactionHash,
                                function (err: any, rec: any) {
                                    if (rec) {
                                        getBalanceTokenStake()
                                        setLoadingStake(false)
                                        getTokenYourStakedBySC()
                                        getTotalStaked()
                                        getblockStaking()
                                        getFeePeriod()
                                        notiSuccess('Staking successfully', 3)
                                        clearInterval(interval);
                                    }
                                    if (err) {
                                        console.log("err", err);
                                        setLoadingStake(false)
                                        notiError('Error Staking', 3)
                                    }
                                }
                            )
                        }, 1000);
                    })
                    .catch((errStake: any) => {
                        console.log('errStake', errStake)
                        notiError(errStake?.message || errStake, 3)
                        setLoadingStake(false)
                    })

            } catch (error: any) {
                console.log('error', error?.message)
                notiError(error?.message || 'Unknown error occurred', 4)
                setLoadingStake(false)
            }
        }

        // unstake-----------------------------------------------------------
        const [loadingUnstake, setLoadingUnstake]: any = useState(false);

        const handleUnstaking = async () => {
            try {
                setLoadingUnstake(true)
                if (amountTokenYourStaked === 0) {
                    notiError('Your staked amount is zero', 4)
                    setLoadingUnstake(false)
                    return
                }
                const gasPrice = await web3.eth.getGasPrice();
                const gasEstimate = await stakeContract.methods
                    .withdrawNew(
                        false
                    )
                    .estimateGas({
                        from: wallet,
                        // value: valueStakeConverted,
                        gasPrice: web3.utils.toHex(String(gasPrice)),
                    });
                await stakeContract.methods.withdrawNew(false)
                    .send({
                        from: wallet,
                        gas: gasEstimate,
                        gasPrice: web3.utils.toHex(String(gasPrice)),
                    })
                    .then(async (resUnstake: any) => {
                        console.log('resUnstake', resUnstake)
                        let data = {
                            ownerAddress: wallet,
                            poolContract: item?.contractAddress,
                            txHash: resUnstake?.transactionHash
                        }
                        await actions.setData(data)
                        const interval = setInterval(function () {
                            web3.eth.getTransactionReceipt(
                                resUnstake?.transactionHash,
                                function (err: any, rec: any) {
                                    if (rec) {
                                        getBalanceTokenStake()
                                        setLoadingUnstake(false)
                                        getTokenYourStakedBySC()
                                        getblockStaking()
                                        getFeePeriod()
                                        getTotalStaked()
                                        notiSuccess('Unstaking successfully', 3)
                                        clearInterval(interval);
                                    }
                                    if (err) {
                                        console.log("err", err);
                                        setLoadingUnstake(false)
                                        notiError('Error Staking', 3)
                                    }
                                }
                            )
                        }, 1000);

                    })
                    .catch((errUnstake: any) => {
                        console.log('errUnstake', errUnstake)
                        notiError(errUnstake, 4)
                        setLoadingUnstake(false)
                    })
            } catch (error: any) {
                console.log('error', error)
                notiError(error?.message || error, 3)
                setLoadingUnstake(false)
            }
        }
        // unstake-----------------------------------------------------------

        // claim------------------------------------------------
        const [loadingClaim, setLoadingClaim]: any = useState(false);
        const handleClaim = async () => {
            try {
                setLoadingClaim(true)
                if (amountEarn === 0) {
                    notiError('Your claim amount is zero', 4)
                    setLoadingClaim(false)
                    return
                }
                const gasPrice = await web3.eth.getGasPrice();
                const gasEstimate = await stakeContract.methods
                    .withdrawNew(
                        true
                    )
                    .estimateGas({
                        from: wallet,
                        // value: valueStakeConverted,
                        gasPrice: web3.utils.toHex(String(gasPrice)),
                    });
                await stakeContract.methods.withdrawNew(true)
                    .send({
                        from: wallet,
                        gas: gasEstimate,
                        gasPrice: web3.utils.toHex(String(gasPrice)),
                    })
                    .then(async (resClaim: any) => {
                        console.log('resClaim', resClaim)
                        let data = {
                            ownerAddress: wallet,
                            poolContract: item?.contractAddress,
                            txHash: resClaim?.transactionHash
                        }
                        await actions.setData(data)
                        const interval = setInterval(function () {
                            web3.eth.getTransactionReceipt(
                                resClaim?.transactionHash,
                                function (err: any, rec: any) {
                                    if (rec) {
                                        getAmountEarn()
                                        // getBalanceTokenStake()
                                        setLoadingClaim(false)
                                        // getTokenYourStakedBySC()
                                        getTotalStaked()
                                        notiSuccess('Claim successfully', 3)
                                        clearInterval(interval);
                                    }
                                    if (err) {
                                        console.log("err", err);
                                        setLoadingClaim(false)
                                        notiError('Error Staking', 3)
                                    }
                                }
                            )
                        }, 1000);

                    })
                    .catch((errClaim: any) => {
                        console.log('errClaim', errClaim)
                        notiError(errClaim?.message, 4)
                        setLoadingClaim(false)
                    })
            } catch (error: any) {
                console.log('error', error)
                notiError(error?.message || error, 3)
                setLoadingClaim(false)
            }
        }
        // getinfor
        const [blockStaking, setBlockStaking]: any = useState();
        const [feePeriod, setFeePeriod]: any = useState();

        const getblockStaking = async () => {
            try {
                await stakeContract.methods.userInfo(wallet)
                    .call()
                    .then((resInfo: any) => {
                        // console.log('resInfo', resInfo)
                        setBlockStaking(resInfo?.lastStakingBlock)
                    })
                    .catch((errInfo: any) => {
                        console.log('errInfo', errInfo)
                    })
            } catch (error) {
                console.log('error', error)
            }
        }
        const getFeePeriod = async () => {
            try {
                await stakeContract.methods.feePeriod()
                    .call()
                    .then((resfeePeriod: any) => {
                        // console.log('res feePeriod--', resfeePeriod)
                        setFeePeriod(resfeePeriod)
                    })
                    .catch((errInfo: any) => {
                        console.log('errInfo', errInfo)
                    })
            } catch (error) {
                console.log('error', error)
            }
        }

        const [dailyReward, setDailyReward] = useState(0);
        const getDailyReward = async () => {
            try {
                await stakeContract.methods.rewardPerBlock(item?.stakingToken?.address)
                    .call()
                    .then((res: any) => {
                        console.log('res getDailyReward', res)
                        setDailyReward(Number(res) / (1 * 10 ** item?.stakingToken?.decimals))
                    })
                    .catch((err: any) => {
                        console.log('err', err)
                    })
            } catch (error) {
                console.log('error', error)
            }
        }

        useEffect(() => {
            if (wallet && stakeContract) {
                getblockStaking()
                getDailyReward()
            }

        }, [wallet, item])
        useEffect(() => {
            if (stakeContract) {
                getFeePeriod()
            }

        }, [stakeContract])



        let timePeriod = Number(feePeriod) * 2.071282530
        let unstakeBlock = Number(blockStaking)

        const getTimeUnstake = async () => {
            try {
                let data = {
                    jsonrpc: "2.0",
                    id: 0, method: 'eth_getBlockByNumber',
                    params: [`0x${unstakeBlock.toString(16)}`, true]
                }
                await actions.getTimeUnstake(data).then((res: any) => {
                    setTimeUnstake(Number(res) || 0)
                })
                    .catch((err: any) => {
                        console.log('err timeunstake', err)
                    })
            } catch (error) {
                console.log('error', error)
            }
        }

        useEffect(() => {
            if (unstakeBlock) {
                getTimeUnstake()
            }

        }, [unstakeBlock, blockStaking, feePeriod])

        const [blockStart, setBlockStart] = useState(0);
        const [blockEnds, setBlockEnds] = useState(0);

        const getStartBlock = async () => {
            try {
                await stakeContract.methods.stakingBlock()
                    .call()
                    .then((res: any) => {
                        // console.log('stakingBlock---', res)
                        setBlockStart(res)
                    })
                    .catch((err: any) => {
                        console.log('err', err)
                    })
            } catch (error) {
                console.log('error', error)
            }
        }

        const getEndBlock = async () => {
            try {
                await stakeContract.methods.stakingEndBlock()
                    .call()
                    .then((res: any) => {
                        console.log('stakingEndBlock---', res)
                        setBlockEnds(res)
                    })
                    .catch((err: any) => {
                        console.log('err', err)
                    })
            } catch (error) {
                console.log('error', error)
            }
        }

        useEffect(() => {
            if (stakeContract) {
                getStartBlock()
                getEndBlock()
            }

        }, [stakeContract])

        let timeStartPool = blockStart * 2.071282530 * 1000

        let apr = getPoolApr(Number(priceGec), Number(priceGec), amountTotalStaked, dailyReward * 1e18, 18)


        return (
            <>
                <Collapse className='gird-lp' expandIconPosition={'end'}>
                    {wallet ? (
                        <>
                            <Panel header={headerColl(item, amountEarn, amountTotalStaked, priceGec, blockEnds, timeStartPool, apr)} key="1">
                                <div className="collap-content">
                                    <div className="content-left">
                                        <div className="content-left1">
                                            <div className="left1-text">Daily rewards</div>
                                            <div className="left1-num">{dailyReward > 0 ? formatNumber(dailyReward, 2) : '--'} {item?.earningToken?.symbol}</div>
                                        </div>

                                        <div className="content-left2">
                                            <div className="left1-text">Unstaking fee period</div>
                                            <div className="left1-num">

                                                <div className="right-countdown">
                                                    {timeUnstake !== 0 && amountTokenYourStaked > 0 ? (
                                                        <Countdown
                                                            date={(timeUnstake + timePeriod) * 1000}
                                                            renderer={rendererCountDown}
                                                        />
                                                    ) : '--'}

                                                </div>

                                            </div>
                                        </div>

                                        <div className="view-pool1" onClick={() => viewPool(item?.contractAddress, item?.chain)}>View pool contract <img src="./images/launchpool/open.png" alt="" /></div>
                                        <div className="view-pool1" onClick={() => viewSwap(item?.stakingToken?.address, item?.chain)}>Buy {item?.stakingToken?.symbol} Token <img src="./images/launchpool/open.png" alt="" /></div>
                                    </div>
                                    <div className="box-right">
                                        <div className="item-earn ">
                                            <div className="item-box-left">
                                                <div className="item-box-text1"><span className='txt-red'>{item?.earningToken?.symbol}</span> earned</div>
                                                <div className="item-box-num">{formatNumber(amountEarn, 2)}</div>
                                                <div className="item-box-price">(${formatNumber((priceGec * amountEarn), 4)})</div>

                                            </div>
                                            <div className="item-box-right">
                                                <div className="add"
                                                    onClick={() =>
                                                        addTokenToWallet({
                                                            image: item?.earningToken?.logoUrl,
                                                            address: item?.earningToken?.address,
                                                            symbol: item?.earningToken?.symbol,
                                                            decimals: item?.earningToken?.decimals,
                                                        })
                                                    }
                                                >
                                                    <img src="./images/launchpool/meta.png" alt="" /> Add to MetaMask
                                                </div>
                                                <div className="btn-action">

                                                    {amountEarn > 0 ? (
                                                        <>
                                                            {loadingClaim ? (
                                                                <button className="btn-lp btn-claim dis">
                                                                    Waiting <Spin indicator={antIcon} />
                                                                </button>
                                                            ) : (
                                                                <button className="btn-lp btn-claim" onClick={handleClaim}>
                                                                    Claim
                                                                </button>
                                                            )}
                                                        </>

                                                    ) : (
                                                        <button className="btn-lp btn-claim dis">Claim</button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item-stake">
                                            <div className="item-box-left">
                                                <div className="item-box-text1"><span className='txt-red'>{item?.stakingToken?.symbol}</span> staked</div>
                                                <div className="item-box-num">{formatNumber(amountTokenYourStaked, 2)}</div>
                                                <div className="item-box-price">(${formatNumber((priceGec * amountTokenYourStaked), 4)})</div>

                                            </div>
                                            <div className="item-box-right">

                                                <div className="btn-action">
                                                    <button className='button-claim1' onClick={showModalStake}>Stake</button>
                                                    {
                                                        <>
                                                            {loadingUnstake ? (
                                                                <button className="btn-lp btn-unstake">
                                                                    <Spin indicator={antIcon} />
                                                                </button>
                                                            ) : (
                                                                <button className="btn-lp btn-unstake" onClick={handleUnstaking}>
                                                                    -
                                                                </button>
                                                            )}
                                                        </>
                                                    }
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Panel>
                        </>
                    ) : (
                        <div className="btc-conn">
                            <WagmiConnect />
                        </div>
                    )}

                </Collapse>
                <Modal footer={false} className='modal-staking' title={titleModal} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                    <div className="content-modal-staking">
                        <div className="title-input-modal">
                            <div className="modal-left">Stake</div>
                            <div className="modal-right">{formatNumber(balanceTokenStake, 2)} {item?.stakingToken?.symbol} available</div>
                        </div>
                        <div className="box-input">
                            <input
                                onChange={handleChangeInput}
                                placeholder="0"
                                value={valueStake}
                            />
                            <div className="box-input-right">
                                <div className="img-input">
                                    <img src="./images/launchpool/gec.png" alt="" />
                                </div>
                                <div className="text-input">{item?.stakingToken?.symbol}</div>
                                <div className="btn-input-max">
                                    <button onClick={handleMaxStake}>Max</button>
                                </div>
                            </div>
                        </div>
                        <div className="box-action">
                            <button className='box-btn btn-cancel' onClick={handleCancel}>Cancel</button>
                            {valueStake === 0 || !valueStake ? (
                                <>
                                    {loadingStake ? (
                                        <button className='box-btn btn-st dis'>
                                            Waiting <Spin indicator={antIcon} />
                                        </button>
                                    ) : (
                                        <button className='box-btn btn-st' onClick={handleStaking}>
                                            Stake
                                        </button>
                                    )}
                                </>
                            ) : (
                                <>
                                    {!isApprove ? (
                                        <>
                                            {loadingApprove ? (
                                                <button className='box-btn btn-st dis'>
                                                    Waiting <Spin indicator={antIcon} />
                                                </button>
                                            ) : (
                                                <button className='box-btn btn-st' onClick={handleApprove}>
                                                    Approve
                                                </button>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {loadingStake ? (
                                                <button className='box-btn btn-st dis'>
                                                    Waiting <Spin indicator={antIcon} />
                                                </button>
                                            ) : (
                                                <button className='box-btn btn-st' onClick={handleStaking}>
                                                    Stake
                                                </button>
                                            )}
                                        </>
                                    )}
                                </>
                            )}


                        </div>
                        <div className="modal-stake-link" onClick={() => viewSwap(item?.stakingToken?.address, item?.chain)}>
                            Buy {item?.stakingToken?.symbol} Token
                            <img src="./images/launchpool/open.png" alt="" />
                        </div>
                    </div>
                </Modal>
            </>

        )
    }


    const [activeTab, setActiveTab]: any = useState(0);

    const handleClickLive = () => {
        setActiveTab(0)
    }
    const handleClickFinished = () => {
        setActiveTab(1)
    }

    return (
        <div style={{ overflowX: "hidden" }}>
            <div className="container-launch">
                <div className="container-img">
                    <div className='section-lp'>
                        <div className="launch-top">
                            <div className="top-lp-left">
                                <div className="title-launch">
                                    Gecko Inu Launchpool
                                </div>
                                <div className="gec-staking">
                                    <span className="txt-red">GEC</span> Staking
                                </div>
                                <div className="lp-decscription">
                                    Park your GEC tokens and watch 'em multiply <br />
                                    No sweat, no fees, all the gains!
                                </div>

                            </div>
                            <div className="top-lp-right">
                                <div className="total-lock">
                                    <div className="img-lock">
                                        <img src="./images/launchpool/lock.png" alt="" />
                                    </div>
                                    <div className="text-lock">Total Value Locked:</div>
                                    <div className="num-lock">${formatNumber((totalValueLock * priceGec), 4)}</div>
                                </div>
                                <div className="gec-price">
                                    <div className="img-lock">
                                        <img src="./images/launchpool/gec.png" alt="" />
                                    </div>
                                    <div className="text-lock">$GEC Price:</div>
                                    <div className="num-lock">${Number(priceGec).toFixed(10)}</div>
                                </div>
                            </div>
                        </div>
                        <div className="launch-mid">
                            <div className="select-view">
                                <div className="viewleft">
                                    <div className="view1" onClick={() => handleChoseView(1)}>
                                        {view === 1 ? (<img src="./images/launchpool/view1-red.png" alt="" />) : (
                                            <img src="./images/launchpool/view1.png" alt="" />
                                        )}

                                    </div>
                                    <div className="view2" onClick={() => handleChoseView(2)}>
                                        {view === 2 ? (<img src="./images/launchpool/view2-red.png" alt="" />) : (
                                            <img src="./images/launchpool/view2.png" alt="" />
                                        )}

                                    </div>
                                </div>


                                <div className="tab-launchpool">
                                    <div className="content-tab">
                                        <div className={`tab-live ${activeTab === 0 ? 'tab-active' : ''}`} onClick={handleClickLive}>Live</div>
                                        <div className={`tab-finished ${activeTab === 1 ? 'tab-active' : ''}`} onClick={handleClickFinished}>Finished</div>
                                    </div>
                                </div>

                                <div className="view3"
                                // onClick={() => handleChoseView(3)}
                                >
                                    <Switch onChange={onChange} /> <span>Staked only</span>
                                </div>
                            </div>

                            {activeTab === 0 ? (
                                <>
                                    {view === 1 ? (
                                        <>
                                            <div className="list-pool">
                                                {listPool && listPool
                                                    .filter((obj: any) => obj.isFinished === false)
                                                    .map((item: any, index: any) => (

                                                        <ItemView1 item={item} />

                                                    ))}
                                            </div>
                                        </>

                                    ) : (
                                        <div className="list-gird">
                                            {listPool && listPool
                                                .filter((obj: any) => obj.isFinished === false)
                                                .map((item: any, index: any) => (
                                                    <ItemView2 item={item} />
                                                ))}


                                        </div>
                                    )}
                                </>
                            ) : (
                                <>
                                    {activeTab === 1 ? (
                                        <>
                                            {view === 1 ? (
                                                <>
                                                    <div className="list-pool">
                                                        {listPool && listPool
                                                            .filter((obj: any) => obj.isFinished === true)
                                                            .map((item: any, index: any) => (

                                                                <ItemView1 item={item} />

                                                            ))}
                                                    </div>
                                                </>

                                            ) : (
                                                <div className="list-gird">
                                                    {listPool && listPool
                                                        .filter((obj: any) => obj.isFinished === true)
                                                        .map((item: any, index: any) => (
                                                            <ItemView2 item={item} />
                                                        ))}


                                                </div>
                                            )}
                                        </>
                                    ) : ('')}
                                </>
                            )}



                        </div>

                    </div>
                    {/* <div className="launch-footer">
                        <div className="logo-footer">
                            <img src="./images/launchpool/logo-f.png" alt="" />
                        </div>
                        <div className="dec-footer">
                            The Next Generation Of Launchpad in <span className='txt-red'>Avalanche</span>
                        </div>
                        <div className="social-footer">
                            <div className="tele-footer" onClick={gotoTele}>
                                <img src="./images/nft/tele-footer1.png" alt="" />
                            </div>
                            <div className="tw-footer" onClick={gotoTw}>
                                <img src="./images/nft/tw-footer1.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="copy-rights">©2024 Gecko Inu LLC. All Rights Reserved.</div> */}
                </div>
            </div>
        </div >
    )
}

export default Launchpool;