import {
  API_URL
} from "../constants/index";


export async function getListMyNFT(param) {
  try {
   
    const response = await fetch(`${API_URL}/nft/my_nft`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(param),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('error getListNFTMarket',error)
    throw error;
  }
}
export async function getListNFTMarket(param) {
  try {
   
    const response = await fetch(`${API_URL}/nft/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(param),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('error getListNFTMarket',error)
    throw error;
  }
}
export async function getListAttribute() {
  try {
   
    const response = await fetch(`${API_URL}/nft/collection`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('error getListNFTMarket',error)
    throw error;
  }
}
export async function getListReport() {
  try {
   
    const response = await fetch(`${API_URL}/nft/report`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('error getListNFTMarket',error)
    throw error;
  }
}

export async function getData(url) {
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        accept: "application/json",
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error getData:", error);
    throw error;
  }
}
export async function postData(url, body) {
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('error postData',error)
    throw error;
  }
}
export async function putData(url, body) {
  try {
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('error putData',error)
    throw error;
  }
}
export async function deleteData(url, body) {
  try {
    const response = await fetch(url, {
      method: "DELETE", 
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('error deleteData',error)
    throw error;
  }
}
