import Web3 from "web3";


declare const window: Window & typeof globalThis & { ethereum: any };

const web3 = new Web3(window.ethereum);

export const _mintToken = async (
  contract: any,
  account: any,
  tier: any,
  amount: any,
  tokenId: any,
  mintFee: any,
  sign: any,
) => {
  const args = [account,tier , amount, tokenId,sign];
  console.log('args',args)
  console.log('mintFee',mintFee)
  const gasPrice = await web3.eth.getGasPrice();
  return contract.safeMintMany(...args, {
    gasPrice: gasPrice,
    value: mintFee,
  });
};
export const _approveSell = async (
  contract: any,
  spender: any,
  tokenId: any
) => {
  const result = await contract.approve(spender, tokenId);
  return result;
};


export const _listing = async (
  contract: any,
  addressNFT: any,
  tokenId: any,
  price: any,
  payToken: any
) => {
  const args = [addressNFT, tokenId, price, payToken];
  console.log('contract',contract)
  console.log('args',args)

  const estimatedGas = await contract.estimateGas.createListing(...args);
  return contract.createListing(...args, {
    gasLimit: estimatedGas,
  });
};
export const _editListing = async (
  contract: any,
  addressNFT: any,
  tokenId: any,
  price: any
) => {
  const args = [addressNFT, tokenId, price];
  const estimatedGas = await contract.estimateGas.updateListing(...args);
  return contract.updateListing(...args, {
    gasLimit: estimatedGas,
  });
};
export const _cancelListing = async (
  contract: any,
  addressNFT: any,
  tokenId: any
) => {
  const args = [addressNFT, tokenId];
  const estimatedGas = await contract.estimateGas.cancelListing(...args);
  return contract.cancelListing(...args, {
    gasLimit: estimatedGas,
  });
};
export const _buyNFT = async (
  contract: any,
  addressNFT: any,
  tokenId: any
) => {
  const args = [addressNFT, tokenId];
  const estimatedGas = await contract.estimateGas.buyNft(...args);
  return contract.buyNft(...args, {
    gasLimit: estimatedGas,
  });
};

export const _listingBid = async (
  contract: any,
  addressNFT: any,
  payToken: any,
  tokenId: any,
  price: any,
  timeBiding: any
) => {
  const args = [addressNFT, payToken, tokenId, price, timeBiding];
  const estimatedGas = await contract.estimateGas.createAuction(...args);
  return contract.createAuction(...args, {
    gasLimit: estimatedGas,
  });
};
export const _editListingBid = async (
  contract: any,
  auctionId: any,
  price: any
) => {
  const args = [auctionId, price];
  const estimatedGas = await contract.estimateGas.updateAuctionPrice(...args);
  return contract.updateAuctionPrice(...args, {
    gasLimit: estimatedGas,
  });
};
export const _cancelListingBid = async (contract: any, auctionId: any) => {
  const args = [auctionId];
  const estimatedGas = await contract.estimateGas.refund(...args);
  return contract.refund(...args, {
    gasLimit: estimatedGas,
  });
};
export const _bidNFT = async (contract: any, auctionId: any, amount:any) => {
  const args = [auctionId, amount];
  const estimatedGas = await contract.estimateGas.bid(...args);
  return contract.bid(...args, {
    gasLimit: estimatedGas,
  });
};
export const _claimNFT = async (contract: any, auctionId: any) => {
  const args = [auctionId];
  const estimatedGas = await contract.estimateGas.claimNFT(...args);
  return contract.claimNFT(...args, {
    gasLimit: estimatedGas,
  });
};
export const _claimToken = async (contract: any, auctionId: any) => {
  const args = [auctionId];
  const estimatedGas = await contract.estimateGas.claimToken(...args);
  return contract.claimToken(...args, {
    gasLimit: estimatedGas,
  });
};
