import { Modal, Spin, message, Progress } from "antd";
import React, { useEffect, useState } from "react";

import Header from "../components/common/header"


const WallPaper = () => {

    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [imgActive, setImgActive]: any = useState();
    const showModal = (value: any) => {
        setImgActive(value)
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleClick = (value: any) => {
        console.log('value', value)
        showModal(value)

    }

    return (
        <div style={{ overflowX: 'hidden' }}>

            <div className="wallpaper-wrap">
                <div className="content-wall">
                    <div className="content-wall-1">
                        <div className="img-wall1" onClick={() => handleClick('./images/wall1.svg')}>
                            <img className="wall-img1" src="./images/wall1.svg" alt="" />
                        </div>
                        <div className="img-wall2" onClick={() => handleClick('./images/wall2.svg')}>
                            <img className="wall-img2" src="./images/wall2.svg" alt="" />
                        </div>
                    </div>
                    <div className="content-wall-1">
                        <div className="img-wall1" onClick={() => handleClick('./images/wall3.svg')}>
                            <img className="wall-img1" src="./images/wall3.svg" alt="" />
                        </div>
                        <div className="img-wall2" onClick={() => handleClick('./images/wall4.svg')}>
                            <img className="wall-img2" src="./images/wall4.svg" alt="" />
                        </div>
                    </div>
                    <div className="content-wall-1">
                        <div className="img-wall1" onClick={() => handleClick('./images/wall5.svg')}>
                            <img className="wall-img1" src="./images/wall5.svg" alt="" />
                        </div>
                        <div className="img-wall2" onClick={() => handleClick('./images/wall6.svg')}>
                            <img className="wall-img2" src="./images/wall6.svg" alt="" />
                        </div>
                        <div className="img-wall3" onClick={() => handleClick('./images/wall7.svg')}>
                            <img className="wall-img3" src="./images/wall7.svg" alt="" />
                        </div>
                    </div>
                    <div className="content-wall-1">
                        <div className="img-wall1" onClick={() => handleClick('./images/wall8.svg')}>
                            <img className="wall-img1" src="./images/wall8.svg" alt="" />
                        </div>
                        <div className="img-wall2" onClick={() => handleClick('./images/wall9.svg')}>
                            <img className="wall-img2" src="./images/wall9.svg" alt="" />
                        </div>
                    </div>
                    <div className="content-wall-1">
                        <div className="img-wall1" onClick={() => handleClick('./images/wall10.svg')}>
                            <img className="wall-img1" src="./images/wall10.svg" alt="" />
                        </div>
                        <div className="img-wall2" onClick={() => handleClick('./images/wall11.svg')}>
                            <img className="wall-img2" src="./images/wall11.svg" alt="" />
                        </div>
                    </div>
                    <div className="content-wall-1">
                        <div className="img-wall1" onClick={() => handleClick('./images/wall12.svg')}>
                            <img className="wall-img1" src="./images/wall12.svg" alt="" />
                        </div>
                        <div className="img-wall2" onClick={() => handleClick('./images/wall13.svg')}>
                            <img className="wall-img2" src="./images/wall13.svg" alt="" />
                        </div>
                        <div className="img-wall3" onClick={() => handleClick('./images/wall14.svg')}>
                            <img className="wall-img3" src="./images/wall14.svg" alt="" />
                        </div>
                    </div>
                    <div className="content-wall-1">
                        <div className="img-wall1" onClick={() => handleClick('./images/wall15.png')}>
                            <img className="wall-col3" src="./images/wall15.png" alt="" />
                        </div>
                        <div className="img-wall2" onClick={() => handleClick('./images/wall16.png')}>
                            <img className="wall-col3" src="./images/wall16.png" alt="" />
                        </div>
                        <div className="img-wall3" onClick={() => handleClick('./images/wall19.png')}>
                            <img className="wall-col3" src="./images/wall19.png" alt="" />
                        </div>
                    </div>
                    <div className="content-wall-2">
                        <div className="img-wall21" onClick={() => handleClick('./images/wall18.png')}>
                            <img className="wall-col21" src="./images/wall18.png" alt="" />
                        </div>
                        <div className="img-wall22" onClick={() => handleClick('./images/wall20.png')}>
                            <img className="wall-col22" src="./images/wall20.png" alt="" />
                        </div>
                    </div>
                    <div className="content-wall-3">
                        <div className="img-col3" onClick={() => handleClick('./images/wall21.JPG')}>
                            <img className="wall-col3" src="./images/wall21.JPG" alt="" />
                        </div>
                        <div className="img-col3" onClick={() => handleClick('./images/wall22.jpeg')}>
                            <img className="wall-col3" src="./images/wall22.jpeg" alt="" />
                        </div>
                        <div className="img-col3" onClick={() => handleClick('./images/wall23.png')}>
                            <img className="wall-col3" src="./images/wall23.png" alt="" />
                        </div>
                    </div>
                    <div className="content-wall-3">
                        <div className="img-col3" onClick={() => handleClick('./images/wall24.png')}>
                            <img className="wall-col3" src="./images/wall24.png" alt="" />
                        </div>
                        <div className="img-col3" onClick={() => handleClick('./images/wall25.png')}>
                            <img className="wall-col3" src="./images/wall25.png" alt="" />
                        </div>
                        <div className="img-col3" onClick={() => handleClick('./images/wall26.png')}>
                            <img className="wall-col3" src="./images/wall26.png" alt="" />
                        </div>
                    </div>
                    <div className="content-wall-3">
                        <div className="img-col3" onClick={() => handleClick('./images/wall28.png')}>
                            <img className="wall-col3" src="./images/wall28.png" alt="" />
                        </div>
                        <div className="img-col3" onClick={() => handleClick('./images/wall29.gif')}>
                            <img className="wall-col3" src="./images/wall29.gif" alt="" />
                        </div>
                        <div className="img-col3" onClick={() => handleClick('./images/wall31.png')}>
                            <img className="wall-col3" src="./images/wall31.png" alt="" />
                        </div>
                    </div>
                    <div className="content-wall-2">
                        <div className="img-wall21" onClick={() => handleClick('./images/wall32.png')}>
                            <img className="wall-col21" src="./images/wall32.png" alt="" />
                        </div>
                        <div className="img-wall22" onClick={() => handleClick('./images/wall33.gif')}>
                            <img className="wall-col22" src="./images/wall33.gif" alt="" />
                        </div>
                    </div>
                    <div className="content-wall-3">
                        <div className="img-col3" onClick={() => handleClick('./images/wall30.png')}>
                            <img className="wall-col3" src="./images/wall30.png" alt="" />
                        </div>
                        <div className="img-col3" onClick={() => handleClick('./images/wall34.gif')}>
                            <img className="wall-col3" src="./images/wall34.gif" alt="" />
                        </div>
                        <div className="img-col3" onClick={() => handleClick('./images/wall35.gif')}>
                            <img className="wall-col3" src="./images/wall35.gif" alt="" />
                        </div>
                    </div>
                    <div className="content-wall-2">
                        <div className="img-wall21" onClick={() => handleClick('./images/wall36.gif')}>
                            <img className="wall-col21" src="./images/wall36.gif" alt="" />
                        </div>
                        <div className="img-wall22" onClick={() => handleClick('./images/wall37.png')}>
                            <img className="wall-col22" src="./images/wall37.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                title=''
                visible={isModalVisible}
                onOk={handleOk}
                footer={false}
                onCancel={handleCancel}
                className="modal-lucky wall"
                destroyOnClose={true}


            >
                <div className="modal-img-ac">
                    <img className="img-ac" src={imgActive} alt="" />
                </div>

            </Modal>

        </div>
    )
}

export default WallPaper;