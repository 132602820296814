/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Modal, Spin, message, Progress, Select, Drawer, Menu } from "antd";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Collapse } from "antd";
import { isMobile } from 'react-device-detect';
import {
    MenuOutlined,
    SettingOutlined
} from '@ant-design/icons';
import './style.css'


const Footer = () => {
    const [valueSelect, setValueSelect]: any = useState('en');
    const { t, i18n }: any = useTranslation('common');
    let navigate = useNavigate();
    const { Panel } = Collapse;

    const onChange = (key: string | string[]) => {
        console.log(key);
    };
    type ExpandIconPosition = 'start' | 'end';
    const [expandIconPosition, setExpandIconPosition] = useState<ExpandIconPosition>('end');


    const handleChange = (value: any) => {
        console.log(`selected ${value}`);
        i18n.changeLanguage(`${value}`)
        localStorage.setItem('valuelang', value)
        setValueSelect(value)
    };
    useEffect(() => {
        setValueSelect(localStorage.getItem('valuelang'))
        i18n.changeLanguage(localStorage.getItem('valuelang'))
    }, [valueSelect])

    const gotoTw = () => {
        window.open('https://Twitter.com/GeckoInuAvax')
    }
    const gotoTele = () => {
        window.open('https://t.me/geckoinuavax')
    }

    const gotoBot = () => {
        window.location.replace("/bot");
    }

    const gotoNFT = () => {
        window.location.replace("/nft");
    }
    const gotoSwap = () => {
        window.location.replace("/swap");
    }
    const gotoGeckoTeminal = () => {
        window.open("https://www.geckoterminal.com/avax/pools/0x2ade4f6d462d60523235650160844bb6b46ac7d9?utm_source=coingecko&utm_medium=referral&utm_campaign=searchresults");
    }
    const gotoGecko = () => {
        window.open("https://www.coingecko.com/en/coins/gecko-inu");
    }
    const gotoCoinMarketCap = () => {
        window.open("https://coinmarketcap.com/currencies/gecko-inu/");
    }

    const gotoLaunchpad = () => {
        navigate("/launchpad");
    }
    const gotoLaunchpool = () => {
        navigate("/staking");
    }
    const gotoVerse = () => {
        navigate("/gecverse");
    }



    const text1 = (
        <div className="item-col-f">
            <div className="collap-item active-col" onClick={gotoSwap}>
                {t('menu.menu-7', { framework: 'React' })}
            </div>
            <div className="collap-item active-col" onClick={gotoBot}>
                {t('menu.menu-8', { framework: 'React' })}
            </div>
            <div className="collap-item active-col" onClick={gotoNFT}>
                {t('menu.menu-9', { framework: 'React' })}
            </div>
            <div className="collap-item active-col" onClick={gotoLaunchpad}>
                {t('menu.menu-11', { framework: 'React' })}
            </div>
            <div className="collap-item active-col" onClick={gotoLaunchpool}>
                {t('menu.menu-10', { framework: 'React' })}
            </div>

            {/* <div className="collap-item">
                {t('menu.menu-12', { framework: 'React' })} - <span>{t('menu.menu-23', { framework: 'React' })}</span>
            </div> */}
            <div className="collap-item active-col" onClick={gotoVerse}>
                {t('menu.menu-13', { framework: 'React' })}
            </div>
        </div>

    )

    const text2 = (
        <div className="item-col-f">
            <div className="collap-item active-col" onClick={gotoGecko}>
                Coingecko
            </div>
            <div className="collap-item active-col" onClick={gotoCoinMarketCap}>
                Coinmarketcap
            </div>
            <div className="collap-item active-col" onClick={gotoGeckoTeminal}>
                Gecko Terminal
            </div>

        </div>
    )


    return (
        <>
            {isMobile ? (
                <>
                    <div className="content-footer">
                        <div className="content-footer-top">
                            <div className="footer-group1">
                                <div className="f-group1-text">{t('menu.menu-14', { framework: 'React' })}</div>
                                <div className="f-group1-logo">
                                    <img className='logo-footer' src="../images/logo-footer.png" alt="" />
                                </div>
                                <div className="f-group1-title">
                                    {t('menu.menu-18', { framework: 'React' })} <br /> {t('menu.menu-19', { framework: 'React' })}
                                </div>
                            </div>
                            <div className="collapse-footer">
                                <Collapse
                                    defaultActiveKey={['0']}
                                    onChange={onChange}
                                    expandIcon={({ isActive }) =>
                                        <img className="expan-footer" src={`${isActive ? ('../images/nft/expan.png') : ('../images/nft/expan2.png')}`}
                                            alt="" />
                                    }
                                    expandIconPosition={expandIconPosition}
                                >
                                    <Panel header={t('menu.menu-15', { framework: 'React' })} key="1">
                                        <div>{text1}</div>
                                    </Panel>
                                    <Panel header={t('menu.menu-16', { framework: 'React' })} key="2">
                                        <div>{text2}</div>
                                    </Panel>

                                </Collapse>
                            </div>
                            <div className="f-group2-mo">
                                <div className="footer-group2">

                                    <div className="f-group2-item-img">
                                        <div className="f-group2-tele" onClick={gotoTele}>
                                            <img src="../images/f-tele.png" alt="" />
                                        </div>
                                        <div className="f-group2-tw" onClick={gotoTw}>
                                            <img src="../images/f-tw.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="footer-group3">
                                    <div className="action-btn">
                                        <Select
                                            value={valueSelect ? valueSelect : 'en'}
                                            style={{
                                                width: 70,
                                            }}

                                            popupClassName='select-lang'
                                            onChange={handleChange}
                                            options={[
                                                {
                                                    value: 'en',
                                                    label: <div className="country">
                                                        <img src="../images/en1.png" alt="" />
                                                        {/* &ensp;<span>English</span> */}
                                                    </div>,
                                                },
                                                {
                                                    value: 'po',
                                                    label: <div className="country">
                                                        <img src="../images/po1.png" alt="" />
                                                        {/* &ensp;<span>Português</span> */}
                                                    </div>,
                                                },
                                                {
                                                    value: 'cn',
                                                    label: <div className="country">
                                                        <img src="../images/cn1.png" alt="" />
                                                        {/* &ensp;<span>中文（简体）</span> */}
                                                    </div>,
                                                },

                                            ]}
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="content-footer-bot">
                            <div className="footer-bot-left">© 2024 Gecko Inu LLC</div>
                            <div className="footer-bot-right">
                                <img src="../images/pow2.png" alt="" />
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className="content-footer">
                    <div className="content-footer-top">
                        <div className="footer-group1">
                            <div className="f-group1-text">{t('menu.menu-14', { framework: 'React' })}</div>
                            <div className="f-group1-logo">
                                <img className='logo-footer' src="../images/logo-footer.png" alt="" />
                            </div>
                            <div className="f-group1-title">
                                {t('menu.menu-18', { framework: 'React' })} <br /> {t('menu.menu-19', { framework: 'React' })}
                            </div>
                        </div>
                        <div className="footer-group2">
                            <div className="f-group2-text">{t('menu.menu-15', { framework: 'React' })}</div>
                            <div className="f-group2-item active-item" onClick={gotoSwap}>{t('menu.menu-7', { framework: 'React' })} </div>
                            <div className="f-group2-item active-item" onClick={gotoBot}>{t('menu.menu-8', { framework: 'React' })}</div>
                            <div className="f-group2-item active-item" onClick={gotoNFT}>{t('menu.menu-9', { framework: 'React' })}</div>

                            <div className="f-group2-item active-item" onClick={gotoLaunchpad}>{t('menu.menu-11', { framework: 'React' })}</div>
                            <div className="f-group2-item active-item" onClick={gotoLaunchpool}>{t('menu.menu-10', { framework: 'React' })}</div>
                            {/* <div className="f-group2-item">{t('menu.menu-12', { framework: 'React' })} - <span>{t('menu.menu-23', { framework: 'React' })}</span></div> */}
                            <div className="f-group2-item active-item" onClick={gotoVerse}>{t('menu.menu-13', { framework: 'React' })} </div>
                        </div>
                        <div className="footer-group2">
                            <div className="f-group2-text">{t('menu.menu-16', { framework: 'React' })}</div>
                            <div className="f-group2-item active-item" onClick={gotoGecko}>{t('menu.menu-20', { framework: 'React' })}</div>
                            <div className="f-group2-item active-item" onClick={gotoCoinMarketCap}>{t('menu.menu-21', { framework: 'React' })}</div>
                            <div className="f-group2-item active-item" onClick={gotoGeckoTeminal}>{t('menu.menu-22', { framework: 'React' })}</div>
                        </div>
                        <div className="footer-group2">
                            <div className="f-group2-text">{t('menu.menu-17', { framework: 'React' })}</div>
                            <div className="f-group2-item-img">
                                <div className="f-group2-tele" onClick={gotoTele}>
                                    <img src="../images/f-tele.png" alt="" />
                                </div>
                                <div className="f-group2-tw" onClick={gotoTw}>
                                    <img src="../images/f-tw.png" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="footer-group3">
                            <div className="action-btn">
                                <Select
                                    value={valueSelect ? valueSelect : 'en'}
                                    style={{
                                        width: 70,
                                    }}

                                    popupClassName='select-lang'
                                    onChange={handleChange}
                                    options={[
                                        {
                                            value: 'en',
                                            label: <div className="country">
                                                <img src="../images/en1.png" alt="" />
                                                {/* &ensp;<span>English</span> */}
                                            </div>,
                                        },
                                        {
                                            value: 'po',
                                            label: <div className="country">
                                                <img src="../images/po1.png" alt="" />
                                                {/* &ensp;<span>Português</span> */}
                                            </div>,
                                        },
                                        {
                                            value: 'cn',
                                            label: <div className="country">
                                                <img src="../images/cn1.png" alt="" />
                                                {/* &ensp;<span>中文（简体）</span> */}
                                            </div>,
                                        },

                                    ]}
                                />
                            </div>

                        </div>
                    </div>
                    <div className="content-footer-bot">
                        <div className="footer-bot-left">© 2024 Gecko Inu LLC</div>
                        <div className="footer-bot-right">
                            <img src="../images/pow2.png" alt="" />
                        </div>
                    </div>
                </div>
            )}

        </>
    )
}

export default Footer;

