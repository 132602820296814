/* eslint-disable prefer-template */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return, no-param-reassign */
import {
    createStore,
    createHook,
    createContainer,
    createSubscriber,
} from "react-sweet-state";
import axios from "axios";
import { API_URL } from '../../constants/index'


const StoreLaunchPool = createStore({
    initialState: {},
    actions: {
        getListPools:
            (data) =>
                ({ setState }) => {
                    return new Promise((resolve, reject) => {
                        axios.post(`${API_URL}/pool/all`, data).then((res) => {
                            resolve(res?.data?.data);

                        });
                    });
                },
        setData:
            (data) =>
                ({ setState }) => {
                    return new Promise((resolve, reject) => {
                        axios.post(`${API_URL}/pool/set`, data).then((res) => {
                            resolve(res);
                        });
                    });
                },
        getTimeUnstake:
            (data) =>
                ({ setState }) => {
                    return new Promise((resolve, reject) => {
                        axios.post(`https://api.avax.network/ext/bc/C/rpc`, data).then((res) => {
                            resolve(res?.data?.result?.timestamp);
                        });
                    });
                },
    },

    name: "StoreLaunchPool",
});

export const useHookStoreLaunchPool = createHook(StoreLaunchPool);
export const Container = createContainer(StoreLaunchPool, {
    onInit:
        () =>
            ({ setState }, props) => {
                setState({ ...props });
            },
});
export const Subscriber = createSubscriber(StoreLaunchPool);
