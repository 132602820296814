import { message } from "antd";


export const notiSuccess = (content, duration) => {
  return new Promise((resolve) => {
    message.success({
      type: "success",
      content: content,
      className: "custom-class",
      duration: duration,
    });
  });
};
export const notiWarning = (content, duration) => {
  return new Promise((resolve) => {
    message.warning({
      type: "warning",
      content: content,
      className: "custom-class",
      duration: duration,
    });
  });
};
export const notiError = (content, duration) => {
  return new Promise((resolve) => {
    message.error({
      type: "error",
      content: content,
      className: "custom-class",
      duration: duration,
    });
  });
};
