/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState, useCallback, useRef } from "react";
import "./style.css";
import Header from "../../../components/common/header";

import { Pagination, Empty, Skeleton, Modal } from "antd";
import { getListMyNFT } from "../../../axios/marketNFT";
import { useActiveWeb3React } from "../../../hooks";
import { useNavigate } from "react-router-dom";
import { postData } from "../../../axios/marketNFT";
import Countdown from "react-countdown";

import {
  API_URL,
  MINT_NFT_CONTRACT,
  TOTAL_NFT_MINT,
  MARKET_NFT,
  BID_NFT,
} from "../../../constants";
import { formatAddress, rendererCountDown } from "./../../../utils/formats";
import TimeAgo from "./../../../utils/timeAgo";
import Loading from "./../../../components/Loading";
import { convertFromWei, convertToWei } from "../../../utils/convertNumber";
import {
  notiError,
  notiSuccess,
  notiWarning,
} from "./../../../utils/notication";
import abiBidNFT from "../../../constants/abi/abiBidNFT.json";
import abiNFTMarket from "../../../constants/abi/abiNFTMarket.json";
import Web3 from "web3";
import { AbiItem } from "web3-utils";
import switchNetworkChain from "../../../utils/walletChain";
import { getDataByAccountAndAuctionId } from "./../../../hooks/getAllDataMarkets";
import "./style.css";
// import { getMarketInfor } from "../../../axios/marketNFT";
import {
  _buyNFT,
  _bidNFT,
  _claimNFT,
  _claimToken,
} from "../../../utils/utilsNFT";
import { useContract } from "../../../hooks/useContract";
import moment from "moment";

declare const window: Window & typeof globalThis & { ethereum: any };
const DetailNft = () => {
  const { account, chainId }: any = useActiveWeb3React();
  const web3 = new Web3(window.ethereum);
  const currentTime = Math.floor(Date.now() / 1000);
  let navigate = useNavigate();

  const [tabKey, setTabKey]: any = useState("1");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [detailNFT, setDetailNFT] = useState<any>("");

  const [tokenID, setTokenID] = useState<any>("");
  const [priceNFT, setPriceNFT] = useState<any>("0");
  const [_auctionId, setAuctionId] = useState<any>("");
  const [history, setHistory] = useState<any>("");
  const [loadingSwitch, setLoadingSwitch] = useState<any>(false);
  const [objData, setObjData] = useState<any>();
  const [sugges, setSugges] = useState<any>("");
  const [balance, setBalance] = useState<any>("0");

  // fixcel price
  const [loadingBuy, setLoadingBuy] = useState<any>(false);

  // sell to highest
  const [loadingBid, setLoadingBid] = useState<any>(false);
  const [loadingClaimToken, setLoadingClaimToken] = useState<any>(false);
  const [loadingClaimNFT, setLoadingClaimNFT] = useState<any>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [timeBiding, setTimeBiding] = useState<any>("");
  const [valueBid, setValueBid] = useState("");

  const pathSymbol = window.location.pathname.split("/");
  const queryKey = pathSymbol[2];
  const workChain = 43114;

  // const detailNFT: any = {
  //   queryKey: "1705288408106",
  //   ownerAddress: "0x6Ae327b6bD7808924ce7640fA3b84DF30A3C655D",
  //   name: "#106",
  //   tokenId: 106,
  //   nftUrl:
  //     "https://s3-ap-southeast-1.amazonaws.com/nft.geckoinu/images/a2001a99eb65503e4af36a9d95b2e58a53f94abab2cf4f2f89.png",
  //   price: 0,
  //   createDate: 1705288408,
  //   updateDate: 1705288408,
  //   buyType: 0,
  //   attrs: [
  //     {
  //       unicorn: "Background",
  //       name: "Stone Red",
  //       rate: 6,
  //       imageUrl:
  //         "https://s3-ap-southeast-1.amazonaws.com/nft.bscs/images/12bca1a656ec88ab1a38b123c41263ee2227dc77b420c494dc.png",
  //       floor: 0,
  //     },
  //     {
  //       unicorn: "Eyes",
  //       name: "Sad Eyebrows",
  //       rate: 2,
  //       imageUrl:
  //         "https://s3-ap-southeast-1.amazonaws.com/nft.bscs/images/00c05b84b27a83024c764196dbb57fe787f3a2316a33d60b03.png",
  //       floor: 0,
  //     },
  //     {
  //       unicorn: "Mouth",
  //       name: "Blowing Kiss",
  //       rate: 8,
  //       imageUrl:
  //         "https://s3-ap-southeast-1.amazonaws.com/nft.bscs/images/22eba443243d8758ab8e0b11a5b9ab9574ef0cc97db340a96f.png",
  //       floor: 0,
  //     },
  //     {
  //       unicorn: "Clothing",
  //       name: "Tuxedo",
  //       rate: 15,
  //       imageUrl:
  //         "https://s3-ap-southeast-1.amazonaws.com/nft.bscs/images/9a3b801fb5899c178198bdfb61f97f7bff7a5885f2a11e5cf9.png",
  //       floor: 0,
  //     },
  //     {
  //       unicorn: "Headwear",
  //       name: "Crystal Bracelet",
  //       rate: 6,
  //       imageUrl:
  //         "https://s3-ap-southeast-1.amazonaws.com/nft.bscs/images/6d013ffaab044acc0b4c81f9443ba77c9466a464212f455edc.png",
  //       floor: 0,
  //     },
  //     {
  //       unicorn: "Skin",
  //       name: "Guitar Pick",
  //       rate: 6,
  //       imageUrl:
  //         "https://s3-ap-southeast-1.amazonaws.com/nft.bscs/images/5d2596e1095b0970c78823fddf1325ee8354769c85b15fe0ee.png",
  //       floor: 0,
  //     },
  //   ],
  //   auction: null,
  // };

  let time = moment(detailNFT?.auction?.expireTime * 1000).format(
    "MMM DD YYYY h:mm A"
  );
  const formattedDate = moment
    .unix(detailNFT?.auction?.expireTime)
    .format("MMMM D, YYYY [at] h:mm A");

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const maxBalance = () => {
    setValueBid(balance);
  };

  const contractMarket: any = useContract(MARKET_NFT, abiNFTMarket);
  const contractBid: any = useContract(BID_NFT, abiBidNFT);
  const buy = new web3.eth.Contract(
    abiNFTMarket as unknown as AbiItem,
    MARKET_NFT
  );

  const switchChain = async () => {
    try {
      if (chainId !== workChain) {
        setLoadingSwitch(true);

        await switchNetworkChain("0xa86a");
        setLoadingSwitch(false);
      }
    } catch (error) {
      console.error("error Switch", error);
      setLoadingSwitch(false);
    }
  };
  const gotoListing = async (id: any) => {
    navigate(`${"/ownerNFT"}/${id}`);
  };
  const gotoDetail = async (id: any) => {
    navigate(`${"/detailNFT"}/${id}`);
  };

  // common
  const getAlldata = async () => {
    await getDataByAccountAndAuctionId(account, _auctionId).then((res: any) => {
      setObjData(res);
    });
  };
  const getDetailNFT = async () => {
    let data: any = {
      queryKey: queryKey,
      ownerAddress: account,
    };
    await postData(`${API_URL}/nft/detail`, data).then((detail) => {
      if (detail?.data) {
        setDetailNFT(detail?.data);
        setTokenID(detail?.data?.tokenId);
        setPriceNFT(detail?.data?.price);
        setAuctionId(detail?.data?.auction?.auctionId);
      }
    });
  };
  const getSugges = async () => {
    let data: any = {
      queryKey: queryKey,
      ownerAddress: account,
    };
    await postData(`${API_URL}/nft/suggested`, data).then((res) => {
      if (res?.data) {
        setSugges(res?.data);
      }
    });
  };
  const getActivities = async () => {
    let data: any = {
      queryKey: queryKey,
      ownerAddress: account,
    };
    await postData(`${API_URL}/nft/activities`, data).then((res) => {
      if (res?.data) {
        setHistory(res?.data);
      }
    });
  };
  const savePrice = async (price: any, type: number, txHash: any) => {
    let data: any = {
      queryKey: queryKey,
      ownerAddress: account,
      setPrice: Number(price),
      nftType: type,
      txHash: txHash,
      source: "",
    };
    await postData(`${API_URL}/nft/set_price`, data).catch((err) => {
      console.error("save price", err?.message);
    });
  };
  const updateOwner = async (txHash: any, owner: any) => {
    let data: any = {
      queryKey: queryKey,
      ownerAddress: owner,
      setPrice: 0,
      txHash: txHash,
      nftType: 0,
      source: "",
    };
    await postData(`${API_URL}/nft/set_owner`, data).catch((err) => {
      console.error("updateOwner", err?.message);
    });
  };
  const createHash = async (txHash: any, type: number) => {
    let data: any = {
      queryKey: queryKey,
      ownerAddress: account,
      txHash: txHash,
      type: type,
    };

    await postData(`${API_URL}/nft/create_hash`, data).catch((err) => {
      console.error("save priceBid", err?.message);
    });
  };
  const getBalanceAVAX = async () => {
    if (account && chainId === workChain) {
      const balanceTowei = await web3.eth.getBalance(account);
      const balance = await convertFromWei(balanceTowei, 18);
      setBalance(balance);
    } else {
      setBalance("0");
    }
  };
  useEffect(() => {
    getBalanceAVAX();
  }, [account, chainId]);

  useEffect(() => {
    getActivities();
  }, [queryKey, account, chainId]);
  useEffect(() => {
    getSugges();
  }, [queryKey, account, chainId]);
  useEffect(() => {
    if (queryKey) {
      getDetailNFT();
    }
  }, [queryKey, account]);
  useEffect(() => {
    getAlldata();
  }, [account, _auctionId, chainId]);

  // end common

  // Fixed price

  const handleBuyNFT = async () => {
    try {
      setLoadingBuy(true);
      let price: any = detailNFT?.price.toString();
      if (account) {
        await _buyNFT(
          contractMarket,
          MINT_NFT_CONTRACT,
          tokenID
        )
          .then((resBuy: any) => {
            if (resBuy?.hash) {
              createHash(resBuy.hash, 5);
            }
            resBuy.wait().then((resSuccess: any) => {
              if (resSuccess) {
                savePrice(0, 0, resSuccess?.transactionHash);
                setTimeout(() => {
                  updateOwner(resSuccess?.transactionHash, account);
                }, 500);
                // onOpenModal(<SuccessBuyModal detail={detailNFT} />);
                getDetailNFT();
                getAlldata();
                notiSuccess("Buy NFT successfully", 5);
                setLoadingBuy(false);
              }
            });
          })
          .catch((err: any) => {
            console.error("error BuyNFT", err);
            notiError(err?.reason, 10);
            setLoadingBuy(false);
          });
      }
    } catch (error: any) {
      console.error("error BuyNFT", error);
      notiError(error.message, 10);
      setLoadingBuy(false);
    }
  };
  // end Fixed price

  // Sell to highest bidder
  const saveOfferBid = async (
    code: any,
    price: any,
    auctionId: any,
    txHash: any
  ) => {
    let data: any = {
      queryKey: queryKey,
      code: code,
      ownerAddress: account,
      setPrice: Number(price),
      auctionId: auctionId,
      txHash: txHash,
      source: "",
    };
    await postData(`${API_URL}/nft/make_offer`, data).catch((err) => {
      console.error("save priceBid", err?.message);
    });
  };
  const handleBidNFT = async () => {
    try {
      setLoadingBid(true);
      setIsModalOpen(false);

      if (Number(valueBid) > Number(priceNFT)) {
        if (account && detailNFT?.auction?.code) {
          await _bidNFT(
            contractBid,
            _auctionId.toString(),
            convertToWei(valueBid.toString(), 18)
          )
            .then((resBid: any) => {
              if (resBid?.hash) {
                createHash(resBid.hash, 3);
              }
              resBid.wait().then((resSuccess: any) => {
                if (resSuccess) {
                  saveOfferBid(
                    detailNFT?.auction?.code,
                    valueBid,
                    Number(detailNFT?.auction?.auctionId),
                    resSuccess?.transactionHash
                  );
                  getAlldata();
                  setLoadingBid(false);
                  notiSuccess("Bid NFT successfully", 5);
                  getDetailNFT();
                  getAlldata();
                  setLoadingBid(false);
                }
              });
            })
            .catch((err: any) => {
              console.error("error resBid", err);
              notiError(err?.reason, 5);
              setLoadingBid(false);
            });
        } else {
          notiWarning("NFTs have not opened for auction yet", 5);
          setLoadingBid(false);
        }
      } else {
        notiWarning(
          "The auction price must be greater than the current bid price",
          5
        );
        setLoadingBid(false);
      }
    } catch (error) {
      console.error("error resBid", error);
      setLoadingBid(false);
    }
  };
  const handleClaimToken = async () => {
    try {
      setLoadingClaimToken(true);
      if (account) {
        await _claimToken(contractBid, _auctionId)
          .then((resClaimToken: any) => {
            if (resClaimToken?.hash) {
              createHash(resClaimToken.hash, 6);
            }
            resClaimToken.wait().then((resSuccess: any) => {
              if (resSuccess) {
                savePrice(detailNFT?.price, 2, resSuccess?.transactionHash);
                setTimeout(() => {
                  updateOwner(
                    resSuccess?.transactionHash,
                    objData?.auction_CurrentOwnerBid
                  );
                }, 500);
                setLoadingClaimToken(false);
                notiSuccess("Claim token successfully", 5);
                getDetailNFT();
                getAlldata();
                setLoadingClaimToken(false);
              }
            });
          })
          .catch((err: any) => {
            console.error("error resClaimToken", err);
            notiError(err?.reason, 10);
            setLoadingClaimToken(false);
          });
      }
    } catch (error: any) {
      console.error("error resClaimToken", error);
      notiError(error.message, 10);
      setLoadingClaimToken(false);
    }
  };
  const handleClaimNFT = async () => {
    try {
      setLoadingClaimNFT(true);
      if (account) {
        await _claimNFT(contractBid, _auctionId)
          .then((resClaimNFT: any) => {
            if (resClaimNFT?.hash) {
              createHash(resClaimNFT.hash, 6);
            }
            resClaimNFT.wait().then((resSuccess: any) => {
              if (resSuccess) {
                savePrice(detailNFT?.price, 2, resSuccess?.transactionHash);
                setTimeout(() => {
                  updateOwner(resSuccess?.transactionHash, account);
                }, 500);
                setLoadingClaimNFT(false);
                notiSuccess("Claim NFT successfully", 5);
                getDetailNFT();
                getAlldata();
                setLoadingClaimNFT(false);
              }
            });
          })
          .catch((err: any) => {
            console.error("error resClaimNFT", err);
            notiError(err?.reason, 10);
            setLoadingClaimNFT(false);
          });
      }
    } catch (error: any) {
      console.error("error resClaimNFT", error);
      notiError(error.message, 10);
      setLoadingClaimNFT(false);
    }
  };
  // end Sell to highest bidder

  // console.log("chainId", chainId);
  // console.log("balance", Number(balance));

  console.log("detailNFT", detailNFT);
  const renderRankColor = (num: any) => {
    if (num > 1 && num <= 80) {
      return 'group-2-id rank1'
    } else if (num > 80 && num <= 403) {
      return 'group-2-id rank2'
    } else if (num > 403 && num <= 1210) {
      return 'group-2-id rank3'
    } else if (num > 1210 && num <= 2824) {
      return 'group-2-id rank4'
    } else if (num > 2824 && num <= 4842) {
      return 'group-2-id rank5'
    } else if (num > 4842 && num <= 10000) {
      return 'group-2-id rank6'
    } else {
      return 'group-2-id'
    }

  }

  return (
    <div style={{ overflowX: "hidden" }}>

      <div className="detail-NFT">
        <div className="div">
          <div className="image-detail-left">
            <img className="img" alt="Image" src={detailNFT?.nftUrl} />
            <div className="detail-group22">
              <div className="detail-group-2">
                <div className="group-2-name">{detailNFT?.name}</div>
                <div
                  className={renderRankColor(detailNFT?.rarityScore)}
                // className="group-2-id"
                >

                  {detailNFT?.rarityScore}
                </div>
              </div>
            </div>

            <div className="detail-group-3">
              <div className="group-3-add">
                Owned by{" "}
                <a
                  href={`https://snowtrace.io/address/${detailNFT?.ownerAddress}`}
                  target="_blank"
                  rel="noreferrer"
                  className="offer-add"
                >
                  <span>{formatAddress(detailNFT?.ownerAddress, 6, 6)} </span>
                </a>
                <img
                  src="../images/nft/link.png"
                  alt=""
                  style={{ height: "14px", width: "auto", borderRadius: "0px" }}
                />
              </div>
              {/* <div className="group-3-share">
                <img src="../images/nft/share.png" alt="" /> Share
              </div> */}
            </div>
          </div>
          <div className="detail-top-right">
            <div className="detail-group-1">
              <div className="group-1-img">
                <img src="../images/nft/group1-img.png" alt="" />
              </div>
              <div className="group-1-name">Gecko Inu NFT Collection</div>
              <div className="group-1-icon">
                <img src="../images/nft/group1-icon.png" alt="" />
              </div>
            </div>
            {/* <div className="detail-group-2">
              <div className="group-2-name">{detailNFT?.name}</div>
              <div className="group-2-id">{TOTAL_NFT_MINT}</div>
            </div>
            <div className="detail-group-3">
              <div className="group-3-add">
                Owned by{" "}
                <a
                  href={`https://snowtrace.io/address/${detailNFT?.ownerAddress}`}
                  target="_blank"
                  rel="noreferrer"
                  className="offer-add"
                >
                  <span>{formatAddress(detailNFT?.ownerAddress, 6, 6)}</span>
                </a>
              </div>
              <div className="group-3-share">
                <img src="../images/nft/share.png" alt="" /> Share
              </div>
            </div> */}
            <div className="detail-group-4">
              <div className="group-4-title">About Gecko NFT </div>
              <div className="group-4-dec">
                10,000 distinct and dope Geckos are set to revolutionize Avax
                culture with unparalleled diversity and flair.
              </div>
              {/* <div className="group-4-box custom">
                <div className="group-4-box1">
                  <div className="box-4-price">Price:</div>
                  <div className="box-4-token" style={{ paddingBottom: "5px" }}>
                    <img src="../images/nft/avax-icon.png" alt="" />
                    <span>{priceNFT} AVAX</span>
                  </div>
                  <div className="box-4-usdt">$560.00</div>
                </div>
                {detailNFT?.buyType === 2 && (
                  <>
                    <div className="group-4-box2">
                      <div className="box-4-price2">Sale end in:</div>
                      <div
                        className="box-4-token"
                        style={{ paddingBottom: "5px" }}
                      >
                        <span>
                          <Countdown date={time} renderer={rendererCountDown} />
                        </span>
                      </div>
                      <div className="box-4-usdt">{formattedDate}</div>
                    </div>
                  </>
                )}
              </div>
              {chainId !== workChain && account && (
                <div className="detail-group-5">
                  <button
                    className="btn-connectnew full-width"
                    disabled={loadingSwitch}
                    onClick={switchChain}
                  >
                    <Loading status={loadingSwitch} content="Switch Chain" />
                  </button>
                </div>
              )}

              <div className="detail-group-5">
                {detailNFT?.ownerAddress?.toLowerCase() ===
                  account?.toLowerCase() && (
                    <button
                      className="btn-connectnew full-width"
                      disabled={chainId !== workChain || !account}
                      onClick={() => gotoListing(detailNFT?.queryKey)}
                    >
                      {detailNFT?.price > 0 ? "Edit Listing" : "List for sale"}
                    </button>
                  )}
              </div>
              {!objData || !detailNFT ? (
                <>
                  <Skeleton className="pt-10" active paragraph={{ rows: 3 }} />
                </>
              ) : (
                <>
                  <div className="detail-group-5">
                   
                    {Number(priceNFT) > 0 &&
                      account?.toLowerCase() !==
                      detailNFT?.ownerAddress?.toLowerCase() &&
                      detailNFT?.buyType === 1 && (
                        <>
                          {" "}
                          {Number(balance) < Number(priceNFT) ? (
                            <button
                              className="btn-connectnew full-width"
                              disabled
                            >
                              Balance insufficient
                            </button>
                          ) : (
                            <button
                              className="btn-connectnew full-width"
                              disabled={
                                loadingBuy || !account || chainId !== workChain
                              }
                              onClick={handleBuyNFT}
                            >
                              <Loading
                                status={loadingBuy}
                                content={`Buy NFT`}
                              />
                            </button>
                          )}
                        </>
                      )}
                  
                    {detailNFT?.ownerAddress?.toLowerCase() !==
                      account?.toLowerCase() &&
                      Number(detailNFT?.price) > 0 &&
                      detailNFT?.buyType === 2 && (
                        <>
                          {Number(balance) >= Number(detailNFT?.price) ? (
                            <>
                              <button
                                disabled={
                                  loadingBid ||
                                  loadingClaimNFT ||
                                  !account ||
                                  chainId !== workChain ||
                                  currentTime > detailNFT?.auction?.expireTime
                                }
                                type="button"
                                className="btn-connectnew full-width"
                                onClick={showModal}
                              >
                                <Loading
                                  status={loadingBid}
                                  content="Bid NFT"
                                />
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                type="button"
                                disabled
                                className="btn-connectnew full-width"
                              >
                                Balance insufficient
                              </button>
                            </>
                          )}
                        </>
                      )}
                  </div>
                </>
              )} */}
            </div>
            <div className="mid-group2">
              <div className="mid-group2-title">Details</div>
              <div className="mid-group-box2">
                <div className="box2-content">
                  <div className="group-box2-left">Mint Address:</div>
                  <div className="group-box2-right">
                    <a
                      href={`https://snowtrace.io/address/${MINT_NFT_CONTRACT}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {formatAddress(MINT_NFT_CONTRACT, 5, 4)}{" "}
                      <img
                        src="../images/nft/link.png"
                        alt=""
                        style={{ height: "14px" }}
                      />
                    </a>
                  </div>
                </div>
                <div className="box2-content">
                  <div className="group-box2-left">Token address:</div>
                  <div className="group-box2-right">
                    <a
                      href={`https://snowtrace.io/address/${MINT_NFT_CONTRACT}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {formatAddress(MINT_NFT_CONTRACT, 5, 4)}{" "}
                      <img
                        src="../images/nft/link.png"
                        alt=""
                        style={{ height: "14px" }}
                      />
                    </a>
                  </div>
                </div>
                <div className="box2-content">
                  <div className="group-box2-left">Owner:</div>
                  <div className="group-box2-right">
                    <a
                      href={`https://snowtrace.io/address/${detailNFT?.ownerAddress}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {formatAddress(detailNFT?.ownerAddress, 5, 4)}{" "}
                      <img
                        src="../images/nft/link.png"
                        alt=""
                        style={{ height: "14px" }}
                      />
                    </a>
                  </div>
                </div>

                {/* <div className="box2-content">
                  <div className="group-box2-left">Creator Royalties:</div>
                  <div className="group-box2-right">2.5%</div>
                </div>
                <div className="box2-content">
                  <div className="group-box2-left">Taker Fee:</div>
                  <div className="group-box2-right">2.5%</div>
                </div>
                <div className="box2-content">
                  <div className="group-box2-left">Create Date:</div>
                  <div className="group-box2-right">
                    <TimeAgo timestamp={detailNFT?.createDate} />
                  </div>
                </div>
                <div className="box2-content">
                  <div className="group-box2-left">Last Updated:</div>
                  <div className="group-box2-right">
                    <TimeAgo timestamp={detailNFT?.updateDate} />
                  </div>
                </div> */}
              </div>
            </div>
            <div className="mid-group1">
              <div className="mid-group1-content">
                <div className="mid-group1-title">Item Properties</div>
                <div className="mid-group1-list">
                  {detailNFT?.attrs?.map((data: any, index: any) => {
                    return (
                      <>
                        <div className="mid-group1-item">
                          <div className="group-item">
                            <div className="group1-item-name">
                              {data?.unicorn}
                            </div>
                            <div className="group1-item-dec">{data?.name}</div>
                            <div className="group1-item-num">
                              <div className="item-num1">{data?.rate || '--'}%</div>
                              {/* <div className="item-num2">
                                {data?.floor}
                                <img src="../images/nft/avax-icon.png" alt="" />
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="detail-mid"> */}
        {/* <div className="mid-group1">
            <div className="mid-group1-content">
              <div className="mid-group1-title">Item Properties</div>
              <div className="mid-group1-list">
                {detailNFT?.attrs?.map((data: any, index: any) => {
                  return (
                    <>
                      <div className="mid-group1-item">
                        <div className="group-item">
                          <div className="group1-item-name">
                            {data?.unicorn}
                          </div>
                          <div className="group1-item-dec">{data?.name}</div>
                          <div className="group1-item-num">
                            <div className="item-num1">{data?.rate}%</div>
                            <div className="item-num2">
                              {data?.floor}
                              <img src="../images/nft/avax-icon.png" alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div> */}
        {/* <div className="mid-group2">
            <div className="mid-group2-title">Details</div>
            <div className="mid-group-box2">
              <div className="box2-content">
                <div className="group-box2-left">Token address:</div>
                <div className="group-box2-right">
                  {formatAddress(MINT_NFT_CONTRACT, 5, 4)}
                </div>
              </div>
              <div className="box2-content">
                <div className="group-box2-left">Owner:</div>
                <div className="group-box2-right">
                  {formatAddress(detailNFT?.ownerAddress, 5, 4)}
                </div>
              </div>
              <div className="box2-content">
                <div className="group-box2-left">Creator Royalties:</div>
                <div className="group-box2-right">2.5%</div>
              </div>
              <div className="box2-content">
                <div className="group-box2-left">Taker Fee:</div>
                <div className="group-box2-right">2.5%</div>
              </div>
              <div className="box2-content">
                <div className="group-box2-left">Create Date:</div>
                <div className="group-box2-right">
                  <TimeAgo timestamp={detailNFT?.createDate} />
                </div>
              </div>
              <div className="box2-content">
                <div className="group-box2-left">Last Updated:</div>
                <div className="group-box2-right">
                  <TimeAgo timestamp={detailNFT?.updateDate} />
                </div>
              </div>
            </div>
          </div> */}
        {/* </div> */}
        {/* <div className="mid-group3">
          <div className="mid-group3-content">
            <div className="group3-title">Offers history</div>
            <div className="group3-table">
              <table className="table-offer">
                <tr className="head-table-offer">
                  <th>Price</th>
                  <th>Floor Difference</th>
                  <th>Last Updated</th>
                  <th>From</th>
                  <th></th>
                </tr>

                {detailNFT?.auction ? (
                  <>
                    {detailNFT?.auction?.offerHistories.map(
                      (item: any, index: any) => {
                        return (
                          <>
                            <tr key={index} className="body-table-offer">
                              <td>{item?.price} AVAX</td>
                              <td>{item?.floor}%</td>
                              <td>
                                <TimeAgo timestamp={item?.createTime - 10} />
                              </td>
                              <td>
                                <a
                                  href={`https://snowtrace.io/address/${item?.fromAddress}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="offer-add"
                                >
                                  {formatAddress(item?.fromAddress, 5, 4)}
                                </a>
                              </td>
                              <td>
                                <div className="btn-open-link">
                                  {!item?.txHash &&
                                    objData?.auction_Owner?.toLowerCase() ===
                                    account?.toLowerCase() ? (
                                    <button
                                      className="btn-offer"
                                      disabled={
                                        currentTime <
                                        detailNFT?.auction
                                          ?.expireTime ||
                                        loadingClaimToken ||
                                        !account ||
                                        chainId !== workChain
                                      }
                                      onClick={handleClaimToken}
                                    >
                                      <Loading
                                        status={loadingClaimToken}
                                        content="Claim Token"
                                      />
                                    </button>
                                  ) : !item?.txHash &&
                                    objData?.auction_CurrentOwnerBid?.toLowerCase() ===
                                    account?.toLowerCase() ? (
                                    <button
                                      className="btn-offer"
                                      disabled={
                                        currentTime <
                                        detailNFT?.auction
                                          ?.expireTime ||
                                        loadingClaimNFT ||
                                        !account ||
                                        chainId !== workChain
                                      }
                                      onClick={handleClaimNFT}
                                    >
                                      <Loading
                                        status={loadingClaimNFT}
                                        content="Claim NFT"
                                      />
                                    </button>
                                  ) : item?.txHash ? (
                                    <a
                                      href={`https://bscscan.com/tx/${item?.txHash}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <i>Refunded</i>
                                    </a>
                                  ) : (
                                    "highest bid"
                                  )}

                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      }
                    )}
                  </>
                ) : (
                  <>
                    <tr>
                      <td colSpan={5}>
                        <Empty
                          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                          imageStyle={{ height: 60 }}
                          description={
                            <span style={{ color: "#97A3BC" }}>No data</span>
                          }
                        ></Empty>
                      </td>
                    </tr>
                  </>
                )}
              </table>
            </div>
          </div>
        </div>
        <div className="mid-group3 bottom">
          <div className="mid-group3-content">
            <div className="group3-title">Activity</div>
            <div className="group3-table">
              <div className="group3-table-new">
                <table className="table-offer">
                  <tr className="head-table-offer">
                    <th>Item</th>
                    <th>Type</th>
                    <th>Price</th>
                    <th>Transaction</th>
                    <th>Time</th>
                    <th>Address </th>
                  </tr>
                  {history?.length > 0 ? (
                    <>
                      {history?.map((item: any, index: any) => {
                        return (
                          <>
                            <tr className="body-table-offer">
                              <td>
                                <img
                                  style={{ borderRadius: "5px" }}
                                  className="img-item-offer"
                                  src={detailNFT?.nftUrl}
                                  alt=""
                                />
                              </td>
                              <td>
                                <span className="type-acti">{item?.type}</span>
                              </td>
                              <td>
                                <img
                                  className="icon-acti"
                                  src="../images/nft/avax-icon.png"
                                  alt=""
                                />
                                {item?.price} AVAX
                              </td>
                              <td>
                                {item?.transaction !== "-"
                                  ? formatAddress(item?.transaction, 5, 4)
                                  : "--"}
                              </td>
                              <td>
                                <TimeAgo timestamp={item?.createTime - 10} />
                              </td>
                              <td>
                                <a
                                  href={`https://snowtrace.io/address/${item?.mintAddress}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="offer-add"
                                >
                                  {formatAddress(item?.mintAddress, 5, 4)}
                                </a>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <tr>
                        <td colSpan={7}>
                          <Empty
                            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                            imageStyle={{ height: 60 }}
                            description={
                              <span style={{ color: "#97A3BC" }}>No data</span>
                            }
                          ></Empty>
                        </td>
                      </tr>
                    </>
                  )}
                </table>
              </div>

              <div className="pagination">
                <Pagination defaultCurrent={1} total={50} />
              </div>
            </div>
          </div>
        </div> */}
      </div>

      <Modal
        footer={false}
        className="group-detail-modal"
        title=""
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="mo-detail-nft">
          <div className="group-mo-title">
            <img src="../images/nft/place.svg" alt="" />
          </div>
          <div className="group-mo-item">
            <img src={detailNFT?.nftUrl} alt="" />
          </div>
          <div className="group-mo-name">{detailNFT?.name}</div>
          <div className="group-mo-dec">
            Owned by <span>{formatAddress(detailNFT?.ownerAddress, 6, 5)}</span>
          </div>
          <div className="group-mo-ctr">
            <div className="mo-ctr-top">
              <div className="ctr-top-left">Price:</div>
              <div className="ctr-top-right">
                Balance: <span>{balance} AVAX</span>
              </div>
            </div>
            <div className="mo-ctr-bot">
              <input
                type="text"
                min={0}
                placeholder="0.00"
                value={valueBid}
                onChange={(e: any) => setValueBid(e.target.value)}
              />
              <button onClick={maxBalance} className="mo-ctr-max">
                MAX
              </button>
            </div>
          </div>
          <div className="mess-ctr">
            {Number(balance) < Number(valueBid) &&
              "*Not enough AVAX to make offer"}
          </div>
          <div className="action-ctr">
            <button
              disabled={Number(balance) < Number(valueBid) || !valueBid}
              className="action-ctr-btn"
              onClick={handleBidNFT}
            >
              Make Offer
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DetailNft;
