/* eslint-disable react-hooks/rules-of-hooks */

const switchNetworkChain = async (chainId: any) => {
  const provider: any = (window as any).ethereum;

  if (provider) {
    try {
      if (provider?.selectedAddress && chainId) {
        await provider
          .request({
            method: "wallet_switchEthereumChain",
            params: [
              {
                chainId,
              },
            ],
          })
      }
    } catch (switchError: any) {
      try {
        await provider.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainName: "Avax Chain Mainnet",
              chainId: "0xa86a",
              rpcUrls: ["https://avalanche.public-rpc.com"],
              nativeCurrency: {
                name: "AVAX",
                symbol: "AVAX",
                decimals: 18,
              },
            },
          ],
        });
        return false;
      } catch (addError) {
        console.error(addError);
      }
      return false;
    }
  } else {
    return false;

    // console.error("Can't setup the BSC network on metamask because window.ethereum is undefined")
  }
};

export default switchNetworkChain;
